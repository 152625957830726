
.expressListerContainer{
	height: 100%;
	background-color: #f8f8f8;
	overflow-x: hidden;
	margin-left: -35px;
	margin-right: -35px;
	.scanButton{
		background-color: #E2EAFF;
		display: flex;
		border-radius: 8px;
		width: 40px;
		height: 40px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		margin-left: 10px;
		border: none;
		cursor: pointer;
		outline: none;
		svg{
			height: 20px;
			width: 20px;
		}
		@media (min-width: 769px) and (max-width: 1440px) {
			width: 32px;
			height: 32px;
			gap: 8px;
			margin-left: 8px;

			svg {
				width: 16px;
				height: 16px;
			}
		}
		@media only screen and (max-width: 1439px) {
			height: 32px;
			width: 32px;
			svg{
				height: 16px;
				width: 16px;
			}
		}
	}
	.researchLensContainer{
		margin-left: 15px;
		display: flex;
		gap: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-left: 12px;
			gap: 8px;
		}
	}
	.priceResearchButton {
		border-radius: 8px;
		border: none;
		background: #D9E2FC;
		color: #fff;
		font-style: normal;
		font-weight: 600;
		height: 40px;
		width: 40px;
		font-size: 16px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (min-width: 769px) and (max-width: 1440px) {
			border-radius: 6px;
			height: 32px;
			width: 32px;
			font-size: 13px;

			svg {
				width: 16px;
				height: 13px;
			}
		}
		&:disabled,
		&.disabledResearch {
			background-color: #ECECEC !important;
			cursor: not-allowed;  // Ensure proper cursor for disabled state
			color: #B6B6B6;  // Adjust text color if needed
            opacity: 1;
			svg {
				path {
					fill: #B6B6B6;
				}
			}
		}
		@media only screen and (max-width: 1439px) {
			height: 32px;
			width: 32px;
		}
	}

	.expressListerSwitcher{
		display: flex;
		margin-bottom: 20px;
		@media (min-width: 769px) and (max-width: 1440px) {
			margin-bottom: 16px;
		}
		.switcherItem{
			border: 1px solid  #767676;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			height: 32px;
			display: flex;
			align-items: center;
			padding: 15px;
			cursor: pointer;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 11px;
				height: 26px;
				padding: 12px;
			}

			&.fullListingForm{
				border-radius: 0px 8px 8px 0px;
				color:  #767676;

				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius: 0 6px 6px 0;
				}

				&:hover{
					border: 1px solid #1E3166;
					background: #D9E2FC;
					color: #1E3166;
				}
			}
			&.expressListerForm{
				border-radius: 8px 0px 0px 8px;
				border: 1px solid #1E3166;
				background: #D9E2FC;
				color: #1E3166;

				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius: 6px 0 0 6px;
				}
				&:hover{
					border: 1px solid #1E3166;
					background: #D9E2FC;
					color: #1E3166;
				}
			}
		}
	}
	.intendedMenuIntendedFor {

		.menuItems {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
			}
			&.menuItemsConditions {
				flex-wrap: nowrap;
			}
		}
		.item {
			border-radius: 8px;
			background: #d7e2ff;
			color: #1e3166;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 9px 15px;
			cursor: pointer;
			display: flex;
			width: fit-content;
			white-space: nowrap;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px;
				font-size: 13px;
				padding: 7px 12px ;
			}
		}
		.itemActive {
			background-color: #1e3166;
			color: #fff;
			border-radius: 8px;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 9px 15px;
			cursor: pointer;
			display: flex;
			width: fit-content;
			white-space: nowrap;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px;
				font-size: 13px;
				padding: 7px 12px;
			}
		}
	}
	.expressTitlePart{
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.conditionNotes{
		max-width: 50%;
		@media (min-width: 769px) and (max-width: 1440px) {
			max-width: 40%;
		}
		@media only screen and (max-width: 768px) {
			max-width: 100%;
		}
	}
	.conditions{
		display: flex;
		gap: 30px;
		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 24px;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			gap: 0;
		}
	}

	.intendedMenuCondition{
		margin-top: 10px;
		margin-bottom: 20px;
		@media (min-width: 769px) and (max-width: 1440px) {
			margin-top: 8px;
			margin-bottom: 16px;
		}
		.popoverIcon {
			cursor: pointer;
			margin-left: 10px;
			min-width: 20px;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-left: 8px;
				min-width: 16px;
			}
		}
		.conditionMenu {
			display: flex;
			gap: 30px;
			align-items: flex-start;
			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 24px;
			}
			input {
				border-radius: 8px;
				border: 1px solid #d9d9d9;
				height: 40px;
				width: 100%;
				padding: 15px;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
			@media only screen and (max-width: 768px) {
				flex-direction: column;
				gap: 15px;
			}
		}
		.menuItems {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
			}
			&.menuItemsConditions {
				flex-wrap: nowrap;
			}
		}
		.title {
			margin-bottom: 9px;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #393838;
			display: flex;
			align-items: center;
			@media only screen and (max-width: 768px) {
				font-size: 16px;
			}
		}
		.item {
			border-radius: 8px;
			background: #d7e2ff;
			color: #1e3166;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 9px 15px;
			cursor: pointer;
			display: flex;
			width: fit-content;
			white-space: nowrap;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px;
				font-size: 13px;
				padding: 7px 12px;
			}
		}
		.itemActive {
			background-color: #1e3166;
			color: #fff;
			border-radius: 8px;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 9px 15px;
			cursor: pointer;
			display: flex;
			width: fit-content;
			white-space: nowrap;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px;
				font-size: 13px;
				padding: 7px 12px;
			}
		}
	}
	.inputContainerDefaultWeight{
		display: flex;
		max-width: 50%;
		gap: 20px;
		.TitlesContainer{
			flex:1;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
		}
	}

	.inputContainerDefault{
		max-width: 50%;
		@media (min-width: 769px) and (max-width: 1440px) {
			max-width: 40%;
		}
		&.inputContainerDefaultSKU{
			max-width: 100%;
			.inpRightItem{
				max-width: 50%;

				@media (min-width: 769px) and (max-width: 1440px) {
					max-width: 40%;
				}
				@media only screen and (max-width: 768px) {
					max-width: 100%;
				}
			}
		}
		@media only screen and (max-width: 768px) {
			width: 100%;
			max-width: 100%;
		}
	}
	.skuContainer {
		padding-bottom: 20px;

		@media (min-width: 769px) and (max-width: 1440px) {
			padding-bottom: 16px;
		}
		.skuInnerContainer {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			align-items: flex-end;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
			}

			.skugeneratorFlex{
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
				align-items: flex-start;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 8px;
				}
			}
			.skuLocationExpress, .skuLocationNumberExpress{
				display: flex;
				flex-direction: column;
				gap: 8px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 6px;
				}
			}
			.skuLocationExpressInner{
				display: flex;
				gap: 5px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 4px;

				}
			}
			.active {
				border: 1px solid var(--Dark-Blue, #1E3166) !important;
				background: var(--Dark-Blue, #1E3166) !important;
				color: #fff !important;
			}
			.generateSkuItem {
				border-radius: 8px;
				background: #D7E2FF;
				border: none;
				padding:8px 14px;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				color:#1E3166;
				cursor: pointer;

				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius: 6px;
					padding: 6px 11px;
					font-size: 13px;
				}
			}
			input {
				padding: 0 20px;
				height: 39px;
				outline: none;
				border-radius: 8px;
				border: 1px solid #d9d9d9;

				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius: 6px;
					height: 32px;
					padding: 0 16px;
				}
				&:focus {
					border: 1px solid #393838;
				}
			}
		}
	}
	.mainExpressListerList{
		display: flex;
		flex-direction: column;
		gap: 15px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 12px;
		}

	}
	.expressButtons{
		display: flex;
		gap: 15px;
		padding-bottom: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 12px;
			padding-bottom: 8px;
		}
		@media only screen and (max-width: 768px) {
			gap: 10px;
			flex-wrap: wrap;
		}
	}
	.customizeButtonMobile{
		align-items: center;
		gap: 5px;
		border-radius: 8px;
		border: 1px solid rgb(182, 182, 182);
		height: 40px;
		padding: 0 10px;
		cursor: pointer;
		display: none;
		background-color: rgb(182, 182, 182);
		@media only screen and (max-width: 768px) {
			display: flex;
			svg{
				path{
					fill: #fff;
				}
			}
		}
		span{
			color: #fff;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
	.customizeButton{
		display: flex;
		align-items: center;
		gap: 10px;
		border-radius: 8px;
		border: 1px solid #1E3166;
		height: 40px;
		padding: 0 15px;
		cursor: pointer;

		span{
			color: #1E3166;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
	.listingAssistentButton{
		height: 40px;
		border-radius: 8px;
		padding: 20px;
		gap: 10px;
		display: flex;
		align-items: center;
		cursor: pointer;
		color: #fff;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		svg{
			background-color: transparent;
		}
		@media only screen and (max-width: 768px) {
			height: auto;
			padding: 10px;
			text-align: center;
			font-size: 14px;
			gap: 5px;
			svg{
				width: 19px;
			}
		}
	}
	.mobileNotifications{
		display: none;
		.progressSavedContainer{
			display: flex;
			gap: 6px;
			align-items: center;
			.timeAgo{
				color: #767676;
				font-style: italic;
				font-weight: 400;
			}
		}
		@media only screen and (max-width: 768px) {
			display: flex;
			align-items: flex-start;
			margin-bottom: 10px;
			flex-direction: column;
			justify-content: center;
		}
	}
	@media only screen and (max-width: 768px) {
		height:100%;
		margin-right: auto;
		margin-left: auto;
	}
	.checkboxContainerDraft {
		display: flex;
		gap: 5px;
		align-items: center;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 4px;
		}

	}
	.headerTitle {
		color: #000;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		display: none;
		@media only screen and (max-width: 768px) {
			font-size: 16px;
			display: block;
		}
	}
	@media only screen and (max-width: 768px) {
		width: 100%;
		left: 0;
		padding: 10px;
		bottom: 0;
	}
	.uploadImagesContainer{
		@media only screen and (max-width: 768px) {
			border-radius: 8px;
			border:none;
			padding: 0;
		}
	}
	.loadercontainerOverlay{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		position: fixed;
		top: 0;
		right: 0;
		width: calc(100% - 210px);
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		z-index: 99;
		@media only screen and (max-width: 768px) {
			width: 100%;
		}
	}
	.mobileTitle{
		display: block;
		color: #000;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		padding: 5px 0;

		@media (min-width: 769px) and (max-width: 1440px) {
			font-size: 16px;
			padding: 4px 0;
		}
		@media only screen and (max-width: 768px) {
			padding:5px 0 0 0;
		}
	}
	.shippingDimensions{
		display: flex;
		gap: 20px;
		max-width: 70%;
		@media only screen and (max-width: 768px) {
			flex-direction: column;
		}
		.listingTitleContainer{
			max-width: 100%;
			flex: 1;
		}
	}
	.listingTitleContainer{
		max-width: 50%;

		@media (min-width: 769px) and (max-width: 1440px) {
			max-width: 40%;
		}
		@media only screen and (max-width: 768px) {
			width: 100%;
			max-width: 100%;
		}
	}

	.expressListerInner{
		padding: 70px 30px 30px 30px;
		border-radius: 8px;
		height: 100%;

		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 56px 24px 24px 24px;
			border-radius: 6px;
		}
		@media only screen and (max-width: 768px) {
			padding: 5px 15px 15px 15px;
			background: #fff;
		}
		.flexContainer{
			display: flex;
			width: 100%;
			align-items: flex-start;
			gap: 10px;
			@media only screen and (max-width: 768px) {
				flex-direction: column;
			}
			.listingTitleContainer{
				flex: 1;
				@media only screen and (max-width: 768px) {
					width: 100%;
					max-width: 100%;
				}
			}
			.inpRightItem{
				flex: 1;
				@media only screen and (max-width: 768px) {
					width: 100%;
				}
			}
		}
		.popoverIcon {
			cursor: pointer;
			margin-left: 10px;
			min-width: 20px;
			@media (min-width: 769px) and (max-width: 1440px) {
				margin-left: 8px;
				min-width: 16px;
			}
			@media only screen and (max-width: 768px) {
				margin-left: 5px;
			}

		}
		.heading {
			margin-bottom: 4px;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #393838;
			display: flex;
			align-items: center;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-bottom: 3px;
				font-size: 13px;
			}
		}
		.markListing {
			display: flex;
			align-items: center;

			.draftCheckbox {
				border: 1px solid #d9d9d9;
				border-radius: 8px;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				color: #393838;
				padding: 10px;
				white-space: nowrap;

				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius:6px;
					font-size: 16px;
					padding: 8px;
				}
				&.addBorder {
					border: 1px solid #4db89e;
				}
				.checkBoxContainer {
					display: inline-block;
					position: relative;
					padding-left: 40px;
					cursor: pointer;

					@media (min-width: 769px) and (max-width: 1440px) {
						padding-left: 32px;
					}
				}

				/* The checkbox itself */
				.checkBoxContainer input {
					display: none;
				}

				/* The custom checkbox */
				.checkBoxContainer .checkMark {
					position: absolute;
					top: -22px;
					left: 0;
					height: 30px;
					width: 30px;
					background-color: #4db89e;
					border: 1px solid #4db89e;
					border-radius: 8px;

					@media (min-width: 769px) and (max-width: 1440px) {
						top: -18px;
						height: 24px;
						width: 24px;
						border-radius: 6px;
					}
				}
				.checkBoxContainer .checkboxChecked {
					position: absolute;
					top: -22px;
					left: 0;
					height: 30px;
					width: 30px;
					background-color: #e9e9e9;
					border: 1px solid #d9d9d9;
					border-radius: 8px;

					@media (min-width: 769px) and (max-width: 1440px) {
						top: -18px;
						height: 24px;
						width: 24px;
						border-radius: 6px;
					}
				}
				/* The white arrow (you can use an SVG or pseudo-element to create the arrow) */
				.checkBoxContainer .checkMark::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
					height: 12px;
					width: 5px;
					border: solid white;
					border-width: 0 2px 2px 0;

					@media (min-width: 769px) and (max-width: 1440px) {
						height: 10px;
						width: 4px;
						border-width: 0 1px 1px 0;
					}
				}
				.checkBoxContainer .checkboxChecked::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
					height: 12px;
					width: 5px;
					border: solid white;
					border-width: 0 2px 2px 0;
				}
			}
			.title {
				margin-bottom: 9px;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				color: #393838;
				display: flex;
				align-items: center;
				@media only screen and (max-width: 768px) {
					font-size: 16px;
					display: none;
				}
			}
			@media only screen and (max-width: 768px) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				.draftCheckbox {
					margin-left: 0;
					margin-bottom: 10px;
				}
			}
		}
		.TitlesContainer {
			.popoverIcon {
				cursor: pointer;
				margin-left: 10px;
				min-width: 20px;
				@media (min-width: 769px) and (max-width: 1440px) {
					margin-left: 8px;
					min-width: 16px;
				}
				@media only screen and (max-width: 768px) {
					margin-left: 0;
				}
			}
		}
		.showAllTitlesContainer {
			margin-left: 30px;
			margin-right: 30px;

			.showAllTitlesItem {
				margin-top: 10px;

				.heading {
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					color: #393838;
					flex: 1;
					white-space: nowrap;
					margin-top: -30px;
				}

				.itemInner {
					display: flex;
					gap: 10px;
					align-items: center;

					@media only screen and (max-width: 768px) {
						flex-direction: column-reverse;
						margin-top: 30px;
						justify-content: flex-start;
						align-items: flex-start;
					}
				}

				.charactersQuantity {
					color: #696969;
					font-size: 14px;
					font-style: italic;
					font-weight: 400;
					line-height: normal;
					margin-top: 8px;
				}

				input {
					border-radius: 8px;
					border: 1px solid #d9d9d9;
					height: 40px;
					width: 100%;
					padding: 15px;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					flex: 3;
				}
			}

			@media only screen and (max-width: 768px) {
				margin-left: 0;
				margin-right: 0;
				margin-top: 40px;
				.heading {
					font-size: 14px !important;
					margin-top: -36px;
				}
			}
		}
		.titlesMain {
			width: 100%;
			align-items: flex-start;
			column-gap: 20px;
			&.titlesMainPrice{
				display: flex;
				column-gap: 0;
			}
			&.titlesMainUpcScan{
				display: flex;
				column-gap: 0;
				gap: 10px;
				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 8px;
				}
			}
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
			.charactersQuantity {
				color: #696969;
				font-size: 14px;
				font-style: italic;
				font-weight: 400;
				line-height: normal;
				margin-top: 8px;
			}
		}
		.title {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 5px;
			color: #393838;
			display: flex;
			align-items: center;
			gap: 5px;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
				margin-bottom: 4px;
				gap: 4px;
			}
		}
	}
	.popoverContent{
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: auto;
		color: #393838;

		@media (min-width: 769px) and (max-width: 1440px) {
			font-size: 13px !important;
		}
	}
}