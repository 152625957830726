

/* For screen display, you can keep the same styling or adjust as needed */
.print-container {
	height: 100vh;
	page-break-inside: avoid;
}

.qrCode {
	width: 100%;
}

.qr-code-container, .title-container-print {
	box-sizing: border-box;
	font-size: 22pt; /* Responsive font size */
}

.qr-title {
	font-size: 45pt;
}

.qr-sku {
	/*before font-size: 24pt;*/
	font-size: 75pt;
	font-weight: bold;
}

.qr-code-container {
	float: left;
	width: 30%; /* Adjust width as needed */
}
.space{
	width: 1%;
	height: 10px;
	float: left;
}
.title-container-print {
	float: left;
	width: 68%; /* Adjust width as needed */
	word-wrap: break-word; /* Ensure long titles wrap */
	/*before padding-left: 2px;*/
}

.qr-code {
	width: 100%; /* Make QR code fill its container */
	height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
	.qr-sku {
		font-size: 30pt !important;
	}
	.qr-title {
		font-size: 20pt;
	}
}



@media print {
	@page{
		size: auto;
	}
	body {
		-webkit-print-color-adjust: exact; /* Adjust color for print */
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
	}

	.print-container {
		height: 100vh; /* Full height of the page */
		width: 100%;
		page-break-inside: avoid;
	}

	.qrCode {
		width: 100%;
	}

	.qr-code-container, .title-container-print {
		box-sizing: border-box;
		font-size: 4vw;
	}
	.qr-sku {
		/*before font-size: 4.5vw;*/
		font-size: 10.5vw;
		font-weight: bold;
	}

	.qr-code-container {
		width: 30%; /* Adjust width as needed */
	}
	.qr-title {
		font-size: 5.5vw;
	}
	.space{
		width: 1%;
		height: 10px;
		float: left;
	}
	.title-container-print {
		width: 68%; /* Adjust width as needed */
		word-wrap: break-word; /* Ensure long titles wrap */
	}

	.qr-code {
		width: 100%; /* Make QR code fill its container */
		height: auto; /* Maintain aspect ratio */
	}
}

/* Media query for smaller paper sizes like A10 */


@media print and (max-width: 200mm) {
	/*@page {*/
	/*	size: 1.25in  2.25in;*/
	/*}*/
	.print-container {
		page-break-inside: avoid;
	}

	.qrCode {
		overflow: hidden;
		width: 100%;
	}
	.title-container-print {
		/*before padding-left: 5pt;*/
		font-size: 4.5vw;  /* Responsive font size */
	}
	.qr-sku {
		font-weight: bold;
		 /*before font-size: 4.5vw;*/
		font-size: 7.5vw;
	}

}
