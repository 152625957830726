@import "@assets/styles/variables.scss" ;

.settingsPageMain{
	margin-top: 24px;
	position: relative;
	@media only screen and (max-width: 768px){
		padding: 0 10px;
	}
	@media (min-width: 769px) and (max-width: 1440px) {
		margin-top: 16px;
	}

	.popoverIcon{
		cursor: pointer;
		transition: $transition;

		&:hover {
			opacity: $globalOpacity;
		}
	}
	.customSkuContainer{
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 15px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap:12px;
		}
	}
	.disabledSku{
		position: absolute;
		left: -10px;
		right: -10px;
		bottom: -10px;
		top: -10px;
		background-color: rgba(239, 239, 239, 0.5);
		z-index: 99;
		cursor: not-allowed;
		border-radius: 8px;

		@media (min-width: 769px) and (max-width: 1440px) {
			left: -8px;
			right: -8px;
			bottom: -8px;
			top: -8px;
			border-radius: 6px;

		}
	}
	.SettingsContent{
		border: 1px solid #4DB89E;
		padding: 60px 30px 30px 30px;
		margin-top: -60px;
		border-radius: 8px;
		margin-bottom: 30px;
		@media only screen and (max-width: 768px){
			padding: 54px 10px 24px 10px;
		}
		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 48px 24px 24px 24px;
			margin-top: -48px;
			border-radius: 6px;
			margin-bottom: 24px;
		}
	}
	.TitlesContainer {
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-width: 300px;
		margin-bottom: 20px;
		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
			max-width: 240px;
			margin-bottom:16px;
		}
		.popoverIcon {
			cursor: pointer;
			margin-left: 10px;
			min-width: 20px;
			@media only screen and (max-width: 768px) {
				margin-left: 0;
			}

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-left: 8px;
				min-width: 16px;

				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
		.title{
			display: flex;
			align-items: center;
			color: #000;
			font-size: 16px;
			font-weight: 700;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;

				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
	.titlesMain {
		width: 100%;
		align-items: flex-start;
		column-gap: 20px;
		@media only screen and (max-width: 768px) {
			width: 100%;
		}
		@media (min-width: 769px) and (max-width: 1440px) {
			column-gap: 16px;
		}
	}
	.userData {
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr 1fr;
		max-width: 1000px;
		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 16px;
			max-width: 800px;
		}

		.labelTitle {
			display: flex;
			align-items: flex-start;
			flex-direction: column;

			input {
				width: 100%;
				max-width: 450px;
				height: 40px;
				outline: none;
				border: 1px solid #D9D9D9;
				padding-left: 10px;
				border-radius: 8px;

				@media (min-width: 769px) and (max-width: 1440px) {
					max-width: 360px;
					height: 32px;
					padding-left: 8px;
					border-radius: 6px;
				}
			}
		}
	}
	.checkboxBlock {
		display: flex;
		align-items: center;
		gap: 15px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 12px;
		}
		.myCheckbox {
			cursor: pointer;
			margin: 0 !important;
			-webkit-appearance: none;
			border: 1px solid #1e3166;
			border-radius: 8px;
			outline: none;
			width: 25px;
			height: 25px;
			font: initial;
			flex-shrink: 0;
			background-color: #fff;

			@media (min-width: 769px) and (max-width: 1440px) {
				width: 20px;
				height: 20px;
				border-radius: 6px;
			}

			&::after {
				content: ' ';
				display: block;
				background-size: 35px;
				background-repeat: no-repeat;
				background-position: center;
				left: 9px;
				top: 4px;
				border: solid #ffffff;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
				position: relative;
				width: 5px;
				height: 10px;

				@media (min-width: 769px) and (max-width: 1440px) {
					left: 7px;
					top: 3px;
					border-width: 0 1px 1px 0;
					width: 4px;
					height: 8px;

				;
				}
			}

			&:checked {
				background-color: #1e3166;
				border-color: #1e3166;
			}

			&:disabled {
				opacity: 0.2;
				cursor: not-allowed;
			}
		}
		label {
			user-select: none;
			cursor: pointer;
		}
	}

	.customDescriptionFooters {
		h2 {
			color: #393838;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 20px;
			@media only screen and (max-width: 768px) {
				font-size: 16px;
				margin-bottom: 10px;
			}
			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 16px;
				margin-bottom: 16px;
			}

		}
		.cstmDecsrpFootersWrapper {
			display: grid;
			align-items: center;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;
			margin-bottom: 30px;
			@media only screen and (max-width: 768px) {
				grid-template-columns: 100%;
			}
			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 16px;
				margin-bottom: 24px;
			}
			.cstmDscrptFItem {
				h5 {
					color: #393838;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					margin-bottom: 5px;
					@media only screen and (max-width: 768px) {
						font-size: 16px;
					}
					@media (min-width: 769px) and (max-width: 1440px) {
						font-size: 16px;
						margin-bottom: 4px;
					}
				}

				div {
					textarea {
						width: 100%;
						height: 120px;
						resize: none;
						border-radius: 8px;
						border: 1px solid #d9d9d9;
						background-color: #fff;
						padding: 10px;
						font-size: 16px;
						overflow-y: auto;

						&::-webkit-scrollbar {
							background-color: #fff;
							width: 16px;

							@media (min-width: 769px) and (max-width: 1440px) {
								width: 13px;
							}
						}

						&::-webkit-scrollbar-thumb {
							background-color: #babac0;
							border-radius: 16px;
							border: 4px solid #fff;

							@media (min-width: 769px) and (max-width: 1440px) {
								border-radius: 13px;
								border:3px solid #fff;
							}
						}

						&::-webkit-scrollbar-track {
							background-color: #fff;
						}

						&::-webkit-scrollbar-button {
							display: none;
						}

						@media (min-width: 769px) and (max-width: 1440px) {
							height: 96px;
							border-radius: 6px;
							padding: 8px;
							font-size: 13px;
						}
					}

				}
			}
		}

		.saveBlock {
			display: flex;
			flex-direction: column;

			button {
				border-radius: 8px;
				padding: 10px 0;
				cursor: pointer;
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 7px;
				border: none;

					@media (min-width: 769px) and (max-width: 1440px) {
						border-radius: 6px;
						padding: 8px 0;
						font-size: 13px;
						margin-bottom: 6px;
					}
			}

			a {
				color: #2185d0;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

					@media (min-width: 769px) and (max-width: 1440px) {
						font-size: 13px;
					}
			}
		}
	}
	.listingsFilters {
		margin-bottom: 30px;
		user-select: none;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-bottom: 24px;
			}


		h2 {
			color: #393838;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 15px;
			@media only screen and (max-width: 768px) {
				font-size: 16px;
			}
			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 24px;
				margin-bottom: 12px;
			}
		}

		h5 {
			color: #393838;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 15px;
			@media only screen and (max-width: 768px) {
				font-size: 16px;
			}
			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 16px;
				margin-bottom: 12px;
			}
		}

		.checkboxBlock {
			display: flex;
			flex-direction: column;
			gap: 18px;
			justify-content: flex-start;
			align-items: flex-start;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 14px;
				}

			div {
				display: flex;
				align-items: center;
				gap: 15px;
					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 12px;
					}
				input {
					width: 22px;
					height: 22px;
					flex-shrink: 0;
						@media (min-width: 769px) and (max-width: 1440px) {
							width: 18px;
							height: 18px;
						}
				}

				label {
					color: #393838;
					font-size: 20px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					cursor: pointer;
					@media only screen and (max-width: 768px) {
						font-size: 16px;
					}
					@media (min-width: 769px) and (max-width: 1440px) {
						font-size: 16px;
					}
				}
			}
		}

		.myCheckboxCircle {
			position: relative;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			width: 20px;
			height: 20px;
			border: 2px solid #1E3166 ;
			border-radius: 50%;
			outline: none;
			cursor: pointer;

				@media (min-width: 769px) and (max-width: 1440px) {
					width: 16px;
					height: 16px;
					border: 1px solid #1E3166 ;

				}

			&:checked {
				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 15px;
					height: 15px;
					background-color: #1E3166 ;
					border-radius: 50%;

						@media (min-width: 769px) and (max-width: 1440px) {
							top: 40%;
							left: 40%;
							width: 12px;
							height: 12px;
						}
				}
			}
		}
	}
}

.customSkuBlock {
	display: flex;
	flex-direction: column;
	gap:15px;
	font-family: "Open Sans",'sans-serif';
    position: relative;
		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 12px;
		}
	.customSkuPrefix {
		color: #000;
		font-size: 20px;
		font-weight: 700;
		line-height: normal;
		text-transform: capitalize;
		display: flex;
		align-items: center;
		gap: 10px;
			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 16px;
				gap: 8px;
			}
		.popoverContent{
			font-weight: 400;
			a{
				font-weight: 500;
			}
		}
	}
}