@import '../../../../../../assets/styles/variables.scss';

.confirmModalOverlay {
  font-family: $fontFamilyOpenSans !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    border-radius: 8px;
    background-color: #fff;
    width: 500px;
    height: 190px;
    padding: 15px;

    .close__block {
      display: flex;
      justify-content: flex-end;
    }

    .modalWrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
      align-items: center;
      //text-transform: capitalize;

      h1 {
        font-size: 30px;
        .role {
          text-transform: capitalize;
        }
        span {
          margin-left: 5px;
        }
      }

      .buttons {
        display: flex;
        gap: 20px;

      }
    }
  }
}
