.iframeContainer {
  position: fixed;
  z-index: 9993;
  right: 0;
  top: 0;
  bottom: 0;

  @media (max-width: 768px) {
    bottom: 63px;
  }

  .saveAndCloseButton{
    z-index: 999;
    position: absolute;
    right: calc(50% - 302px);
    bottom: 0;
    @media only screen and (max-width: 1279px) and (min-width: 571px){
      right: calc(50% - 272px);
    }
    @media only screen and (max-width: 570px) and (min-width: 432px){
      right: calc(50% - 61px);
    }
    @media only screen and (max-width: 431px){
      right: calc(50% - 130px);
    }

    button{
      background: #5f879b ;
      white-space: nowrap ;
      border-radius: 8px ;
      padding: 0 20px;
      cursor: pointer ;
      height: 39px;
      font-family: "Open Sans", sans-serif ;
      font-size: 16px;
      font-weight: 400 !important;
      text-transform: capitalize;
      position: relative ;
      user-select: none;
      transition: opacity 0.25s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
      color: #FFFFFF ;
      margin: 3px;
      width: 154px;
      outline: none;
      border: none;
      @media only screen and (max-width: 1279px) and (min-width: 571px){
        font-size: 12px;
        height: 30px;
        width: 130px;
      }
      @media only screen and (max-width: 570px){
        font-size: 12px;
        height: 30px;
        width: 130px;
      }
    }
  }

  .loaderIframe {
    background: white;
    width: 80vw;
    border: 1px solid grey;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 100vw !important;
      /* New width for smaller screens */
    }

    .loaderAnimation {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;

      /* Safari */
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .closeBackground {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ccc;

    position: absolute;
    align-items: center;
    height: 40px;

    .closeIcon {
      width: 29px;
      cursor: pointer;
      margin-right: 5px;
      margin-top: 5px;
      position: absolute;
      right: 4px;
    }

    .listingAssistantBtnBlock {
      display: flex;
      align-items: flex-end;
      gap: 5px;

      .assistantButton {
        background-color: #234165;
        color: white;
        border: none;
        border-radius: 8px;
        padding: 5px 10px;
        font-family: 'open sans';
        cursor: not-allowed;
      }
    }

  }
}