#textArea .tagify {
	max-height: 300px;
	overflow-y:auto;
	overflow-x: hidden;
	width: 100%;
	background: #fff;
	margin-bottom:0 !important;
}

#textArea .tagify__tag {
	cursor: grab !important;
}