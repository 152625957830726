.acordionHeader {
	background: #ededed;
	color: #393838;
	height: 67px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	padding: 22px;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	cursor: pointer;
	margin-bottom: 30px;
	position: relative;
	@media (min-width: 769px) and (max-width: 1440px) {
		height: 54px;
		border-radius: 6px;
		padding: 18px;
		font-size: 16px;
		margin-bottom: 24px;
	}
	.popoverContent{
		z-index: 9991;
		cursor: pointer;
		max-width: 450px;

		@media (min-width: 769px) and (max-width: 1440px) {
			max-width:360px;
		}

		.heading {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #393838;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
			}
		}
		.line {
			height: 1px;
			background-color: #d9d9d9;
			width: 100%;
			margin-top: 10px;
			margin-bottom: 18px;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-top: 8px;
				margin-bottom: 14px;
			}
		}
		p {
			margin: 10px 0;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin: 8px 0;
			}
		}
		ul {
			li {
				position: relative;
				padding-left: 20px;
				margin-bottom: 10px;
				&::before {
					content: '•';
					color: #000;
					font-size: 27px;
					position: absolute;
					left: 0;
					top: 11px;
					transform: translateY(-50%);
					margin-right: 5px;
				}
			}
		}

	}
	.popoverCustomizations{
		display: flex;
		flex-wrap: wrap;
		a{
			//white-space: nowrap;
			padding: 0 5px;

			@media (min-width: 769px) and (max-width: 1440px) {
				padding: 0 4px;
			}
		}
	}
	.line {
		height: 1px;
		background-color: #d9d9d9;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 18px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-top: 8px;
			margin-bottom: 14px;
		}
	}
	.popoverIcon {
		cursor: pointer;
		margin-left: 10px;
		min-width: 20px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-left: 8px;
			min-width: 16px;
		}
	}
	p {
		display: flex;
		align-items: center;
	}
	@media only screen and (max-width: 768px) {
		font-size: 16px;
		padding-right: 60px;
	}
	&.accordionActive {
		background-color: #4db89e;
		color: #fff;
	}

	.accordionArrow {
		position: absolute;
		right: 20px;
		height: 40px;
		width: 40px;
		@media only screen and (max-width: 768px) {
			height: 30px;
			width: 30px;
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			right: 16px;
			height: 32px;
			width: 32px;
		}

	}
}