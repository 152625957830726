@import '@assets/styles/variables.scss';
.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 4px; // Space between checkbox and label
}
.checkboxLabel {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  transition: opacity 0.25s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  @media (min-width: 769px) and (max-width: 1440px) {
    font-size: 13px;
    gap: 4px;
  }

}
.checkbox {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #1e3166;
  border-radius: 8px;
  outline: none;
  width: 25px;
  height: 25px;
  font: initial;
  flex-shrink: 0;
  background-color: #fff;
  position: relative;
  transition: $transition;

  &:hover {
    opacity: $globalOpacity;
    transition: opacity 0.2s ease-in-out;
  }
  &--default-sticky {
    margin-left: 6px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: 5px;
    }
  }
  &--sticky {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 2px;
      background-color: grey;
      transform: translate(-50%, -50%);
      border: none;
      transition: background-color 0.2s ease-in-out;
    }

    &:checked::after {
      background-color: grey;
    }
  }

  &:checked {
    background-color: #1e3166;
    border-color: #1e3166;

    &::after {
      content: ' ';
      display: block;
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center;
      left: 9px;
      top: 4px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: relative;
      width: 5px;
      height: 10px;
      background-color: #1e3166 !important;
      transition: transform 0.2s ease-in-out, border-color 0.2s ease-in-out;

      @media (min-width: 769px) and (max-width: 1440px) {
        left: 7px;
        top: 3px;
        border-width: 0 1px 1px 0;
        width: 4px;
        height: 8px;
      }
    }
  }

  &--disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &:hover {
      opacity: 0.2;
    }
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    border-radius: 6px;
    width: 20px;
    height: 20px;
  }
}

