.selectionIDsMainContainer{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9995;
  padding: 10px;
  .selectionIDsModal{
    background-color: #fff;
    padding: 20px;
    position: relative;
    border: 1px solid #B6B6B6;
    border-radius: 8px;
    min-width: 450px;
    margin: auto;

    @media only screen and (max-width: 769px){
      min-width: auto;
      width: 100%;
    }
    .selectionIDsModalInner{
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      .checkboxContainer, .buttonsContainer{
        display: flex;
        gap: 10px;
      }
      .title{
        font-size: 16px;
        font-weight: 500;
      }
    }
    .closeIcon{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      svg{
        height: 20px;
      }
    }
  }
}