.brandInfoTextarea {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 100% !important;
  resize: none;
  outline: none;
  padding: 20px;
  &:focus {
    border: 1px solid #393838;
  }
}

.charactersQuantity {
  color: #696969;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin: 5px;
  @media only screen and (max-width: 768px) {
    margin: 8px 0;
  }
}