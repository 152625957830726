@import '../../../../assets/styles/variables.scss';

.myInput {
  font-family: 'Open Sans', sans-serif !important;
  background-color: #fff !important;
  height: 39px !important;
  outline: none !important;
  border: 1px solid $dark !important;
  border-radius: 4px !important;

  padding: 0 10px !important;
  width: 321px;
  &:focus {
    border: none !important;
    outline: 1px solid $mint !important;
  }

  &:disabled {
    outline: 1px solid $gray;
  }


}

.characterText {
 font-family: $fontFamilyOpenSans;
  font-style: italic;
  font-size: 14px;
  position: absolute;
  top: 40px;
  color:#767676;

    @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 11px;
        top: 32px;
    }

}

.myInputDanger {
  &:focus {
    border: none !important;
    outline: 1px solid #FF4D4D !important;
  }

  &:disabled {
    outline: 1px solid $gray;
  }
}

.characterTextDanger {
  color:#FF4D4D;
}