@import "@assets/styles/variables.scss" ;

.csvBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer !important;
	text-align: right !important;
	padding: 10px;
	border: 1px solid #1E3166;
	border-radius: 8px;
	width: 40px;
	height: 40px;
	transition: opacity .25s ease,
	background-color .1s ease,
	color .1s ease,
	box-shadow .1s ease,
	background .1s ease,
	-webkit-box-shadow .1s ease;
	color: #1e3166;

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 8px;
		border-radius: 6px;
		width: 32px;
		height: 32px;
	}

	&:hover {
		opacity: $globalOpacity;
	}

}

.csvBtnDisabled  {
	border: 1px solid #e6e6e6;
	cursor: not-allowed !important;

	svg {
		fill: #b4b4b3;

		path {
			fill: #b4b4b3;
		}
	}
}

