
.confirmModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    margin: 20px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    width: 500px;
    overflow-y: auto;
    max-height: 300px;
    padding: 15px;
    span{
      margin-right: 30px;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .close__block {
      position: absolute;
      right: 15px;
      top:5px;
    }
   ul {
     margin-top: 20px;
     gap: 10px;
     li {
       color: var(--Gray-1, #333);
       font-size: 16px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
     }
   }
  }
}
