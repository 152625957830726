.hr {
	background: #bdbdbd;
	height: 1px;
	width: 100%;
}
.noMarketplaceList {
	font-size: 16px;
	font-style: normal;
	line-height: normal;
	text-transform: capitalize;
	padding: 30px;
}

.availablePlatformsContainer {
	padding-right: 30px;
	padding-left: 30px;

	.availablePlatformsItemContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.avalaiblePlatforms {
			display: flex;
			flex-wrap: wrap;
			gap: 5px;
			//max-width: calc(100% - 300px);
			//overflow-x: auto;
			&::-webkit-scrollbar {
				background-color: #fff;
				width: 16px;
				&-thumb {
					background-color: #babac0;
					border-radius: 16px;
					border: 4px solid #fff;
				}

				&-track {
					background-color: #fff;
				}

				&-button {
					display: none;
				}
			}
		}
	}

	.availablePlatformsTitle {
		margin-top: 15px;
		margin-bottom: 20px;
		color: #000;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: capitalize;
	}
}
.listItemContainer {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr;
	margin-top: 17px;
	align-items: center;

	.actionsContainer {
		display: flex;
		justify-content: center;
		.settings {
			width: 40px;
			height: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid #1e3166;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			svg {
				fill: #1e3166;
			}
		}
	}
	.apiContainer {
		.apiTitle {
			color: #999;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.popovericon {
		cursor: pointer;
		margin-left: 4px;
	}
	.buttonsContainer {
		display: flex;
		flex-direction: column;
		gap: 5px;
		.errBtnContainer {
			display: flex;
			gap: 4px;
		}
		.errorButton {
			display: inline-flex;
			height: 30px;
			padding: 10px 5px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 4px;
			background: #ffe2e2;
			color: #f40000;
			max-width: 76px;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			border: none;
		}
		.connectButton {
			display: inline-flex;
			height: 40px;
			padding: 10px 15px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid var(--Blue, #173593);
			background: #fff;
			color: var(--Blue, #173593);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			cursor: pointer;
		}
		.activeButton {
			height: 30px;
			max-width: 84px;
			display: inline-flex;
			padding: 10px 5px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 4px;
			background: #c5ffed;
			color: var(--Green, #009566);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			outline: none;
			border: none;
		}
		.disconnectButton {
			display: inline-flex;
			height: 40px;
			padding: 10px 15px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid var(--Grey-Text, #999);
			background: #fff;
			color: var(--Grey-Text, #999);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			cursor: pointer;
		}
	}
	.extensionContainer {
		.extensionTitle {
			color: #999;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		.connectButton {
			display: inline-flex;
			height: 40px;
			padding: 10px 15px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid var(--Blue, #173593);
			background: #fff;
			color: var(--Blue, #173593);
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			cursor: pointer;
		}
	}
	.accountContainer {
		display: grid;
		align-items: center;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
		.account {
			color: #000;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.nameContainer {
		display: flex;
		align-items: center;
		gap: 10px;
		.marketName {
			color: #000;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

	.actionsContainer {
		svg {
			height: 24px;
			width: 24px;
		}
	}
}
.connectedMarketplacesModal {
	overflow: hidden;
	background-color: #fff;
	width: 100%;
	max-width: 760px;
	//height: calc(100vh - 50px);
	border-radius: 8px;
	border: 1px solid var(--Grey-Text, #999);
	padding-top: 11px;
	padding-bottom: 30px;
	position: fixed;
	top: auto;
	bottom: auto;
	margin: auto;
	max-height: calc(100vh - 110px);
	display: flex;
	flex-direction: column;
	&.hideModal {
		display: none;
	}
	&.showModal {
		display: flex;
		flex-direction: column;
	}
	.marketplacesListOuter {
	}
	.closeModal {
		position: absolute;
		top: 15px;
		right: 15px;
		cursor: pointer;
	}
	.marketplacesList {
		margin-top: 23px;
		flex-grow: 1;
		overflow-y: auto;
		max-height: calc(100% - 60px);
		padding-bottom: 20px;
		padding-left: 30px;

		&.marketplacesListReconnect {
			flex-grow: 1;
			overflow-y: auto;
			max-height: calc(100% - 60px);
			padding: 10px;
		}
		&::-webkit-scrollbar {
			background-color: #fff;

			width: 16px;
			&-thumb {
				background-color: #babac0;
				border-radius: 16px;
				border: 4px solid #fff;
			}
			&-track {
				background-color: #fff;
			}
			&-button {
				display: none;
			}
		}
		.marketplacesListHeader {
			display: grid;
			grid-template-columns: 1fr 2fr 1fr;
			width: 100%;
			.marketplacesListHeaderItem {
				color: var(--Grey-Text, #999);
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				&.actions {
					text-align: center;
				}
			}
		}
	}
	.runConnectionWizard {
		cursor: pointer;
		height: 40px;
		margin: 0;
		flex-shrink: 0;
		border-radius: 8px;
		border: 1px solid var(--Mint, #4db89e);
		background: var(--Mint, #4db89e);
		color: var(--White, #fff);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: capitalize;
		display: flex;
		align-items: center;
		padding: 0 18px;
		gap: 12px;
	}
	.reconnectionWizardBtns {
		display: flex;
		padding-left: 30px;
		padding-right: 30px;
		gap: 17px;
		.loadingBtn{
			display: flex;
			justify-content: space-between;
			gap: 10px;
			align-items: center;
			p{
				color: #1E3166;
			}
		}
		.viewAllSettings {
			cursor: pointer;
			height: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid var(--Mint, #1e3166);
			background: #fff;
			color: #1e3166;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
			margin-top: 15px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			gap: 12px;
			width: fit-content;
		}
		.runReConnectionWizard {
			cursor: pointer;
			height: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid var(--Mint, #ff4d4d);
			background: var(--Light-Red, #ff4d4d);
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
			margin-top: 15px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			gap: 12px;
			width: fit-content;
		}
	}

	.bottomButtons {
		position: absolute;
		right: 20px;
		bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		.connectButton {
			border-radius: 8px;
			background: var(--LP-Navy, #1e3166);
			padding: 10px;
		}
		.cancelButton {
			border-radius: 8px;
			border: 1px solid #999 !important;
			background: #fff !important;
			padding: 8px 18px;
			color: #999 !important;
		}
	}
	.notConnectedTitle {
		color: var(--Grey-Text, #999);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-top: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.backButton {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.titles {
		display: flex;
		align-items: center;
		gap: 15px;
		padding-left: 30px;
		padding-right: 30px;
		.heading {
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
		}
		.subTitle {
			color: #999;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
}