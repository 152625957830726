.salesPageLabel {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: -30px;

  div {
    color: #767676;
    font-family: Open Sans, 'sans-serif';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .title  {
    font-size: 30px;
    font-weight: 600;
    color:#000;
  }
}
.marketplacesBtn{
  background-color: #1E3166;
  color: #fff;
  height: 40px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  min-width: 183px;
  cursor: pointer;
  span{
    color: #fff;
  }
  .marketplacesDot{
    height: 10px;
    width: 10px;
    border-radius: 100%;
    &.red{
      background-color: #FF4D4D;
    }
    &.green{
      background-color: #21BA45;
    }
  }
  &.marketplacesBtnDisabled{
    background-color: #B6B6B6;
    .marketplacesDot{
      &.red{
        background-color: #fff;
      }
      &.green{
        background-color: #fff;
      }
    }

  }
}
.toolbar {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  position: sticky;
  top: 0;
  background: #f8f8f8;
  height: 50px;
  padding: 5px 0;
  z-index: 999;
  gap: 10px;

  .toolbar__left {
    align-items: center !important;
    display: flex !important;
    gap: 20px;

    .reactDatePickerBlock {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .toolbar__right {
    align-items: center !important;
    display: flex !important;
    gap: 10px;
  }
}

.saveAndCancel {
  display: flex;
  align-items: center;
  gap: 5px;
}

.downPaginateBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.downPaginateBlockLoading {
    margin-top: 110px;
  }
}

.settings,
.csv {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  text-align: right !important;
  padding: 10px;
  border: 1px solid #1E3166;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  transition: opacity .25s ease,
  background-color .1s ease,
  color .1s ease,
  box-shadow .1s ease,
  background .1s ease,
  -webkit-box-shadow .1s ease;

  &:hover {
    opacity: .9;
  }
}

.bulkBtnWrapper {
  transition: opacity .25s ease,
  background-color .1s ease,
  color .1s ease,
  box-shadow .1s ease,
  background .1s ease,
  -webkit-box-shadow .1s ease;

  &:hover {
    opacity: .9;
  }
}

.perPageSelectBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
}

.csvIndicator {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  color: #000;
}

@media (min-width: 769px) and (max-width: 1440px)  {
  .salesPageLabel {
    gap: 20px !important;

    div {
      font-size: 16px !important;
    }

    .title {
      font-size: 24px !important;
    }
  }
  .marketplacesBtn {
    padding: 12px ;
    min-width: 146px ;
    height: 32px;
    border-radius: 6px;

    svg {
      width: 16px;
      height: 13px;
    }


    .marketplacesDot {
      height: 8px !important;
      width: 8px !important;
    }
  }
  .toolbar {
    gap: 8px  !important;
  }
  .toolbar__left {
    gap: 16px !important;

    .reactDatePickerBlock {
      gap: 12px !important;
    }

  }
  .toolbar__right {
    gap: 8px !important;
  }
  .saveAndCancel {
    gap: 4px !important;
  }
  .settings,
  .csv {
    padding: 8px !important;
  }
  .perPageSelectBlock {
    gap: 8px ;
  }
  .csvIndicator {
    font-size: 13px !important;
  }
}

