@import '../../assets/styles/variables.scss';

.mainPage {
	font-family: $fontFamilyOpenSans !important;
	display: flex;
	justify-content: center;
	align-items: center;

	.mainHeader {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;

		h2 {
			color: #007D5F;
			font-size: 20px;
			font-style: italic;
			font-weight: bold;

		}

		p {
			font-size: 20px;
			color: #393838;
			font-weight: bold;
		}
	}
}

@media (min-width: 769px) and (max-width: 1440px)  {
	.mainHeader {
		gap: 16px;

		h2 {
			font-size: 16px;
		}

		p {
			font-size: 16px;
		}
	}
}