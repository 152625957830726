.editAddModalContainer{
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all .2s;
  padding: 100px 40px;
  left: auto;
  width: 80vw;
  .closeEditAddModalMobile{
    display: none;
    position: fixed;
    right: 22px;
    top: 18px;
    background-color: #fff;
    z-index: 9991;
    cursor: pointer;
    svg{
      height: 40px;
      width: 40px;
    }
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
  &.editAddModalFixed{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9993;
    width: 80vw;
    height: 100vh;
    overflow-y: auto;
    padding: 100px 40px 60px 40px;
    .editModalBody{
      @media only screen and (max-width: 768px) {
        padding-bottom: 110px;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 20px;
    }
  }
  &.editAddModalPage{
    position: relative;
    width: 100%;
    padding: 0;
    .editModalBody{
      padding:20px 40px 40px 40px;
      @media (min-width: 769px) and (max-width: 1440px) {
        padding: 16px 32px 32px 32px;
      }
      @media only screen and (max-width: 768px) {
        padding: 0 20px 150px 20px;
      }
    }
    .headerTitle{
      padding: 20px 0;
      margin-bottom: 0;
      @media (min-width: 769px) and (max-width: 1440px) {
        padding: 16px 0;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8d8d8d;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  .closeEditAddModalMobile{
    display: none;
    position: fixed;
    right: 22px;
    top: 18px;
    background-color: #fff;
    z-index: 9991;
    cursor: pointer;
    svg{
      height: 40px;
      width: 40px;
    }
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  .expressListerSwitcher{
    display: flex;
    margin-bottom: 20px;
    .switcherItem{
      border: 1px solid  #767676;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      height: 32px;
      display: flex;
      align-items: center;
      padding: 15px;
      cursor: pointer;
      &.fullListingForm{
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #1E3166;
        background: #D9E2FC;
        color: #1E3166;
    }
      &.expressListerForm{
        border-radius: 0px 8px 8px 0px;
        color:  #767676;
        &:hover{
          border: 1px solid #1E3166;
          background: #D9E2FC;
          color: #1E3166;
        }
      }
    }
  }
  .loaderForSavingProgress{
    display: flex;
    gap: 10px;
    background-color: #fff;
    padding: 10px 15px;
    align-items: center;
    border: 1px solid #cecece;
    color: #333;
    font-weight: 600;
    border-radius: 8px;
    position: fixed;
    right: 20px;
    bottom: 80px;
    z-index: 9991;
    @media only screen and (max-width: 768px) {
     bottom: 150px;
    }
  }
  .loadercontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    &.loaderContainerModal{
      height: 100%;
    }
  }
  .headerTitle {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: none;
    text-align: left;
    margin-left: 20px;
    &.modalTitle{
      margin-left: 0;
    }
    @media only screen and (max-width: 768px) {
      display: block;
      margin-bottom: 20px;
    }
  }
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;
  }
  .uploadImagesContainer {
    margin: 20px 0;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin: 16px 0;
    }
    .title {
      color: #393838;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
    .uploadImagesSlider {
      border-radius: 8px;
      margin-top: 55px;

      @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 6px;
        margin-top: 44px;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 20px;
      }
    }
    .uploadImagesHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 21px;

      @media only screen and (max-width: 768px) {
        margin-top: 15px;
        img {
          display: none;
        }
        .leftPart {
          flex-direction: column;
          gap: 19px;
        }
      }

      .leftPart {
        display: flex;
        .bulkSelect {
          margin-right: 30px;
          color: #1e3166;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: 1px solid #1e3166;
          border-radius: 8px;
          padding: 10px 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          padding-right: 80px;
          position: relative;
          cursor: pointer;
          &.arrowDown {
            .arrow {
              position: absolute;
              right: 15px;
            }
          }
          &.arrowUp {
            .arrow {
              position: absolute;
              right: 15px;
              transform: rotate(180deg);
            }
          }
        }
        .newUploaderSwitcher {
          display: flex;
          align-items: center;
          .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #393838;
            margin-right: 10px;
            @media only screen and (max-width: 768px) {
              font-size: 16px;
              margin-bottom: 10px;
            }
          }
          .switch {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 18px;
          }

          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          .slider:before {
            position: absolute;
            content: '';
            height: 12px;
            width: 12px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          input:checked + .slider {
            background-color: #4db89e;
            &::before {
              position: absolute;
              content: '';
              height: 12px;
              width: 12px;
              left: -12px;
              bottom: 3px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }

          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    left: 0 !important;
    bottom: 0;
    padding: 20px 20px 80px 20px;
    width: 100%;
  }
}
.mobileAssistantContainer{
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
  }

  .listingAssistButton {
    background-color: #f2711c;
    border: none;
    border-radius: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 5px 10px;
    height: 40px;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
    svg{
      width: 15px;
      min-width: 15px;
    }
  }
}