.loading {
  width: 100%;
  height: 300px;
  position: relative;
  filter: blur(10px);
  clip-path: inset(0);
  border-radius: 8px;
}

.loaded {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  filter: blur(0px);
  transition: filter 0.5s linear;
  cursor: pointer;
  position: relative;
}
.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.progressiveImg {
  position: relative;
  @media only screen and (max-width: 768px){
    max-height: 295px;
    max-width: 295px;
    border: 1px solid #DDDDDD;
    background-color: #EFEFEF;
    border-radius: 8px;
  }
  .imageOrder {
    position: absolute;
    height: 20px;
    width: 24px;
    background-color: #4db89e;
    color: #fff;
    display: flex;
    bottom: -7px;
    right: -12px;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    z-index: 9;
  }
}