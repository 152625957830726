.fixedHeader{
  position: fixed;
  top: 0;
  background-color: #fff;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  border-bottom: 1px solid #DDD;
  z-index: 99;
  &.fixedHeaderOnModal{
    width: 80vw;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
    min-height: 60px;
  }
  .titleRightTop {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
      @media only screen and (max-width: 768px) {
        button{
          height: 32px;
          font-size: 14px;
          gap: 5px;
        }
        svg{
          height: 14px;
        }
      }
  }
  .headerButtonsContainer{
    display: flex;
    gap: 15px;
    align-items: center;
    svg{
      max-height: 25px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .inventoryLabelContainer{
    display: flex;
    align-items: center;
    gap: 5px;
    .popoverIcon{
      cursor: pointer;
    }
  }
  .headerButton{
    align-items: center;
    border: none;
    border-radius: 8px;
    background-color: rgb(77, 184, 158);
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    gap: 10px;
    height: 39px;
    justify-content: space-between;
    min-height: 39px;
    padding: 10px;
    transition: opacity .25s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
    white-space: nowrap;
    @media (min-width: 769px) and (max-width: 1440px) {
      border-radius: 6px;
      font-size: 13px;
      gap: 8px;
      height: 32px;
      min-height: 32px;
      padding: 8px;
    }
    &.buttonCrossPost{
      background-color: #4db89e;
      padding: 5px;
      svg{
        height: 16px;
        width: 16px;
      }
    }
  }

  .closeSvgRed{
    path{
      fill: #FF4D4D;
    }
  }
}

.mobileHeaderTitle{
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    color: #393838;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
}