@import "@assets/styles/variables.scss" ;

.uniqueIdFormat {

	.header {
			display: flex;
			align-items: center;
			gap:10px;
			margin-bottom: 5px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
				margin-bottom: 4px;

				svg {
					width: 16px;
					height: 16px;
				}
			}

		.title {
			color: #000;
			font-size: 16px;
			font-weight: 700;

			@media (min-width: 769px) and (max-width: 1440px)  {
				font-size: 13px;
			}
		}

		.popoverIcon {
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}
		}
	}


	.radioWrapper {
		display: flex;
		align-items: center;
		gap:5px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 4px;
		}

		.radioValue {
			border-radius: 8px;
			background: #d7e2ff;
			color: #1e3166;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 9px 15px;
			cursor: pointer;
			display: flex;
			width: fit-content;
			white-space: nowrap;
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
				padding: 7px 12px;
				border-radius: 6px;
			}
		}

		.radioValueActive {
			background: #1e3166;
			color:#fff;
		}
	}
}