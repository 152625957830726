.plansPageMain{
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  .plansPageContainer{
    padding: 40px;
    max-width: 1345px;
    margin: 0 auto;

    @media only screen and (max-width: 768px){
      padding: 15px 15px 30px 15px;
    }
    .plansPageTitle{
      color: #2B2B2B;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media only screen and (max-width: 768px){
        font-size: 18px;
      }
    }
    .plansPageDetails{
      display: flex;
      padding: 15px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      border-radius: 8px;
      background-color: #F8F8F8;
      margin-top: 25px;
      &.plansPageDetailsImportantTitle{
        background: #FFF0C3;
        .plansPageDetailsTitle{
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      .plansPageDetailsTitle{
        color: #2B2B2B;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        span{
          color:#2B2B2B;
          font-style: italic;
        }
      }
      .plansPageDetailsDesc{
        color:  #2B2B2B;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.plansPageDetailsDescList{
          margin-left: 42px;
          li{
            list-style-type: disc !important;
          }
        }
        span{
          color: #2B2B2B;
          font-weight: 700;
        }
      }
      @media only screen and (max-width: 768px){
        margin-top:15px;
      }
    }
  }
}