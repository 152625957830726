
.editAddModalContainer{
	background-color: #fff;
	transition: all .2s;
	padding: 0px 40px 100px 40px;
	//height: 100vh;
	z-index: 9993;
	left: auto;

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 0 32px 80px 32px;
	}
	&.editAddModalContainerFixed{
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9993;
		width: 80vw;
		height: 100vh;
		overflow-y: auto;
		padding: 60px 40px;
		@media only screen and (max-width: 768px) {
			width: 100%;
			padding: 20px 20px 60px 20px;
		}
	}

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #8d8d8d;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: #f5f5f5;
	}
	.closeEditAddModalMobile{
		display: none;
		position: fixed;
		right: 22px;
		top: 18px;
		background-color: #fff;
		z-index: 9991;
		cursor: pointer;
		svg{
			height: 40px;
			width: 40px;
		}
		@media only screen and (max-width: 768px) {
			display: block;
		}
	}
	.loaderForSavingProgress{
		display: flex;
		gap: 10px;
		background-color: #fff;
		padding: 10px 15px;
		align-items: center;
		border: 1px solid #cecece;
		color: #333;
		font-weight: 600;
		border-radius: 8px;
		position: fixed;
		right: 20px;
		bottom: 80px;
		z-index: 9991;
	}
	.loadercontainer{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		//position: fixed;
		//top: 0;
		//right: 0;
		//width: calc(100% - 230px);
		//bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		z-index: 99;
		@media only screen and (max-width: 768px) {
			width: 100%;
		}
	}
	.headerTitle {
		color: #000;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		display: none;
		text-align: left;
		@media only screen and (max-width: 768px) {
			display: block;
			margin-bottom: 20px;
		}
	}
	.popoverIcon {
		cursor: pointer;
		margin-left: 10px;
		min-width: 20px;
	}
	.uploadImagesContainer {
		margin: 20px 0;
		.title {
			color: #393838;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			display: flex;
			align-items: center;
			@media only screen and (max-width: 768px) {
				font-size: 16px;
				margin-bottom: 10px;
			}
		}
		.uploadImagesSlider {
			border-radius: 8px;
			margin-top: 55px;
			@media only screen and (max-width: 768px) {
				margin-top: 20px;
			}
		}
		.uploadImagesHeader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 21px;

			@media only screen and (max-width: 768px) {
				margin-top: 15px;
				img {
					display: none;
				}
				.leftPart {
					flex-direction: column;
					gap: 19px;
				}
			}

			.leftPart {
				display: flex;
				.bulkSelect {
					margin-right: 30px;
					color: #1e3166;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					border: 1px solid #1e3166;
					border-radius: 8px;
					padding: 10px 15px;
					display: flex;
					align-items: center;
					gap: 10px;
					padding-right: 80px;
					position: relative;
					cursor: pointer;
					&.arrowDown {
						.arrow {
							position: absolute;
							right: 15px;
						}
					}
					&.arrowUp {
						.arrow {
							position: absolute;
							right: 15px;
							transform: rotate(180deg);
						}
					}
				}
				.newUploaderSwitcher {
					display: flex;
					align-items: center;
					.title {
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						color: #393838;
						margin-right: 10px;
						@media only screen and (max-width: 768px) {
							font-size: 16px;
							margin-bottom: 10px;
						}
					}
					.switch {
						position: relative;
						display: inline-block;
						width: 30px;
						height: 18px;
					}

					.switch input {
						opacity: 0;
						width: 0;
						height: 0;
					}

					.slider {
						position: absolute;
						cursor: pointer;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: #ccc;
						-webkit-transition: 0.4s;
						transition: 0.4s;
					}

					.slider:before {
						position: absolute;
						content: '';
						height: 12px;
						width: 12px;
						left: 3px;
						bottom: 3px;
						background-color: white;
						-webkit-transition: 0.4s;
						transition: 0.4s;
					}

					input:checked + .slider {
						background-color: #4db89e;
						&::before {
							position: absolute;
							content: '';
							height: 12px;
							width: 12px;
							left: -12px;
							bottom: 3px;
							background-color: white;
							-webkit-transition: 0.4s;
							transition: 0.4s;
						}
					}

					input:focus + .slider {
						box-shadow: 0 0 1px #2196f3;
					}

					input:checked + .slider:before {
						-webkit-transform: translateX(26px);
						-ms-transform: translateX(26px);
						transform: translateX(26px);
					}

					/* Rounded sliders */
					.slider.round {
						border-radius: 34px;
					}

					.slider.round:before {
						border-radius: 50%;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 768px) {
		left: 0 !important;
		bottom: 0;
		padding: 20px 20px 60px 20px;
		width: 100%;
	}
}