#templatesTable .resize-handle {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 5px;
    right: 0;
    top: 0;
    border-right: 6px solid;
    border-color: rgba(215, 226, 255, 0.5);
}
#templatesTable .resize-handle:hover {
    border-color: #ccc;
}
#templatesTable .resize-handle.active {
    border-color: #4db89e;
}
#templatesTable tbody tr {
    td:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#templatesTable tbody td {
    display: flex !important;
    align-items: center;
    max-width: 500px !important;
    word-break: break-word;
    color: #393838;
}
#templatesTable .stickyColumnActive{
    position: sticky;
    left: 70px;
    z-index: 9;
    background-color: #f8f8f8;
}
#templatesTable .stickyCheckboxColumn{
    position: sticky;
    left: 0;
    z-index: 9;
    background-color: #f8f8f8;
}
/*.stickyColumnActive{*/
/*    background: #C5EEE4 !important;*/
/*}*/
#templatesTable{
    display: grid;
    overflow: auto;
    border: 1px solid #d9d9d9;
    /*max-height: calc(100vh - 70px);*/
}

#templatesTable thead th {
    @media (min-width: 769px) and (max-width: 1440px) {
        max-height: 70px !important;
    }
}

#templatesTable thead tr th:first-child {
    border-left: none;
    justify-content: center;
    align-items: center;
    border-right: 6px solid rgba(215, 226, 255, 0.5);
}


#templatesTable tbody {
    tr:nth-child(odd) td {
        background-color: #fff;
    }
    tr:nth-child(odd) td.stickyColumnActive {
       background-color: #fff;
   }
    tr:nth-child(odd) td.stickyCheckboxColumn {
        background-color: #fff;
    }
}
#templatesTable thead, #templatesTable th {
    user-select: none;
}
#templatesTable tbody, #templatesTable thead, #templatesTable tr{
    display: contents;
}
#templatesTable  thead tr .sticky-element {
    border-left: none ;
    border-right: none ;
    border-bottom: 1px solid #D9D9D9;
    font-weight: 600;
    color: #1E3166;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: baseline;
    justify-content: left;
    transition: transform 0.1s ease-out;

    @media (min-width: 769px) and (max-width: 1440px) {
        gap: 4px;
    }
}

#templatesTable tbody td{
    border: none !important;
}

.tableAboveBlock{
    display: flex;
    align-items: center;
    gap: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
        gap: 16px;
    }
}

#templatesTable thead tr .sticky-element.sticky-checkbox-column {
    left: 0;
    z-index: 999;
}
#templatesTable thead tr .sticky-element .th-inner-container{
    display: flex;
    align-items: flex-start;
    gap: 5px;
}
#templatesTable thead tr .sticky-element#sticky-image-col{
    position: sticky;
    left: 70px;
    z-index: 999;
}
#templatesTable thead tr .sticky-element .th-inner-container .lock-icon{
    height: 18px;
    width: 20px;
}
@media only screen and (max-width: 768px){
    #templatesTable thead tr .sticky-element .th-inner-container .lock-icon{
       display: none;
    }
}
#templatesTable thead tr .sticky-element .th-inner-container .lock-icon svg{
    cursor: pointer;
    height: 20px !important;
    width: 20px !important;
    transform: none !important;
    margin: 0 !important;
}
.hiddenElementForTippyStickyColumnsTemplates{
    visibility: hidden;
    position: absolute;
    top: -9999px;
}
.hiddenElementForTippyStickyColumnsTemplates .tippy-box{
    background-color: #fff;
    border: 1px solid #cecece;
    font-weight: normal;
    color: #333;
    z-index: 9999 !important;
}
.hiddenElementForTippyStickyColumnsTemplates .tippy-content{
    color: #333 !important;
}
#templatesTable thead tr .sticky-element .th-inner-container .lock-icon svg path {
    fill: rgba(30, 49, 102, 0.5);
}
#templatesTable thead tr .sticky-element .th-inner-container .lock-icon.sticky-column-active-template svg path{
    fill: #1E3166;
}