.listItemContainer {
	display: flex;
	align-items: center;
	width: max-content;
	gap: 10px;
	padding: 10px 10px 10px 10px;
	border-radius: 8px;
	width: 100%;
	&:hover {
		cursor: pointer;
		background-color: #D9E2FC;
	}
	.marketName {
		display: flex;
		gap: 5px;
		color: #000;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		display: flex;
		align-items: center;
		span {
			cursor: pointer;
		}
	}
}