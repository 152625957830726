.footerButtonsMobile{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  margin: 20px 0;


  @media only screen and (max-width: 768px) {
    display: flex;
    position: fixed;
    bottom: 43px;
    background-color: #fff;
    left: 0;
    right: 0;
    padding: 15px;
    z-index: 9991;
  }
  &.footerButtonsMobileOnModal{
    @media only screen and (max-width: 768px) {
      bottom: 0;
      margin: 0;
    }
  }
}
