.listingAssistantContainer {
  background-color: #F5F5F5;
  border-radius: 12px;
  width: 600px;
  height: fit-content;
  position: absolute;
  z-index: 9991;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  border: 1px solid #F2711C;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    width: calc(100% - 20px);
  }

  .scanningError{
    color: red;
    font-size: 16px;
    font-style: italic;
  }

  .autoModalSwitcher{
    display: flex;
    align-items: center;
    gap: 5px;
    position: absolute;
    right: 20px;
    bottom: 28px;
    @media only screen and (max-width: 768px) {
      bottom: 18px;
    }
  }
  .myCheckbox {
    cursor: pointer;
    margin: 0 !important;
    -webkit-appearance: none;
    border: 1px solid #1e3166;
    border-radius: 8px;
    outline: none;
    width: 25px;
    height: 25px;
    font: initial;
    flex-shrink: 0;
    background-color: #fff;
    &.defaultstickyCheckbox{
      margin-left: 6px !important;
    }

    &::after {
      content: ' ';
      display: block;
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center;
      left: 9px;
      top: 4px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: relative;
      width: 5px;
      height: 10px;
    }

    &:checked {
      background-color: #1e3166;
      border-color: #1e3166;
      &::after {
        content: ' ';
        display: block;
        background-size: 35px;
        background-repeat: no-repeat;
        background-position: center;
        left: 9px;
        top: 4px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: relative;
        width: 5px;
        height: 10px;
        background-color: #1e3166 !important;
      }
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
  .closeListingAssistant{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .buttonsContainerForAssistant{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 28px;
    @media only screen and (max-width: 768px) {
      gap: 10px;
      flex-direction: column;
    }
    .assistantButtonChoice{
      cursor: pointer;
      display: flex;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #1E3166;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
      outline: none;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .assistHeading{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
    svg{
      path{
        fill: #F2711C;
      }
    }
  }
  h2 {
    width: 100%;
  }
  .charactersQuantity{
    color: #767676;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
  }
  .assistantLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 380px;
    height: 430px;
  }
  .assistDesc {
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .notifyModalDesc{
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
    width: 100%;
    color: #000;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 20px 0;
  }
  .or {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
    margin-top: 30px;
  }
  .detailedDate{
    width: 100%;
    margin: 30px 0;
    .charactersQuantity{
      color: #767676;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
    }
  }
  .detailedTitleContainer{
    display: flex;
    width: 100%;
    .detailedDateInputTitle{
      width: 100% !important;
      resize: none;
      outline: none;
      padding: 10px;
      height: 42px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #B6B6B6;
      background: #FFF;
      &:focus {
        border: 1px solid #393838;
      }
    }

  }

  .submitButtons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    gap: 30px;
    button {
      color:  #1E3166;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: inline-flex;
      height: 40px;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      border: 1px solid  #1E3166;
      background-color: transparent;
      cursor: pointer;
    }
    @media only screen and (max-width: 768px) {
      gap: 10px;
      margin-bottom: 30px;
      margin-top: 20px;
      button{
        font-size: 14px;
      }
    }
  }
  .scanInput {
    margin-top: 30px;
    position: relative;
    width: 100%;
    .detailedDateInput {
      width: 100% !important;
      resize: none;
      outline: none;
      padding: 20px;
      padding-right: 80px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #B6B6B6;
      background: #FFF;
      &:focus {
        border: 1px solid #393838;
      }
    }

    .scanInputContainer {
      display: flex;
    }
    .scanBtn {
      cursor: pointer;
      border-radius: 4px;
      background: #1E3166;
      display: flex;
      width: 84px;
      height: 42px;
      margin-left: -2px;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: #fff;
      border: none;
      outline: none;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .charactersQuantity {
      color: #767676;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      @media only screen and (max-width: 768px) {
        margin: 8px 0;
      }
    }
  }
  .scanInput {
    .detailedDateInput {
      border-radius: 8px 0 0 8px;
      font-size: 16px;
    }
    .showSubmitBtn {
      background-color: #007bff;
      border: none;
      color: #fff;
      border-radius: 6px;
      padding: 5px 10px;
      position: absolute;
      right: 100px;
      top: 7px;
      transition: all 0.2s;
      cursor: pointer;
    }
  }
  .detailedDate {
    .showSubmitBtn {
      cursor: pointer;
      border-radius: 4px;
      background: #1E3166;
      display: flex;
      width: 84px;
      height: 42px;
      margin-left: -2px;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: #fff;
      border: none;
      outline: none;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.listingAssistantMain {
  height: 100%;
  width: 100%;
  background-color: rgba(39, 55, 77, 0.5);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.isModal{
    width: 80vw;
    left: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
      left: 0;
    }
  }

}