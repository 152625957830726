@media print {
    @page {
        size: portrait;
    }
    .myBtn {
        display: none;
    }
     #closePrint {
        display: none;
    }
}

