@import '@assets/styles/variables.scss';

.perPage {

  position: relative;
  min-width: 70px;

  .perPageTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 8px 10px 8px;
    border: 1px solid #ccc;
    border-radius: $borderRadius8;
    transition: $transition;

    &:hover {
      opacity: $globalOpacity;
    }

  }

  .perPageNumbersWrapper {
    position: absolute;
    top: 115%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: $borderRadius8;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
      cursor: pointer;
      transition: $transition;
      padding: 2px 8px;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &:hover {
        background: #D7E2FF;
      }
    }
  }

  .down__icon {
    width: 14px;
    height: 14px;
    min-height: 14px;
    min-width: 14px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(15%) sepia(57%) saturate(2207%) hue-rotate(209deg)
    brightness(82%) contrast(88%);
    border: none !important;
  }

  .dropdown__icon_rotate180 {
    width: 14px;
    height: 14px;
    min-height: 14px;
    min-width: 14px;
    background-repeat: no-repeat;
    background-position: center;
    filter: invert(15%) sepia(57%) saturate(2207%) hue-rotate(209deg)
    brightness(82%) contrast(88%);
    transform: rotate(180deg);
    border: none !important;
  }
}

@media (min-width: 769px) and (max-width: 1440px) {
  .perPage {
    min-width: 56px;
    .perPageTitle {
      border-radius: 6px;
      padding: 6px 6px 8px 6px;
      span {
        font-size: 13px;
      }
    }

  }

  .dropdown__icon_rotate180,.down__icon {
    width: 11px !important;
    height: 11px !important;
    min-height: 11px !important;
    min-width: 11px !important  ;
  }
}