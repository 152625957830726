.fullImageModalContainer {
  position: fixed;
  background-color: #fff;
  z-index: 9995;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  .fullImageLoader{
    position: fixed;
    top: 0;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imageOuter {
    overflow: hidden;
    cursor: zoom-in;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    @media only screen and (max-width: 768px) {
      margin: 30px;
    }
  }
  .quantity {
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 24px;
  }

  .CloseModal {
    position: fixed;
    right: 15px;
    top: 10px;
    z-index: 9991;
    cursor: pointer;
    svg{
      path{
        fill: #FF4D4D;
      }
    }
    @media only screen and (max-width: 768px) {
      button{
        height: 32px;
        font-size: 14px;
        gap: 5px;
      }
      svg{
        height: 14px;
      }
    }
  }
  .slide {
    width: 100vw !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;

    img {
      width: auto;
      height: auto;
      min-width: initial;
      min-height: initial;
      max-width: 820px;
      max-height: 100%;
      border-radius: 8px;
      @media only screen and (max-width: 768px){
        max-height: 400px;
      }
      @media only screen and (max-height: 832px) {
        height: 100%;
        width: 80%;
      }
      @media only screen and (max-height: 786px) {
        height: 100%;
        width: 70%;
      }
      @media only screen and (max-height: 580px) {
        height: 100%;
        width: 50%;
      }
      @media only screen and (max-height: 768px) and (max-width: 768px) {
        width: 70%;
        flex: 1;
        height: auto;
        max-height: 80vh;
      }

    }
  }
}

.arrowContainer {
  display: flex !important;
}
.arrowSvg {
  width: 21px;
  height: 24px;
}
.leftArrow {
  background-color: #1e3166;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 9991;
  left: 15px;
  svg {
    cursor: pointer;
  }
}
.inactiveLeftArrow {
  background-color: #98a3c1;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 9991;
  left: 110px;
  svg {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    left: 10px;
  }
}

.rightArrow {
  background-color: #1e3166;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 9991;
  right: 15px;
  svg {
    cursor: pointer;
  }
}
.inactiveRightArrow {
  background-color: #98a3c1;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 9991;
  right: 10px;
  svg {
    cursor: pointer;
  }
}