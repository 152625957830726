.listingAssistantModalContainer{
  background-color: rgba(39, 55, 77, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 6px;
  &.isModal{
    width: 80vw !important;
    left: auto;
    @media only screen and (max-width: 768px) {
      width: 100% !important;
      left: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    z-index: 99999;
    width: 100% !important;
    left: 0;
    padding: 10px;
    padding-bottom: 62px;
  }

  .listingAssistantTitle{
    display: flex;
    align-items: center;
    gap: 10px;
    svg{
      path{
        fill: #F2711C;
      }
    }
  }
  .assistantNotifyModal{
    max-width: 400px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #F2711C;
    padding: 15px 20px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-height: 150px;
    position: absolute;
    background-color: #fff;
    z-index: 999;
    p{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin-top: 30px;
    }
  }
  .assistantInnerContainer{
    @media only screen and (max-width: 768px) {
      overflow: auto;
      max-height: calc(100vh - 190px);
    }
  }
  .toneTitleContainer, .contextTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    p{
      display: flex;
      align-items: center;
      gap: 5px;
      &.headingTitle{
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .contextTitleContainer{
    margin-top: 10px;
  }
  .popoverIcon{
    cursor: pointer;
  }
  .listingAssistantModal{
    max-width: 580px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #F2711C;
    background:#F5F5F5;
    padding: 15px 20px;
    min-height: 223px;
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
    .assistantTextarea{
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      width: 100% !important;
      resize: none;
      outline: none;
      padding: 10px;
      font-size: 16px;
      &:focus {
        border: 1px solid #393838;
      }
    }
    .suggestedList{
      margin-bottom: 10px;
      li{
        margin-left: 12px;
        position:relative;
        margin-top: 5px;
        &::before{
          content: '';
          position: absolute;
          left: -12px;
          top: 8px;
          height: 7px;
          width: 7px;
          border-radius: 50%;
          background-color: #333;
        }
      }
    }
    .closeModal{
      position: absolute;
      cursor: pointer;
      right: 20px;
      z-index: 999;
    }
    .loadercontainerOverlay{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }
    .notice{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
    .description{
      color: #000;;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 10px;
    }
    .available{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 10px;
      display: flex;
      gap: 5px;
      align-items: center;
    }
    .launchButton{
      border-radius: 8px;
      border: 1px solid  #F2711C;
      background: #F2711C;
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      height: 39px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      margin: 10px auto 0 auto;
      cursor: pointer;
      @media only screen and (max-width: 768px) {
        height: auto;
        padding: 5px 10px;
        text-align: center;
      }
      &.disabledLaunchButton{
        background-color:rgb(182, 182, 182);
        border: 1px solid rgb(182, 182, 182);
        cursor: not-allowed;
      }
    }
  }
}