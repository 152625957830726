$borderRadius8 : 8px;

$fontFamilyOpenSans: "Open Sans", sans-serif;

$textColor: #fff;
$dark: #393838;
$gray: #b6b6b6;
$lightGray: #e5e5e5;
$darkBlue: #1e3166;
$darkBlue2: #25386d;
$blue: hsl(194, 92%, 71%);
$mint: #4db89e;
$mint2: #8AE2CC;
$secondaryBgColor: #32C09A;
$green: rgba(63, 209, 139, 0.6);
$yellow: #fbbe0a;
$lightYellow: #fcd45f;
$red: #ca1010;
$lightRed: #f37b7b;
$dangerRed: #FF5353;

$red2: #f44336;
$platformBackgroundColor : rgba(77, 184, 158, 0.1);
$NotPlafformBackgroundColor : rgba(202, 16, 16, 0.1);

$editBtnColor : #4eb99e;
$pageBtnColor : #234165;

$activeBackgroundColor: #173593;

$tabBgColor: #D7E2FF;
$tabBgColor2: #EDEDED;


$searchItemBg: #f3f3f3;
$clearButtonBg: #D7E2FF;

$tableRowBgColor :#F4F7FF;
$transition : opacity 0.25s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
$globalOpacity:.7