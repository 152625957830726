@import '../../../../assets/styles/variables.scss';

.toolbar {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center;
	margin-bottom: 10px;

	.toolbar__left {
		align-items: center !important;
		display: flex !important;
		gap: 20px !important;

		.selects__box {
			display: flex !important;
			align-items: center !important;
			gap: 10px !important;
		}
	}

	.toolbar__right {
		align-items: center !important;
		display: flex !important;

		gap: 10px;
	}
}

.noResultsSalesPageOuter {
  height: 120px;
  position: relative;
}

.noResultsSalesPage {
  position: relative;
  padding: 33px;
  top: -110px;
  width: calc(94vw - 238px);

  text-align: center;
  margin: 0 10px;

  @media only screen and (max-width: 1257px) {
    width: calc(91vw - 236px);
  }

  p {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (min-width: 769px) and (max-width: 1440px) {
      font-size: 16px;
    }
  }
}

.loaderTableSales {
  position: relative;
  padding: 33px;
  width: calc(94vw - 238px);
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.tableWrapper {
  position: relative;

  .tableContainer {
    position: relative;

    table {
      display: grid;
      overflow-x: auto;
      overflow-y: scroll;
      height: auto;
      max-height: calc(100vh - 70px);
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;

      @media (min-width: 769px) and (max-width: 1440px) {
        max-height: calc((100vh - 70px));
      }

      .myCheckbox {
        cursor: pointer;
        margin: 0 !important;
        -webkit-appearance: none;
        border: 1px solid #1e3166;
        border-radius: 8px;
        outline: none;
        width: 25px;
        height: 25px;
        font: initial;
        flex-shrink: 0;
        background-color: #fff;
        transition: $transition;

        &:hover {
          opacity: $globalOpacity

        ;
        }

        @media (min-width: 769px) and (max-width: 1440px) {
          width: 20px !important;
          height: 20px !important;
          border-radius:6px !important;
        }
        &.defaultstickyCheckbox{
          margin-left: -6px !important;
        }
        &::after {
          content: ' ';
          display: block;
          background-size: 35px;
          background-repeat: no-repeat;
          background-position: center;
          left: 9px;
          top: 4px;
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: relative;
          width: 5px;
          height: 10px;

          @media (min-width: 769px) and (max-width: 1440px) {
            left: 7px;
            top: 3px;
            width: 4px;
            height: 8px;
            border-width: 0 1px 1px 0;
          }
        }

        &:checked {
          background-color: #1e3166;
          border-color: #1e3166;
        }

        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }
      }

      thead {
        display: contents;

        tr {
          display: contents;
        }

        th {
          max-width: 500px;
          min-width: 70px;
          overflow: hidden;
          text-transform: capitalize !important;

          @media (min-width: 769px) and (max-width: 1440px) {
            min-width: 56px;
          }

        }
      }

      tbody {
        display: contents;



        tr {
          display: contents;
          text-align: center;
          border: 1px solid gray !important;
          overflow: hidden;

          input:not([type='checkbox']) {
            width: calc(100% - 20px);

            margin-left: 10px !important;
            margin-right: 10px !important;

            @media (min-width: 769px) and (max-width: 1440px) {
              width: calc(100% - 16px);
            }
          }

          select {
            width: calc(100% - 20px);
            margin-left: 10px !important;
            margin-right: 10px !important;

            @media (min-width: 769px) and (max-width: 1440px) {
              width: calc(100% - 16px);
            }
          }

          &:last-child {
            border: none !important;
          }

          td {
            display: flex;
            justify-content: center;
            align-items: center;
            word-break: break-word;
            color: #393838;

            .platformsWrapper {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(50px, 140px));
              gap: 0 8px;
              padding: 10px;
              overflow: hidden;

              >  div {
                svg {
                  @media (min-width: 769px) and (max-width: 1440px) {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                  }
                }

                a {
                  @media (min-width: 769px) and (max-width: 1440px) {
                    div {
                      font-size: 13px;
                    }
                  }
                }

                @media (min-width: 769px) and (max-width: 1440px) {
                  div {
                    font-size: 13px;
                  }
                }

              }



              @media (min-width: 769px) and (max-width: 1440px) {
                grid-template-columns: repeat(auto-fit, minmax(40px, 112px));
              }
              span {
                div {
                  img {
                    width: 35px;
                    height: 35px;

                    @media (min-width: 769px) and (max-width: 1440px) {
                      width: 28px;
                      height: 28px;
                    }
                  }
                }
              }
            }

            a {
              color: #000;
              width: fit-content;
            }
          }

          .thumbnailTd {
            div {
              svg {
                height: 67px;
                fill:#B6B6B6;

                @media (min-width: 769px) and (max-width: 1440px) {
                  height: 54px;
                }
              }
            }
          }

          .platformTd {
            a {
              width: 40px;
              transition: $transition;

              &:hover {
                opacity: $globalOpacity
              }
              @media (min-width: 769px) and (max-width: 1440px) {
                width: 32px;

                svg {
                  width: 32px;
                  height: 32px;
                }
              }

              img {
                width: 100%;
                height: 40px;
              }
            }
          }

          .actionButtons {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            justify-content: center;

            @media (min-width: 769px) and (max-width: 1440px) {
              gap: 8px;
            }

            .arrow {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              svg {
                width: 30px;
                height: 15px;

                @media (min-width: 769px) and (max-width: 1440px) {
                  width: 24px;
                  height: 12px;
                }
              }
            }

            .actionColumnSvg {
              border-radius: 8px;
              background: var(--White, #fff);
              padding: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              max-width: 46px;
              transition: $transition;

              &:hover {
                opacity: $globalOpacity;
              }
              @media (min-width: 769px) and (max-width: 1440px) {
                padding: 8px;
                max-width: 37px;
              }

              svg {
                width: 24px;
                height: 19.2px;
                flex-shrink: 0;

                @media (min-width: 769px) and (max-width: 1440px) {
                  width: 20px;
                  height: 15px;
                }
              }
            }
            .recoverySpan {
              filL: $red2;
            }
          }

          .linkedTd {
            div {
              cursor: pointer;
                svg {
                  width: 35px;
                  height: 35px;

                  @media (min-width: 769px) and (max-width: 1440px) {
                    width: 28px;
                    height: 28px;
                  }
                }
              
            }
          }

          .shippingStatusTd {
            word-break: break-word;
            overflow: hidden;

            div {
              &:first-child {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.leftContainer {
  position: fixed;
  cursor: pointer;
  z-index: 999;
  margin-left: -15px;
  top: calc(100vh - 400px);
  display: none;
  transition: $transition;

  &:hover {
    opacity: $globalOpacity;
  }

  svg {
    width: 40px;

    @media (min-width: 769px) and (max-width: 1440px) {
      width: 32px;
    }
  }
}

.rightContainer {
  position: fixed;
  cursor: pointer;
  z-index: 999;
  top: calc(100vh - 400px);
  display: none;
  transition: $transition;

  &:hover {
    opacity: $globalOpacity;
  }

  svg {
    width: 40px;

    @media (min-width: 769px) and (max-width: 1440px) {
      width: 32px;
    }
  }
}

.noData {
  text-align: center !important;
  padding: 10px 0 !important;

  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 8px !important;
  }
}

.tableFitContent {
  height: fit-content !important;
}

.saveAndCancel {
  display: flex;
  align-items: center;
  gap: 5px;

  @media (min-width: 769px) and (max-width: 1440px) {
    gap: 4px;
  }
}

.hideLeftContainer {
  display: none !important;
}

.hideRightContainer {
  display: none !important;
}

.activeTr {
  td {
    //background-color: rgba(138, 226, 204, 0.4588235294) !important;
    background: #C5EEE4 !important;
  }
}

.evenTr td{
	background: #fff;
}

.oddTr  td{
	background: #f8f8f8;
}

.linked {
  cursor: pointer !important;
  transition: $transition;

  &:hover {
    opacity: $globalOpacity;
  }

    svg {
      width: 35px;
      height: 35px;

      @media (min-width: 769px) and (max-width: 1440px) {
        width: 28px;
        height: 28px;
      }
    }
}

.multiOrderDefault {
  position: relative;
  .nestedTableDataLength {
    color:#fff;
    padding: 4px 18px;
    border-radius: 10px;
    border: 1px solid#FFF;
    background: #4db89e;
    position: absolute;
    right: 0;
    bottom: -8px;
    z-index: 99;

    @media (min-width: 769px) and (max-width: 1440px) {
      padding: 3px 14px;
    }

  }
}