.detailsContainerMain{
  padding: 20px 20px 20px 0;
  display: flex;
  flex-direction: column;
  .fieldTitle {
    color: var(--Gray-2, #4f4f4f);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .itemDetails , .shipDescription{
    padding: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    @media screen and (max-width: 768px) {
      padding: 0 20px;

    }
    .itemDetailsInfo {
      display: flex;
      flex-wrap: wrap;
      row-gap: 30px;
      column-gap: 50px;
      margin-bottom: 30px;
      max-width: 100%;
      justify-content: flex-start;
      box-sizing: border-box;
      &.keywordsContainer{
        margin-top: 20px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 768px) {
        row-gap: 15px;
        column-gap: 20px;
      }
    }
  }
  .shipDescription{
    margin-top: 10px;
    .shipping {
      display: flex;
      gap: 40px;
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    }
  }
  .conditionsContainer{
    display: flex;
    gap: 50px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  .objectNameValueDiv{
    max-width: 230px;
  }
  .itemDetailsObjectKeyName {
    color: #767676;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
  }
  .itemDetailsObjectValue {
    color: var(--Dark, #393838);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .objectValueText {
    color: var(--Dark, #393838);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
  .itemValues {
    display: flex;
    gap: 40px;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 10px;
    }
  }
  .keywordDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px 10px 0;
  }
  .fieldTitleDescription{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .itemDetailsKeywords{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .copiedText{
    font-size: 12px;
    font-weight: bold;
    color: #4db89e;
  }
  .line{
    height: 1px;
    background: #DDD;
    margin: 20px 0 20px -30px;
    width: calc(100% + 60px);
  }
  .keywordsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;

    li {
      padding: 5px 10px;
      background: rgb(215, 226, 255);
      border-radius: 4px;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
        word-break: break-all;
      }
    }
  }
  .shippingObjectKeyName {
    display: flex;
    flex-direction: column;
    color: var(--Grey1, #767676);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .shippingObjectValue {
    color: var(--Dark, #393838);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .globalFooters {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    .platformFootersText {
      color: var(--Dark, #393838);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .lpIdContainer{
      .lpHeading{
        color: #b8b6b6;
        font-size: 16px;
      }
      .lpId{
        font-size: 16px;
      }
    }
    .footerItemContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .globalFootersItemSubTitle {
        color: var(--Dark, #393838);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

      }
      .globalFootersItemTitle {
        color: var(--Grey1, #767676);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .packageProperties {
    display: flex;
    column-gap: 7px;
  }
  .packageObjectValue {
    color: var(--Dark, #393838);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media screen and (max-width: 768px) {
     padding: 0;
     margin-top: 0;
    .packageObjectValue {
      font-size: 16px;
    }
  }
  .packageMeausure {
    color: var(--Grey-Text, #999);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .description {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 10px;
  }
  .popoverIcon{
    cursor: pointer;
    margin-left: -10px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  .descriptionText {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-word !important;
    pre, span{
      font-size: 14px !important;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: pre-line !important;
      max-width: 100%;
      box-sizing: border-box;
      display: inline-block;
    }

    div {
      color: var(--Gray-1, #333) !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      word-break: break-word !important;
    }
  }
  .ordersFooters {
    padding: 0 40px 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    @media screen and (max-width: 768px) {
      padding: 0 20px;

    }
  }

}
