
.overlay {
	font-family: "Open Sans", 'sans-serif';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background: rgba(0, 0, 0, 0.40);

	.successModal {
		width: 400px;
		background: #fff;
		border-radius: 8px;
		border: 1px solid #4DB89E;
		padding: 15px 13px;
		overflow-y: auto;

		.header {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			color: #4DB89E;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			text-transform: capitalize;
			margin-bottom: 10px;
		}

		.successItemsCount {
			color: #393838;
			font-size: 16px;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 10px;


			span {
				font-weight: 700;
				margin-right: 3px;

			}
		}

		.wrapper {
			margin-bottom: 15px;

			.successItem {
				display: flex;
				align-items: center;
				gap: 10px;

				border-radius: 8px;
				background: #F7F7F7;
				padding: 10px;

				.thumbnail {
					cursor: pointer;

					img {
						width: 60px;
						height: 60px;
						border-radius: 8px;
					}

					svg {
						width: 60px;
						height: 60px;
					}
				}

				.title {
					color: #393838;
					font-size: 16px;
					font-weight: 600;
					line-height: normal;
					text-transform: capitalize;
				}
			}
		}

		.btns {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 15px;
		}
	}

}
