.editHeader {
  border-bottom: 1px solid #ececec;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  width: 80vw;
  background-color: #fff;
  height: 60px;
  justify-content: space-between;
  transition: all .2s;
  z-index: 999;
  gap: 20px;
  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 8px 24px;
    height: 48px;
    gap: 16px;
  }
  &.stickyHeader{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9991;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }

  .headerLeftContainer {
    display: flex;
    gap: 10px;
    align-items: center;

    @media (min-width: 769px) and (max-width: 1440px) {
      gap: 8px;
    }
  }
  .headerMiddleContainer{
    position: relative;
  }
  .loaderForSavingProgress{
    display: flex;
    gap: 10px;
    align-items: center;
    color: #333;
    font-weight: 600;
    border-radius: 8px;
    position: absolute;
    right: -105px;
    top: 20px;
    height: 40px;
    padding: 5px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    border-left: none;
  }
  .editCloseButton {
    display: flex;
    align-items: center;
    background-color: #FF6B6B;
    gap: 10px;
    border-radius: 8px;
    height: 40px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    min-width: 110px;
    font-size: 16px;
    justify-content: center;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  .editSubmitButtons {
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 1450px) and (min-width: 769px) {
      display: none;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      bottom: 62px;
      gap: 10px;
    }
  }
  .headerTitle {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}