.quickLinksContainer .tippy-box, .firstHeaderBlock .tippy-box, .downSection .tippy-box,
.salesAccordion .tippy-box, .templatesAccordion .tippy-box {
	background-color: #fff;
	border: 1px solid red;
	color: #000;
	padding: 5px;
	border-radius: 8px;
}

.firstHeaderBlock .tippy-box {
		margin-top:11px;
}
