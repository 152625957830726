@import '../../../../assets/styles/variables.scss';
.searchResultSimpleBusiness{
    font-family: $fontFamilyOpenSans !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    gap: 15px;
    top: 224px;
    z-index: 998;
    background: white;
    height: 100%;
    padding: 10px 0;
    @media only screen and (max-width: 768px) {
      position: relative;
      top: 0px !important;
      padding: 10px 20px;
      z-index: 0 !important;
    }
    @media only screen and (min-width: 800px) and (max-width: 1330px) {
      top: 260px !important;
    }

  @media (min-width: 769px) and (max-width: 1440px) {
    gap: 12px;
    top: 179px;
    padding: 8px 0;
  }
    .searchItem {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 4px 15px;
      background: $searchItemBg;
      border-radius: $borderRadius8;
      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 12px;
        padding: 3px 12px;
        border-radius: 6px !important;

        svg {
          width: 12px;
          height: 16px;
        }
      }
      span {
        &:last-child {
          display: flex;
          cursor: pointer;
        }
      }
    }

    .clearAllButton {
      background-color: $clearButtonBg;
      border-radius: $borderRadius8;
      cursor: pointer;
      padding: 4px 15px;

      @media (min-width: 769px) and (max-width: 1440px) {
        padding: 3px 12px;
        border-radius: 6px;
      }

      button {
        background: none;
        border: none;
        color: $darkBlue;
        white-space: nowrap;
        font-size: 17px;
        cursor: pointer;

        @media (min-width: 769px) and (max-width: 1440px) {
          font-size: 14px;
        }
      }
    }
  }

.searchResult {
  font-family: $fontFamilyOpenSans !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  background: inherit;
  height: 100%;
  padding: 10px 0 0 0;

  @media (min-width: 769px) and (max-width: 1440px) {
    gap: 8px;
    padding: 8px 0 0 0;
  }
  .resultsFor{
    margin-right: 10px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-right: 8px;
    }
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0px !important;
    padding: 10px 20px;
    z-index: 0 !important;
  }

  .searchItem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px 15px;
    background: $searchItemBg;
    border-radius: $borderRadius8;

    @media (min-width: 769px) and (max-width: 1440px) {
      gap: 10px;
      padding: 3px 12px;
      border-radius: 6px;

      svg {
        width: 12px;
        height: 16px;
      }
    }
    span {
      &:last-child {
        display: flex;
        cursor: pointer;
      }
    }
  }

  .clearAllButton {
    background-color: $clearButtonBg;
    border-radius: $borderRadius8;
    cursor: pointer;
    padding: 4px 15px;

    @media (min-width: 769px) and (max-width: 1440px) {
      padding: 3px 12px;
      border-radius: 6px ;
    }

    button {
      background: none;
      border: none;
      color: $darkBlue;
      white-space: nowrap;
      font-size: 17px;
      cursor: pointer;

      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }
}
.issueResultSimpleBusiness{

    font-family: $fontFamilyOpenSans !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    gap: 15px;
    top: 218px;
    z-index: 998;
    background: white;
    height: 100%;
    padding: 10px 0;

  @media (min-width: 769px) and (max-width: 1440px) {
    gap: 12px;
    top: 174px;
  }

    @media only screen and (max-width: 768px) {
      position: relative;
      top: 0px !important;
      padding: 10px 20px;
      z-index: 1 !important;
    }
    @media only screen and (min-width: 800px) and (max-width: 1330px) {
      top: 270px !important;
    }
    .searchItem {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 4px 15px;
      background: $searchItemBg;
      border-radius: $borderRadius8;
      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 12px;
        padding: 3px 12px;
        border-radius: 6px;

        svg {
          width: 12px;
          height: 16px;
        }
      }
      span {
        &:last-child {
          display: flex;
          cursor: pointer;
        }
      }
    }

    .clearAllButton {
      background-color: $clearButtonBg;
      border-radius: $borderRadius8;
      cursor: pointer;
      padding: 4px 15px;

      @media (min-width: 769px) and (max-width: 1440px) {
        padding: 3px 12px;
      }

      button {
        background: none;
        border: none;
        color: $darkBlue;
        white-space: nowrap;
        font-size: 17px;
        cursor: pointer;

        @media (min-width: 769px) and (max-width: 1440px) {
          font-size: 14px;
        }
      }
    }
  }
.issueResultTabResult {
  font-family: $fontFamilyOpenSans !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  gap: 15px;
  top: 268px;
  z-index: 998;
  background: white;
  height: 100%;
  padding: 10px 0;

  @media (min-width: 769px) and (max-width: 1440px) {
    gap: 12px;
    top: 214px;
    padding: 8px 0;
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    top: 0px !important;
    padding: 10px 20px;
    z-index: 1 !important;
  }
  @media only screen and (min-width: 800px) and (max-width: 1330px) {
    top: 320px !important;
  }
  .searchItem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px 15px;
    background: $searchItemBg;
    border-radius: $borderRadius8;

    @media (min-width: 769px) and (max-width: 1440px) {
      gap: 12px;
      padding: 3px 12px;
      border-radius: 6px;

      svg {
        width: 12px;
        height: 16px;
      }
    }
    span {
      &:last-child {
        display: flex;
        cursor: pointer;
      }
    }
  }

  .clearAllButton {
    background-color: $clearButtonBg;
    border-radius: $borderRadius8;
    cursor: pointer;
    padding: 4px 15px;

    @media (min-width: 769px) and (max-width: 1440px) {
      padding: 3px 12px;
    }

    button {
      background: none;
      border: none;
      color: $darkBlue;
      white-space: nowrap;
      font-size: 17px;
      cursor: pointer;

      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }
}
