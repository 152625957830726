@import '../../../../assets/styles/variables.scss';

//../../assets/styles/variables.scss
.MobileSliderContainer {
  margin-bottom: 10px;
  .activeTab {
    background-color: #1e3166;
    border-radius: 4px;
    padding: 5px 15px;
    display: flex !important;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    .sliderItemTitle {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      white-space: nowrap;
    }
    .sliderItemQuantity {
      color: rgba(255, 255, 255, 0.7);
      span {
        display: flex !important;
      }
    }
  }
  .Issues {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .sliderItem {
    border-radius: 4px;
    background: #ededed;
    padding: 5px 15px;
    display: flex !important;
    height: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;

    .sliderItemTitle {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      white-space: nowrap;
      color: #393838;
    }
    .sliderItemQuantity {
      color: #393838;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      span {
        display: flex !important;
      }
    }
  }
}
.SliderContainer {
  margin-left: 10px;
  margin-bottom: 30px;
  max-width: calc(100% - 20px);
}
.customArrows {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  z-index: 999;
  .leftArrow {
    background-color: #1e3166;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }
  .rightArrow {
    background-color: #1e3166;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    svg {
      cursor: pointer;
    }
  }
}

.tabs {
  align-items: center;
  display: flex;
  font-family: Open Sans, sans-serif !important;
  gap: 5px;

  li {
    display: flex;
    gap: 5px;
    // padding: 5px;
    cursor: pointer;
    flex-direction: column;

    background-color: $tabBgColor;
    padding: 5px 15px;
    border-radius: $borderRadius8;

    span {
      font-family: $fontFamilyOpenSans;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      text-wrap: nowrap;

      color: $dark;

      display: flex;
      align-items: center;
    }

    .activeTabSpan {
      color: #fff !important;

      svg {
        color: inherit;
      }
    }
  }

  .activeTab {
    background-color: $darkBlue;
  }

  .removeCapitalize {
    text-transform: initial !important;
  }

  .activeLi {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: $darkBlue;
  }
}
