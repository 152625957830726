.react-datepicker-wrapper {
    background-color: green !important;
    display: none !important;
}

.blockWrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (min-width: 769px) and (max-width: 1440px) {
        gap: 10px;
    }
}