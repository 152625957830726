.expressListerCustomizeContainer{
	position: fixed;
	background-color: #fff;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	display: grid;
	grid-template-columns: 350px 3fr;
	@media only screen and (max-width: 940px) {
		overflow-y: auto;
	}
	@media only screen and (max-width: 768px) {
		bottom: 63px;
		overflow-y: auto;
	}
	.imageUploaderTemplate{
		overflow: hidden;
		padding: 15px 10px 30px 20px;
		display: flex;
		flex-direction: column;
		gap: 5px;
		position: relative;
		border-radius: 8px;
		margin-bottom: 15px;
		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 12px 8px 24px 16px;
			gap: 4px;
			border-radius:6px;
			margin-bottom: 12px;
			svg {
				width: 80px;
				height: 80px;
			}
		}
		&::before{
			content: "";
			position: absolute;
			border: 9px dashed #1E3166;
			top: -7px;
			bottom: -7px;
			left: -7px;
			right: -7px;

			@media (min-width: 769px) and (max-width: 1440px) {
				border: 7px dashed #1e3166;
				top: -6px;
				bottom: -6px;
				left: -6px;
				right: -6px;
			}

		}
		p{
			color: #2B2B2B;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 16px;
			}
			span{
				color: #2185D0;
			}
		}

	}
	.leftContainer{
		padding: 15px 0 15px 15px;
		max-height: 100vh;
		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 12px 0 12px 12px;
		}
		@media only screen and (max-width: 940px) {
			max-height: initial;
		}
		.scrollContainerMain{
			padding-right: 15px;
			overflow-y: auto;
			height: calc(100% - 100px);

			@media (min-width: 769px) and (max-width: 1440px) {
				padding-right: 12px;
			}
			&::-webkit-scrollbar {
				width: 3px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: #8d8d8d;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background-color: #f5f5f5;
			}
			@media only screen and (max-width: 940px) {
				padding-bottom: 15px;
				overflow-y: initial;
			}
		}
		.logoContainer{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 22px;
			padding-right: 15px;
		}
		.basicInfoContainer{

		}
		.basicInfoList{

			.basicField{
				border-radius: 8px;
				background: #F5F5F5;
				height: 40px;
				padding: 0 10px;
				display: flex;
				align-items: center;
				gap: 10px;
				cursor: pointer;
				margin-bottom: 5px;
				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius: 6px;
					height: 32px;
					padding: 0 8px;
					gap: 8px;
					margin-bottom: 4px;

					svg {
						width: 16px;
						height: 13px;
					}
				}
				&.disabledFieldCheck{
					background-color: #E0E0E0;
				}
				p{
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					text-transform: capitalize;
					color: #767676;
					@media (min-width: 769px) and (max-width: 1440px) {
						font-size: 13px;
					}
					&.basicFieldTitleActive{
						color: #1E3166;
					}

				}
				@media only screen and (max-width: 940px) {
					&.disabledField{
						background: #E0E0E0;
						p{
							color: #A0A0A0;
						}
						svg{
							path{
								fill:#A0A0A0;
							}
						}
					}
				}


			}
		}
		.selectedFieldsContainer{
			border-bottom: 1px solid #ECECEC;
			padding-bottom: 10px;
			.selectedFieldsList{
				display: flex;
				flex-direction: column;
				gap: 5px;
				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 4px;
				}

			}
			.skuGeneratorFieldSKU{
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
			.selectedField{
				border-radius: 8px;
				background:  #D9E2FC;
				padding: 10px;
				display: flex;
				align-items: center;
				height: 40px;
				justify-content: space-between;
				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius: 6px;
					padding: 12px;
					height: 32px;
				}
				.dragButton {
					touch-action:none;
				}
				svg{
					cursor: grab;
					outline: none;

					@media (min-width: 769px) and (max-width: 1440px) {
						width: 14px;
						height: 16px;
					}
				}
				.leftSide{
					display: flex;
					align-items: center;
					gap: 10px;
					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 8px;
					}
					@media only screen and (max-width: 940px) {
						gap: 0;
						svg{
							min-width: 30px;
						}
					}
				}
				p{
					color: #1E3166;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					text-transform: capitalize;
					-webkit-user-select: none; /* Safari */
					-moz-user-select: none; /* Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
					user-select: none;

					@media (min-width: 769px) and (max-width: 1440px) {
						font-size: 13px;
					}
				}
			}
		}
		.fieldsTitle{
			color:  #2B2B2B;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
			margin-bottom: 5px;
			margin-top: 10px;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
				margin-bottom: 4px;
				margin-top: 8px;
			}
		}
		.searchContainerMain{
			background: #fff;
			padding: 0 15px 15px 15px;
			@media (min-width: 769px) and (max-width: 1440px) {
				padding: 0 12px 12px 12px;

				svg {
					width: 16px;
					height: 16px;
					left: 12px !important;
					top: 8px !important;
				}
			}
			@media only screen and (max-width: 940px) {
               padding: 0;
			}
		}
		.searchContainer{
			position: relative;
			padding-right: 15px;
			@media (min-width: 769px) and (max-width: 1440px) {
				padding-right: 12px;
			}
			@media only screen and (max-width: 940px) {
				padding-bottom: 5px;
			}
			.searchIcon{
				position: absolute;
				left: 15px;
				top: 10px;
			}
			input{
				border-radius: 8px;
				border: 1px solid #D9D9D9;
				background-color:  #FFF;
				height: 40px;
				width: 100%;
				padding-left: 45px;
				color: #767676;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;

				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius:6px;
					height: 32px;
					padding-left: 36px;
					font-size: 13px;
				}

			}

		}

	}
	.titles{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		position: fixed;
		background-color: #fff;
		top: 0;
		right: 0;
		z-index: 9992;
		gap: 5px;
		padding: 15px 30px 15px 24px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-bottom: 8px;
			gap: 4px;
			padding: 12px 24px 12px 19px;
		}
		p{
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 16px;
			}
			@media only screen and (max-width: 1089px) {
				font-size: 16px;
			}
		}

		.customizeHeadingButtons{
			display: flex;
			align-items: center;
			gap: 15px;
			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 12px;
			}
			.saveAndClose{
				white-space: nowrap;
				border-radius: 8px;
				border: 1px solid  #1E3166;
				background:#1E3166;
				padding: 0 15px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				cursor: pointer;
				@media only screen and (max-width: 1089px) {
					font-size: 14px;
				}
			}
			.cancelCustomize{
				border-radius: 8px;
				border: 1px solid #1E3166;
				color:#1E3166;
				display: flex;
				align-items: center;
				height: 40px;
				padding: 0 15px;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				cursor: pointer;
				@media only screen and (max-width: 1089px) {
					font-size: 14px;
				}
			}
		}

		&.mobileHeaderContainer{
			display: none;
			@media only screen and (max-width: 940px) {
				display: flex;
				position: static;
				padding: 0 15px 5px 0;
				gap: 5px;
				margin-bottom: 5px;
				p{
					font-size: 16px;
				}
				.saveAndClose{
					font-size: 14px;
				}
				.cancelCustomize{
					font-size: 14px;
				}
			}
		}


	}
	.rightContainer{
		background-color: #F8F8F8;
		padding: 75px 24px 24px 24px;
		overflow-y: auto;

		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 60px 19px 19px 19px;
		}

		&::-webkit-scrollbar {
			width: 7px;

			@media (min-width: 769px) and (max-width: 1440px) {
				width: 6px;
			}
		}

		&::-webkit-scrollbar-thumb {
			background-color: #8d8d8d;
			border-radius: 10px;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 8px;
			}
		}

		&::-webkit-scrollbar-track {
			background-color: #f5f5f5;
		}
		.skuGeneratorFieldSKURight{
			.selectedFieldRightSide{
				display: flex;
				flex-direction: column;
				gap: 15px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 12px;
				}
			}
		}
		.customizeHeading{
			margin-bottom: 15px;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-bottom: 12px;
			}
			.customizeDescription{
				color: #2B2B2B;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}

		}
		.listOfFieldsRight{
			display: flex;
			flex-direction: column;
			gap: 15px;
			border-radius: 8px;
			padding: 15px;
			position: relative;
			border: 1px solid #4DB89E;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 12px;
				border-radius: 6px;
				padding: 12px;
			}

			.selectedFieldRightSide{
				background-color: #F8F8F8;
				.fieldItemContainer{
					border-radius: 8px;
					border: 1px solid #4DB89E;
					padding: 15px 10px;
					@media (min-width: 769px) and (max-width: 1440px) {
						border-radius: 6px;
						padding: 12px 8px;
					}
					.fakeInputCheckbox{
						position: relative;
						input{
							display: none;
						}
						span{
							position: absolute;
							top: 0;
							left: 0;
							height: 30px;
							width: 30px;
							background-color: #ECECEC;
							border: 1px solid #ECECEC;
							border-radius: 8px;
							&::before{
								content: "";
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%) rotate(45deg);
								height: 12px;
								width: 5px;
								border: solid white;
								border-width: 0 2px 2px 0;
							}
						}
					}
					.fakeSelect{
						border-radius: 8px;
						background: #ECECEC;
						width: 100%;
						max-width: 250px;
						margin: 8px 0;
						position: relative;
						&::before {
							content: '';
							position: absolute;
							right: 10px; /* Adjust as needed */
							top: 55%; /* Center vertically */
							transform: translateY(-50%);
							border-width: 6px; /* Adjust size as needed */
							border-style: solid;
							border-color: #333 transparent transparent transparent; /* Arrow color and transparent sides */
						}
					}
					.fakeInput{
						border-radius: 8px;
						background: #ECECEC;
						width: 100%;
						max-width: 550px;
						margin: 8px 0;

						@media (min-width: 769px) and (max-width: 1440px) {
							border-radius: 6px;
							max-width: 440px;
							margin: 6px 0;
						}
					}
					span{
						color: #696969;
						font-size: 14px;
						font-style: italic;
						font-weight: 400;
						line-height: normal;
					}
					.header{
						display: flex;
						align-items: center;
						justify-content: space-between;
						p{
							color: #2B2B2B;
							font-size: 20px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
							display: flex;
							align-items: center;
							gap: 5px;
							@media (min-width: 769px) and (max-width: 1440px) {
								font-size: 16px;
								gap: 4px;
							}
							.popoverIcon{
								cursor: pointer;

								@media (min-width: 769px) and (max-width: 1440px) {
									width: 16px;
									height: 16px;
								}
							}
						}
						.dragAndRemove{
							display: flex;
							gap: 10px;
							align-items: center;
							position: relative;
							z-index: 9991;
							@media (min-width: 769px) and (max-width: 1440px) {
								gap: 4px;
							}
							.dragBtnRight{
								border-radius: 8px;
								border: 1px solid #4DB89E;
								background: #FFF;
								display: flex;
								align-items: center;
								justify-content: center;
								height: 40px;
								width: 40px;
								cursor: pointer;
								position: relative;
								z-index: 9991;
								@media (min-width: 769px) and (max-width: 1440px) {
									border-radius: 6px;
									height: 32px;
									width: 32px;
								}
								svg{
									@media (min-width: 769px) and (max-width: 1440px) {
										width: 8px;
										height: 16px;
									}
									path{
										fill: #4DB89E;
									}
								}
							}
							.removeBtnRight{
								border-radius: 8px;
								border: 1px solid #FF4D4D;
								background: #FFF;
								display: flex;
								align-items: center;
								justify-content: center;
								height: 40px;
								width: 40px;
								cursor: pointer;
								position: relative;
								z-index: 9991;
								@media (min-width: 769px) and (max-width: 1440px) {
									border-radius: 6px;
									height: 32px;
									width: 32px;
								}

								svg{
									@media (min-width: 769px) and (max-width: 1440px) {
										width: 14px;
										height: 16px;
									}
									path{
										fill: #FF4D4D;
									}
								}
							}
						}
					}
				}

			}
		}
		@media only screen and (max-width: 940px) {
		  display: none;
		}
	}
	@media only screen and (max-width: 940px) {
		grid-template-columns:100%;
	}
}
