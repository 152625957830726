.SliderContainer {
  display: flex;
  column-gap: 15px;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    column-gap: 0;
    margin-top: 20px;
  }
}
.slider {
  display: flex;
  align-items: center;
}
.imagesGallery{
  display: grid;
  grid-template-columns: repeat(auto-fill, 110px);
  grid-template-rows: repeat(auto-fill, 110px);
  gap: 20px;
  .imagesGalleryContainer:not(:first-child){
    max-height: 110px;
  }
  .imagesGalleryContainer:first-child{
    max-height: 240px;
    max-width: 240px;
  }
  .imagesGalleryContainer{
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DDDDDD;
    background-color: #EFEFEF;
    cursor: pointer;
    min-height: 110px;
  }
  :nth-child(1){
    grid-column: 1/3;
    grid-row: 1/3;
    .imageOrder {
      bottom: -7px;
      right: -12px;
      z-index: 9;
    }
  }
  img{
    height: auto;
    width:100%;
    border-radius: 8px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
.slide, .imagesGalleryContainer {
  position: relative;
  .imageOrder {
    position: absolute;
    height: 20px;
    width: 24px;
    background-color: #32C09A;
    color: #fff;
    display: flex;
    bottom: -7px;
    right: -12px;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
  }
}
.thumbnailDiv {
  margin: 0;
  width: 300px;
  height: 300px;
}
.thumbnailImg {
  object-fit: contain;
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border: #4db89e solid 4px;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .images {
    width: 80px;
    height: 80px;
  }
}

.arrowContainer {
  display: flex !important;
  @media screen and (max-width: 768px) {
   min-width: 44px;
    margin-top: 10px;
  }
}
.arrowSvg {
  width: 21px;
  height: 24px;
}
.leftArrow {
  background-color: #1e3166;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  svg {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    svg{
      width: 13px;
    }
  }
}

.inactiveLeftArrow {
  background-color: #98a3c1;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  svg {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-right: 10px;
    height: 30px;
    width: 30px;
    svg{
      width: 13px;
    }
  }
}

.rightArrow {
  background-color: #1e3166;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
  svg {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-left: 4px;
    height: 30px;
    width: 30px;
    svg{
      width: 13px;
    }
  }
}

.inactiveRightArrow {
  background-color: #98a3c1;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  cursor: pointer;
  svg {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    margin-left: 4px;
    height: 30px;
    width: 30px;
    svg{
      width: 13px;
    }
  }
}
