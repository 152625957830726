@import '../../../../../../assets/styles/variables.scss';

.pagination {
  font-family: $fontFamilyOpenSans !important;

  max-width: 140px !important;
  display: flex !important;
  align-items: center !important;

  .page__item {
    padding: 2px !important;
    cursor: pointer !important;
    font-weight: 600;
    font-size: 16px;
  }

  .page__link {
    color: #000;
    font-weight: 500 !important;
  }

  .page__previous__next {
    padding: 2px !important;
    cursor: pointer !important;
  }

  .pageLink__previous__next {
    color: #000;
    font-weight: 700 !important;
  }

  .page__break {
    cursor: pointer !important;
    padding: 2px !important;
  }

  .pageLink__break {
    color: #000;
    font-weight: bold !important;
  }

  .activePageLink {
    background-color: rgba(144, 136, 136, 0.6);
    border-radius: $borderRadius8 !important;
  }
}

.reactPaginate {
  display: flex !important;
  gap: 5px !important;
  align-items: center;

  .paginateInput {
    display: flex !important;
    gap: 10px !important;
    align-items: center !important;
    margin-left: 20px;
    margin-right: 20px;

    label {
      margin: 0 !important;
      font-family: $fontFamilyOpenSans;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: $dark;
    }

    input {
      height: 39px !important;
      max-width: 40px !important;
      text-align: center !important;
      padding: 0 2px !important;
      -moz-appearance: textfield !important;
      background-color: transparent !important;
      border: 1px solid #000 !important;
      margin: 0 !important;
      border-radius: 4px;
      outline: none;
      font-size: 15px;

      &:focus {
        border: 1px solid $mint !important;
      }

      &::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
      }

      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
      }
    }
  }

  ul {
    margin: 0 !important;
  }

  .addPageVector {
    cursor: pointer;
    padding: 10px 5px;
    border-radius: 8px;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: #d7e2ff;
    }
  }

  .rototateRemove {
    transform: rotate(180deg);
  }
}

.paginatePageCount {
  font-family: $fontFamilyOpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: $dark;
  text-wrap: nowrap;
}

.paginateGo {
  font-family: $fontFamilyOpenSans;
  border: 1px solid #1e3166;
  border-radius: 8px;
  padding: 10px 15px;
  max-height: 39px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: $darkBlue;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 769px) and (max-width: 1440px) {
  .reactPaginate {
    gap:4px !important;

    .paginateInput {
      gap:8px !important;
      margin-left: 16px !important;
      margin-right: 16px !important;
    }

    .addPageVector {
      padding: 8px 4px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    input[type="text"] {
      height: 32px !important;
      max-width: 32px !important;
      padding: 0 1px !important;
      border-radius: 3px  !important;
    }
  }
  .paginatePageCount {
    font-size: 13px;
  }
}
