#keywords_tagify .tagify__tag  {
    background-color: rgb(230, 230, 230);
}
#keywords_tagify .tagify__tag:hover {
    background-color: rgb(215, 226, 255);
}
#keywords_tagify::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}
#keywords_tagify::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
 }
#keywords_tagify::-webkit-scrollbar-track {
    background-color: #fff;
}
#keywords_tagify::-webkit-scrollbar-button {
     background-color: #fff;
 }


#tableRowId .lp_custom_crosspost_icon_disabled {
    border-radius: 8px;
    border: 1px solid  #B6B6B6;
    rect {
        fill: white;
    }

    path {
        fill: #B6B6B6;
    }
}

#tableRowId .lp_custom_crosspost_icon_active {
    border-radius: 8px;
    rect {
        fill: rgb(77, 184, 158);
    }

    path {
        fill: white;
    }
}
#tableRowId .deleteIconDisabled{
    cursor: not-allowed;
}

#tableRowId .deleteIconDisabled path{
    fill: #B6B6B6;
}
