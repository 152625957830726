@import '../../../../assets/styles/variables.scss';

.catalogInModalOverlay {
	font-family: $fontFamilyOpenSans !important;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	z-index: 999999;
	background: rgba(51, 51, 51, 0.4);
	overflow: hidden;

	.modal {
		position: relative;
		font-family: 'Open Sans', sans-serif;
		background-color: #fff;
		width: 81%;
		height: 100%;
		overflow-y:auto;
		scrollbar-width: none;


		.soldIcon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
		}

		.modalInner {
			padding: 20px;
			height: 86%;
			overflow-y: auto;

			.loader {
				height: 50vh;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.line {
			background: #B6B6B6;
			width: 100%;
			height: 1px;
			position: fixed;
		}

		.linkedBlock {
				margin:0 0 20px 0;
			.linkedHeader {
				display: flex;
				align-items: baseline;
				gap: 10px;
				margin-bottom: 7px;

				h2 {
					color: #000;
					font-family: "Open Sans", 'sans-serif';
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}

			.linkedListing {
				display: flex;
				align-items: center;
				gap: 10px;
				border-radius: 8px;
				background: #F7F7F7;
				padding: 10px 20px 10px 15px;
				justify-content: space-between;
				width: 100%;

				.left {
					display: flex;
					align-items: center;
					gap:15px;

					.thumbnail {
						height: 100%;

						img {
							border-radius: 8px;
							width: 70px;
							height: auto;
						}

						.noThumbnail {
							width: 70px;
							height: 70px;
							border-radius: 8px;
							background: #DBDBDB;
						}
					}

					h3 {

						color: var(--Dark, #393838);
						font-family: "Open Sans", 'sans-serif';
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						text-transform: capitalize;
						a {
							color: #000;
						}
					}
				}

				.right {
					display: flex;
					align-items: center;
					gap:15px;

					.linkBtn {
						cursor: pointer;
						border-radius: 8px;
						border: 1px solid #4DB89E;
						background: #F7F7F7;
						padding: 10px;
						color: #4DB89E;
						font-size: 16px;
					}

					.btnsWrapper {
						display: flex;
						gap:15px;
						align-items: center;
					}
				}
			}
		}

		.linkText {
			display: flex;
			flex-direction: column;
			gap:10px;
			margin-bottom: 20px;

			.heading {
				display: flex;
				align-items: baseline;
				gap:10px;

				h2 {
					color: #000;
					font-family: "Open Sans", 'sans-serif';
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
				}
			}

			p {
				color: var(--Dark, #393838);
				font-family: "Open Sans", 'sans-serif';
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-top: -5px;
			}
		}

		.modalHeader {
			display: flex;
			justify-content: space-between;
			padding: 20px;
			position: sticky;
			top: 0;
			z-index: 9999;
			background: #fff;

			.closeBtn {
				cursor: pointer;
				transition: $transition;

				&:hover {
					opacity: $globalOpacity;
				}
			}
		}


		.modalWrapper {
			display: flex;
			gap: 20px;
			align-items: center;

			.thumbnail {
				height: 100%;

				img {
					border-radius: 8px;
					width: 90px;
					height: auto;
				}
			}

			h3 {
				font-size: 18px;
				font-weight: 500;
			}
		}

		.inputBlock {
			text-align: center;
			margin-bottom: 20px;
			display: flex;

			input {
				width: 100%;
				color: #767676;
				font-family: "Open Sans", 'sans-serif';
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				padding-left: 15px !important;
			}
		}

		.modalListingWrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 15px;

			.listingInModalItem {
				display: flex;
				align-items: center;
				gap: 10px;
				border-radius: 8px;
				background: #F7F7F7;
				padding: 10px 20px 10px 15px;
				justify-content: space-between;
				width: 100%;

				.left {
					display: flex;
					gap:15px;
					align-items: center;

					.thumbnail {
						height: 100%;

						img {
							border-radius: 8px;
							width: 70px;
							height: auto;

						}

						.noThumbnail {
							width: 70px;
							height: 70px;
							border-radius: 8px;
							background: #DBDBDB;
						}
					}

					h3 {
						color: var(--Dark, #393838);
						font-family: "Open Sans", 'sans-serif';
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						text-transform: capitalize;

						@media (min-width: 769px) and (max-width: 1440px) {
							font-size: 13px;
						}

						a {
							color: #000;
						}
					}
				}

			.right {
				display: flex;
				align-items: center;
				gap:10px;

				.linkBtn {
					cursor: pointer;
					border-radius: 8px;
					border: 1px solid #4DB89E;
					background: #F7F7F7;
					padding: 10px;
					color: #4DB89E;
					font-size: 16px;

				}
			}
			}
		}
		.loader {
			margin: 20px 0;

			div {
				width: auto !important;
				height: auto !important;
			}
		}
	}
}

@media (min-width: 769px) and (max-width: 1440px) {
	.catalogInModalOverlay {
		.modal {
			.modalInner {
				padding: 16px;
			}
			.linkedBlock {
				.linkedHeader {
					gap: 8px;
					h2 {
						font-size: 16px;
					}
				}
				.linkedListing {
					gap: 8px;
					padding: 8px 16px 8px 12px;

					.left {
						gap: 12px;

						.thumbnail {
							img {
								width: 56px;
							}

							.noThumbnail {
								width: 56px;
								height: 56px;
							}
						}
					}
					.right {
						gap: 12px;
						.linkBtn {
							padding: 8px;
							font-size: 13px;
						}
						.btnsWrapper {
							gap: 12px;
						}
					}
				}
			}
			.linkText {
				gap: 8px;

				.heading {
					gap: 8px;

					h2 {
						font-size: 16px;
					}
				}
				p {
					font-size: 13px;
				}
			}
			.modalHeader {
				padding: 16px;
			}
			.modalWrapper {
				gap: 16px;

				.thumbnail {
					img {
						width: 72px;
					}
				}
				h3 {
					font-size: 14px;
				}
			}
			.inputBlock {
				input {
					font-size: 13px;
					padding-left: 12px !important;
				}
			}
			.modalListingWrapper {
				gap: 12px;

				.listingInModalItem {
					gap: 12px;
					padding: 8px 16px 8px 12px;

					.left {
						gap: 12px;

						.thumbnail {
							img {
								width: 56px;
							}

							.noThumbnail {
								width: 56px;
								height: 56px;
							}
						}
					}
					.right {
						gap: 8px;

						.linkBtn {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
}