
.corporateTableUpBlock {
	display: flex;
	align-items: center;
	gap: 15px;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 15px;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
	}

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 12px;
		font-size: 13px;
		margin-bottom: 12px;
	}
}

.corporateTableUpBlock__title--green{
	color: var(--Mint, #4DB89E);
	font-weight: 700;
}

.corporateTableUpBlock__title--gray {
	color:  #767676;
	font-size: 16px;
	font-weight: 600;

	@media (max-width: 768px) {
		font-size: 14px;
	}

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 13px;
	}
}

.subAccountTable {
	border: 1px solid var(--Border-Grey, #DDD);
	border-radius: 8px;
	margin-bottom: 10px;

	@media (min-width: 769px) and (max-width: 1440px) {
		border-radius: 6px;
		margin-bottom: 8px;
	}

	&__no_sub_account {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;

		a{
			margin: 0 5px;
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 8px;
		}
	}
}

.subAccountTable__table {
	display: grid;
	overflow: auto;
	max-height: calc(100vh - 70px);
}

.subAccountTable__thead {
	display: contents;
}

.subAccountTable__row {
	text-align: left !important;
	padding: 9px 0 9px 20px;
	display: contents;

	th {
		position: sticky;
		top: 0;
		padding: 10px 0 10px 10px;
	}
}

.table__th-heading {
	color: #1E3166;
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 13px;
	}
}

.subAccountTable__tbody {
	display: contents;
}

.subAccountTable__row {
	display: contents;

	&:nth-child(odd) td {
		background: #fff;
	}
}

.subAccountTable__tbody-td {
	padding: 20px 0 20px 10px !important;

	&--actions {
		padding: 10px 0 10px 10px !important;
	}
}

.table__td-text {
	color: var(--Dark, #2B2B2B);
	font-size: 14px;
	font-weight: 400;

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 11px;
	}

	&--bold {
		font-weight: 600;
	}
}


.addSubAccountBtn {
	background: var(--Mint, #4DB89E);
	gap: 10px;
	height: 32px;
	min-height: 32px !important;
	padding: 10px;

	@media (max-width: 768px) {
		height: 44px;
		min-height: 44px !important;

		svg {
			width: 14px;
			height: 14px;
		}

	}

	span {
		color: var(--White, #FFF);
		font-size: 14px;
		font-weight: 600;

		@media (max-width: 768px) {
			font-size: 16px;
		}
	}
}

.table__actions {
	display: flex;
	align-items: center;
	gap: 10px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 8px;
	}
}


.subAccountTable__paraqraph {
	color: #767676;
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 11px;
	}
}

