@import "@assets/styles/variables.scss" ;

.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background: rgba(43, 43, 43, 0.5);
}

.modal {
	padding: 20px;
	border-radius: 8px;
	border: 1px solid var(--Red, #FF4D4D);
	background: var(--Light-Grey, #F8F8F8);
	width: 420px;

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 16px;
		border-radius: 6px;
		width: 336px;
	}
}

.modal__header {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 8px;
		margin-bottom: 12px;
	}
}

.modal__content {
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	gap:15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 12px;
	}

	&-option {
		display: flex;
		align-items: center;
		gap:10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}

		label {
			color: #000;
			font-size: 14px;
			font-weight: 400;
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 11px;
			}
		}
	}
}

.modal__content-radio {
	position: absolute;
	opacity: 0;
	width: 0;
	height: 0;
}

.modal__content-option {
	position: relative;
	display: flex;
	align-items: center;

	label {
		cursor: pointer;
		display: flex;
		align-items: center;

	}

	.modal__custom-radio {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 1px solid #1E3166;
		position: relative;
		margin-right: 7px;

		@media (min-width: 769px) and (max-width: 1440px) {
			width: 16px;
			height: 16px;
			margin-right: 6px;
		}

		&:after {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 50%;
			background-color: transparent;
			transition: background-color 0.2s;

			@media (min-width: 769px) and (max-width: 1440px) {
				width: 10px;
				height: 10px;
			}
		}
	}

	.modal__content-radio:checked + label .modal__custom-radio:after {
		background-color: #1E3166;
	}
}


.modal__footer {
	display: flex;
	align-items: center;
	gap: 15px;
	justify-content: flex-end;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 12px;
	}
}

.modal__content-radio--active {
	font-weight: 600 !important;
}