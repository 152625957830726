.accordionContent {
  padding: 40px 20px 20px 20px;
  border: 1px solid #4db89e;
  border-radius: 8px;
  margin-top: -30px;
  margin-bottom: 20px;
  background: #f8f8f8;

  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 32px 16px 16px 16px;
    border-radius: 6px;
    margin-top: -24px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 15px 15px 15px;
    border-radius: 0 0 8px 8px;
    margin-top: -50px;
  }
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: 16px;
      min-width: 16px;
    }
  }
  .cogsAndProfit {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .accordionContent3 {
    .title {
      margin-bottom: 9px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #393838;
      display: flex;
      align-items: center;
      @media (min-width: 769px) and (max-width: 1440px) {
        margin-bottom:7px;
        font-size: 16px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    .line {
      height: 1px;
      background-color: #d9d9d9;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 18px;

      @media (min-width: 769px) and (max-width: 1440px) {
        margin-top: 8px;
        margin-bottom: 14px;
      }
    }
    .inputsContainerDown {
      input{
        margin-bottom: 10px;
      }
      .inputsItem {
        display: flex;
        gap: 30px;

        @media (min-width: 769px) and (max-width: 1440px) {
          gap: 24px;
        }

        @media only screen and (max-width: 768px) {
          flex-direction: column;
          gap: 10px;

        }
        .heading {
          margin-bottom: 4px;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #393838;

          @media (min-width: 769px) and (max-width: 1440px) {
            margin-bottom: 3px;
            font-size: 13px;
          }
        }
      }
    }

    .inputsContainer {
      input{
        margin-bottom: 10px;
        @media only screen and (max-width: 768px) {
          margin-bottom: 5px;
        }
      }
      .inputsItem {
        display: flex;
        align-items: center;
        gap: 30px;
        @media (min-width: 769px) and (max-width: 1440px) {
          gap: 24px;
        }
        svg{
          cursor: pointer;
          min-width: 20px;
          min-height: 20px;

          @media (min-width: 769px) and (max-width: 1440px) {
            min-width: 16px;
            min-height: 16px;
          }

        }
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
          div{
            width: 100%;
          }
        }
        .heading {
          margin-bottom: 4px;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #393838;

          @media (min-width: 769px) and (max-width: 1440px) {
            margin-bottom: 3px;
            font-size: 13px;
          }
        }
      }
    }
  }
}