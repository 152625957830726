.mobileActions .tippy-arrow {
  display: none;
}
.mobileActions .tippy-content {
  padding: 0;
}

#mobileCard .tippy-box {
  z-index: 10 !important;
  background-color: #fff;
  border: 1px solid #1e3166;
  border-radius: 8px;
  overflow: hidden;
  max-width: 200px;
  color: #000;
}

#mobileCard .mobileActions .tippy-box {
  background-color: #fff;
  border: 1px solid #1e3166;
  border-radius: 8px;
  overflow: hidden;
  max-width: 200px;
}
#mobileCard .mobileActions .tippy-arrow {
  display: none;
}
#mobileCard .mobileActions .tippy-content {
  padding: 0;
}


#mobileCard .lp_custom_crosspost_icon_disabled {
  rect {
    fill: white;
  }

  path {
    fill: #B6B6B6;
  }
}


#mobileCard {
  .socialIcon {
    height: 40px !important;
    width: 40px !important;

    @media (max-width: 768px) {
      height: 32px !important;
      width: 32px !important;
    }
  }
}