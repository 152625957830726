@import "@assets/styles/variables.scss" ;

.skuGenerator {
	font-family: "Open Sans", 'sans-serif';

	.titleBlock {
		display: flex;
		gap:10px;
		margin-bottom: 15px;

		.questionIcon {
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
			margin-bottom: 12px;

			svg {
				width: 16px;
				height: 16px;
			}
		}
		.title {
			color: #000;
			font-size: 16px;
			font-weight: 700;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
			}
		}
	}

	.skuLocationsWrapper {
		display: flex;
		align-items: center;
		gap:20px;
		flex-wrap: wrap;
		margin-bottom: 15px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 16px;
			margin-bottom: 12px;
		}

		.skuLocationItem {
			display: flex;
			flex-direction: column;
			gap:5px;
			position: relative;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 4px;
			}

			.locationTitle {
				color: #000;
				font-size: 16px;
				font-weight: 600;

				@media (min-width: 769px) and (max-width: 1440px) {
					font-size: 13px;
				}
			}

			input {
				width: 115px;
				height: 39px;
				border: 1px solid #d9d9d9;
				border-radius: 4px;
				padding-left: 10px;

				@media (min-width: 769px) and (max-width: 1440px) {
					width: 92px;
					height: 32px;
					padding-left: 8px;
				}
			}

			p {
				font-size: 13px;
				position: absolute;
				bottom: -20px;
				left: 0;

				@media (min-width: 769px) and (max-width: 1440px) {
					font-size: 11px;
					bottom: -16px;
				}
			}

			.limitTextDanger {
				color:#ff4d4d;
				bottom: -35px;

				@media (min-width: 769px) and (max-width: 1440px) {
					bottom: -28px;
				}
			}
		}
	}
}

.toolTip {
	padding: 10px;
	border-radius: 8px;
	border: 1px solid var(--Dark-Blue, #1E3166);
	background: var(--White, #FFF);
	box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.20);

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 8px;
		border-radius: 6px;
	}
}

.questionSvg {
	cursor: pointer;
}

.isClickedQuestionClass {
	path {
		fill: #1E3166;
	}
}
.popoverIcon{
	cursor: pointer;
}
