.customMarketModalContainer{
	position: fixed;
	background-color: rgba(0,0,0,0.5);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99991;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;

	.customMarketModal{
		background-color: #fff;
		min-height: 300px;
		width: 100%;
		border-radius: 8px;
		position: relative;
		padding: 26px 20px;
		max-width: 360px;
		.buttonsContainer{
			display: flex;
			margin-top: 25px;
			gap: 15px;
			.cancelBtn2{
				border-radius: 8px;
				border: 1px solid  #B6B6B6;
				color: #B6B6B6;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: capitalize;
				background-color: #fff;
			}
			button{
				width: 100%;
				max-width: 150px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		.colorContainer{
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
		}
		.marketNameContainer{
			display: flex;
			gap: 10px;
			.platformInputWrapper{
				display: flex;
				flex-direction: column;
				width: 100%;
			}
			input{
				color:  #393838;
				font-family: "Open Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				border: 1px solid #B6B6B6 !important;
				width: 100%;
				max-width: 265px !important;
			}
		}
		p{
			color: #393838;
			font-family: "Open Sans";
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
		.uploadBtn{
			border-radius: 8px;
			border: 1px solid  #1E3166;
			background-color: #fff;
			display: flex;
			gap: 15px;
			padding: 10px 20px;

			span{
				color: #1E3166;
				font-family: "Open Sans";
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: capitalize;
			}
		}
		.imageContainer{
			display: flex;
			gap: 10px;
			align-items: flex-start;
			margin-bottom: 20px;

		}
		.editCustomMarket{
			color: #393838;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 12px;
		}
	}
	.closeIcon{
		position: absolute;
		right: 10px;
		top: 6px;
	}
}