.mobileMenuContainer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9991;

  .editLogo {
    svg {
      path {
        fill: rgb(30, 49, 102);
      }
    }
  }
  .mainFooterMenu {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
  }
  .menuOpened {
    border-radius: 8px 8px 0px 0px;
    border-top: 1px solid #b6b6b6;
    background: #fff;
    padding: 26px 12px;
    .optProPlus{
      padding: 10px 0;
      display: flex;
    }
  }
}
.NavigateTo {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #767676;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.mobileMenuMain {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d5d5d5;
  padding: 7px 0;
  align-items: flex-start;
  background-color: #fff;

  .menuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    cursor: pointer;
    &.menuItemExpress{
      .itemLogo{
        background: #F2711C;
        height: 30px;
      }
    }
    &:last-child {
      .itemLogo {
        background: none;
        max-width: fit-content;
        padding: 0;
        svg {
          width: 40px;
        }
      }
    }
    .itemTitle {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 3px;
    }
    .itemLogo {
      border-radius: 8px;
      background: #f5f5f5;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40px;
      height: 32px;
      svg {
        width: 20px;
        color:#1E3166;
      }
    }
  }
}

.acordionHeeder {
  border-radius: 8px;
  border: 1px solid #ececec;
  cursor: pointer;
}
.settingsNew{
  display: flex;
  gap: 10px;
  align-items: center;
  span{
    width: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #4DB89E;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#FFF;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    padding: 1px;
  }
}
.list {
  display: grid;
  gap: 10px;
}
.acordionHeederActive {
  border-radius: 8px;
  border: 1px solid #1e3166;
  cursor: pointer;
}
.acordionHeeder{

}
.menuBackgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the background color and opacity as needed */
  z-index: 2; /* Ensure it's above other content */
}
.acordionFlex {
  display: flex;
  align-items: center;
  color: #1e3166;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  padding: 10px;
}

.acrodionItem {
  color: #767676;
  font-family: Open Sans,'sans-serif';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #F5F5F5;
    color:#767676;
  }

  &:active {
    background: #ECECEC;
    color:#767676;

  }
}

.acrodionItemListing {
  border-radius: 8px;
  color: #767676;
  font-family: Open Sans,'sans-serif';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #F5F5F5;
    color:#767676;
  }

  &:active {
    background: #ECECEC;
    color:#767676;
  }

}

.acrodionItemListingAddTemplate {
  border-radius: 8px;
  background: #f5f5f5;
  color: #1e3166;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  cursor: pointer;
  gap: 4px;
  display: flex;
  /* justify-content: center; */
}

.acrodionItemListingBulk {
  border-radius: 8px;
  background: #f5f5f5;
  color: #fff;
  font-family: Open Sans, 'sans-serif';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: flex;
  /* justify-content: center; */

  border-radius: 8px;
  border: 1px solid var(--dark-blue, #1e3166);
  background: var(--dark-blue, #1e3166);
}

.downSection {
  display: flex;
  flex-direction: column;
}

.rototateArrow {
  transform: rotate(180deg);
}

.scrollContainer {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-left: -12px;
  margin-bottom: 20px;
}

.scrollContainer::-webkit-scrollbar {
  width: 8px;
}

.scrollContainer::-webkit-scrollbar-thumb {
  background-color: #888888;
}

.scrollContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}

.scrollContainer::-webkit-scrollbar-track {
  background-color: #dddddd;
}

.questionModal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.questionButton {
  border-radius: 8px;
  padding: 8px;
  border: 1px solid black;
}

.iframeOverflay {
  width: 100vw;
  height: 100vh;
  background: #27374d;
  position: fixed;
  z-index: 9991;
  opacity: 0.8;
}

.textAreaCounter {
  position: absolute;
  right: 5px;
  bottom: 1px;
  font-size: 13px;
  font-style: italic;
}

.notificationAbsoluteCloseButton {
  position: absolute;
  right: 13px;
  top: 10px;
  cursor: pointer;
  transform: scale(0.8);
}

.infinite-scroll-component__outerdiv {
  width: 100%;
  height: 100%;
}

.favoritesBlock {
  overflow-y: scroll;
  .titleBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .left {
      display: flex;
      align-items: center;
      gap:10px;

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color: #767676;
        text-transform: uppercase;
      }
    }

    .threeDots {
      cursor: pointer;
    }
  }

  .favoriteLinkWrapper {

    .quickLinkItem {
      display: flex;
      align-items: center;
      gap:10px;
      padding: 10px;

      div {
        color: var(--Dark-Blue, #1E3166);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

  }
}

.disabledExpressLister {
  cursor: not-allowed !important;
  opacity: .4;
}