.accordionContent {
  padding: 40px 20px 20px 20px;
  border: 1px solid #4db89e;
  border-radius: 8px;
  margin-top: -30px;
  margin-bottom: 20px;
  background: #f8f8f8;

  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 32px 16px 16px 16px;
    border-radius: 6px;
    margin-top: -24px;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 768px) {
    padding: 30px 15px 15px 15px;
    border-radius: 0 0 8px 8px;
    margin-top: -50px;
  }
  .bulletsContainer{
    display: flex;
    flex-direction: column;
    .emojiInput{
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;

      @media (min-width: 769px) and (max-width: 1440px) {
        max-width: 240px;
        margin-bottom: 16px;
      }
    }
  }
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: 8px;
      min-width: 16px;
    }
  }

  .cogsAndProfit {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .accordionContent5 {
    .measurementContainer {
      display: flex;
      gap: 60px;

      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 48px;
      }

      @media only screen and (max-width: 768px) {
        gap: 0px;
        flex-direction: column;
      }
    }

    .heading {
      margin-bottom: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #393838;

      @media (min-width: 769px) and (max-width: 1440px) {
        margin-bottom: 8px;
        font-size: 13px;
      }
    }
    .textareaContainer {
      display: flex;
      align-items: center;
      background-color: #e8e8e8;
      padding: 5px 10px 10px 10px;
      border-radius: 8px 8px 0 0;

      @media (min-width: 769px) and (max-width: 1440px) {
        padding: 4px 8px 8px 8px;
        border-radius: 6px 6px 0 0;
      }
    }
    .asigneeBtn {
      border-radius: 8px;
      border: 1px solid #1e3166;
      cursor: pointer;
      width: 100%;
      max-width: 192px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #1e3166;
      background: #fff;
      padding: 9px;
      white-space: nowrap;
      height: 40px;
      margin-bottom: 20px;
    }
    .menuItems {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      gap: 10px;

      @media (min-width: 769px) and (max-width: 1440px) {
        margin-bottom: 16px;
        gap: 8px;
      }
      &.menuItemsConditions {
        flex-wrap: nowrap;
      }
    }
    .item {
      border-radius: 8px;
      background: #d7e2ff;
      color: #1e3166;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 9px 15px;
      cursor: pointer;
      display: flex;
      width: fit-content;
      white-space: nowrap;

      @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 6px;
        font-size: 13px;
        padding: 7px 12px;
      }
    }
    .itemActive {
      background-color: #1e3166;
      color: #fff;
      border-radius: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 9px 15px;
      cursor: pointer;
      display: flex;
      width: fit-content;
      white-space: nowrap;

      @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 6px;
        font-size: 13px;
        padding: 7px 12px;
      }
    }
  }
  .selectionAssignee{
    max-width: 300px;
    margin: 0 0 15px 0;

    @media (min-width: 769px) and (max-width: 1440px) {
      max-width: 240px;
      margin:0 0 12px 0;
    }
  }
}