@import "@assets/styles/variables.scss" ;

.customSkuPrefix {
	font-family: "Open Sans",'sans-serif';
	.popoverIcon{
		cursor: pointer;
	}
	.tippyBlock {
		display: flex;
		align-items: center;
		gap:10px;

		.questionIcon {
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
	.title {
		color: #000;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: capitalize;
		margin-bottom: 5px;

		@media (min-width: 769px) and (max-width: 1440px) {
			font-size: 13px;
			margin-bottom: 4px;
		}
	}

	.skuPrefixBlock {
		display: flex;
		flex-direction: column;
		gap:10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}

		.blockTitle {
			font-size: 18px;
			font-weight: 600;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 14px;
			}
		}

		.inputBlock {
			max-width: 320px;
			position: relative;

			@media (min-width: 769px) and (max-width: 1440px) {
				max-width:256px;
			}

			input {
				padding-left: 10px;
				height: 39px;
				width: 100%;
				border: 1px solid #B6B6B6;
				border-radius: 4px;

				@media (min-width: 769px) and (max-width: 1440px) {
					padding-left: 8px;
					height: 32px;
					border-radius: 3px;
				}
			}

			p {
				font-size: 13px;
				position: absolute;
				bottom: -20px;
				left: 0;

				@media (min-width: 769px) and (max-width: 1440px) {
					font-size: 11px;
					bottom: -16px;
				}
			}

			.limitTextDanger {
				color:#ff4d4d;
			}
		}
	}
}

.isClickedQuestionClass {
	path {
		fill: #1E3166;
	}
}