tr {
  td {
    .myCheckbox {
      cursor: pointer;
      margin: 0 !important;
      -webkit-appearance: none;
      border: 1px solid #1e3166;
      border-radius: 8px;
      outline: none;
      width: 25px;
      height: 25px;
      font: initial;
      flex-shrink: 0;
      background-color: #fff;

      @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 6px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .cellContent {
    cursor:text;
  }
  .thumbnailTd {
    border: 1px solid #ccc;
    padding: 8px;

    @media (min-width: 769px) and (max-width: 1440px) {
      padding: 6px;
    }

    .thumbnail {
      position: relative;
      svg {
        height: 67px;

        @media (min-width: 769px) and (max-width: 1440px) {
          height: 54px;
        }
      }

      img {
        width: 100px;
        max-height: 100px;

        @media (min-width: 769px) and (max-width: 1440px) {
          width: 80px;
          max-height: 80px;
        }
      }
    }
  }
  .platforms {
    svg {
      @media (min-width: 769px) and (max-width: 1440px) {
        width: 32px;
        height: 32px;
      }
    }
  }

  a img {
    height: 35px;
    width: 35px;
  }

  .badge {
    background: #4DB89E;
    border-radius: 10px;
    height: 30px;
    width: 45px;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    bottom: -3px;
    right: -6px;
    position: absolute;
  }
  .customMarketplace {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .customMarketplaceItem {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

    }
    .customMarketplaceTitle {
      font-size: 15px;

      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 13px;
      }
    }

  }

}