.syncButton.extenstionNotInstalled {
    background: gray!important;
    color: white;
    white-space: nowrap;
    padding: 10px;
    border-radius: 4px;
    border: none;
}
.syncButton.hidden {
    display: none;
}
.syncButton.active {
    display: block;
    border: none!important;
    border-radius: 8px!important;
    color: #fff!important;
    cursor: pointer!important;
    font-size: 16px!important;
    font-weight: 600;
    min-height: 39px!important;
    transition: all .3s!important;
    padding: 8px 15px;
    margin-right: 15px;
    font-family: Open Sans,sans-serif!important;
    background-color: #4DB89E;
}
.syncMessage.active{
    display: block;
    font-family: Open Sans,sans-serif!important;
    font-size: 12px!important;
    font-weight: 600;
}
.syncMessage.hidden {
    display: none;
}
.cancelButton {
    color: white;
    white-space: nowrap;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background: #FF5353!important;
}
.progressButton {
    background-color: #FCD45F!important;
}

.syncTextContainer {
    display: flex;
    flex-direction: column;
}