.singleProductViewContainer{
  height: 100vh;
  margin-left: -25px;
  margin-right: -55px;
  &.singleProductAsModal{
    position: fixed;
    z-index: 9994;
    background-color: #fff;
    height: 100%;
    width: 80vw;
    margin-left: 0;
    margin-right: 0;
    right: 0;
    @media screen and (max-width: 768px) {
      z-index: 999;
      width: 100%;
      bottom: 60px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-left: 0;
  }
}
.SingleProductView {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  min-height: 100vh;
  .singleViewBody{
    overflow-y: auto;
    overflow-x: hidden;
    height: 94vh;
    margin-top: 50px;
    background-color: #F8F8F8;
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 0 30px 0;
    }
    &.singleViewBodyModal{
      @media screen and (max-width: 768px) {
        padding: 20px 0 30px 0;
        height:calc(100vh - 110px);
      }
    }
    @media screen and (max-width: 768px) {
      height:calc(100vh - 115px);
    }
  }
  .listingViewTitle{
    width: 100%;
    color: #2B2B2B;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding: 20px 0 15px 20px;
    @media screen and (max-width: 768px) {
     display: none;
    }
  }
  .fixedHeader{
    position: sticky;
    top: 0;
    background-color: #fff;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    z-index: 99;
    @media screen and (max-width: 768px) {
      display: none;
    }
    .closeIconListingModal{
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FF6B6B;
      border-radius: 8px;
      position: absolute;
      right: 20px;
      top: 10px;
      cursor: pointer;
    }

  }
  @media only screen and (max-width: 768px){
    width: 100%;
    margin: 0;
    border: none;
    &.singleProductViewModal{
      padding-top: 56px;
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    height: 100px;
  }
}
.images {
  border-bottom: solid #e0e0e0 1px;
  padding: 0 20px 20px 20px;
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
}
.mainDetailsContainer {
  display: grid;
  grid-template-columns: 300px auto;
  max-width: calc(100% - 30px);
  gap: 30px;
  @media screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 10px;
  }
}
