.editFavoriteLinksModal  {
	height: 79vh;
	width: 100%;
	background: #fff;
	padding: 20px 10px 10px 10px;
	overflow-y: auto;

	.headerBlock {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-bottom: 10px;
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			div {
				color: #000;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}

		p {
			color: #000;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}

	.linksWrapper {
		display: flex;
		flex-direction: column;
		gap:5px;
		margin-bottom: 30px;

		.linkItem {
			cursor: pointer;
			padding: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 8px;

			&.active {
				background: #E9EEFF;

				div {
					color: var(--Dark-Blue, #1E3166);
				}
			}

			div {
				color:#767676;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
		}
	}

	.linkBtns {
		display: flex;
		align-items: center;
		gap:20px;
		justify-content: center;


		button {
			padding: 9px 15px;
			border-radius: 8px;
			border:none;
			cursor: pointer;

			&.save {
				color: #fff;
				background: var(--Dark-Blue, #1E3166);
			}
			&.cancel {
				color: #B6B6B6;
				background: transparent;
				border: 1px solid var(--Grey, #B6B6B6);

			}
		}
	}
}