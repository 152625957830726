#tabsContent .tippy-box{
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #cecece;
	font-size: 16px;
	line-height: 1.4;
	white-space: normal;
	outline: 0;
	transition-property: transform, visibility, opacity;
	position: relative;
	z-index: 10 !important;
	transition-duration: 0ms !important;
}
#custom-marketplaces .tippy-box{
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #cecece;
	font-size: 16px;
	line-height: 1.4;
	white-space: normal;
	outline: 0;
	transition-property: transform, visibility, opacity;
	position: relative;
	z-index: 10 !important;
	transition-duration: 0ms !important;
}
#tabsContent .tippy-content{
	transition-duration: 0ms !important;
}
#tabsContent .tippy-box{
	background-color: #fff;
	border: 1px solid rgba(182,182,182, 0.8);
	color: #333;
	padding: 10px;
}
#custom-marketplaces .tippy-content{
	transition-duration: 0ms !important;
}

#settingsPageWebsite .tippy-box, .analyticsAccessLabelWrapper .tippy-box{
	background-color: #fff;
	padding: 10px;
	border-radius: 8px;
	border: 1px solid #cecece;
	font-size: 16px;
	line-height: 1.4;
	white-space: normal;
	outline: 0;
	transition-property: transform, visibility, opacity;
	position: relative;
	z-index: 10 !important;
	transition-duration: 0ms !important;
	color: #333 !important;
}
