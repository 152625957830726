@import "@assets/styles/variables.scss" ;

.tableContainer {
  position: relative;
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    cursor: pointer;
  }

  .myCheckbox.stickyCheckbox {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 55%;
      width: 12px;
      height: 2px;
      background-color: grey; /* Set the color of the minus sign */
      transform: translate(-50%, -50%);
      border: none; /* Remove border to make a flat line */
    }

    &:checked::after {
      /* Optional: Change the minus sign color when checked */
      background-color: grey; /* Keep the minus sign grey when checked */
    }
  }

  .myCheckbox {
    cursor: pointer;
    margin: 0 !important;
    -webkit-appearance: none;
    border: 1px solid #1e3166;
    border-radius: 8px;
    outline: none;
    width: 25px;
    height: 25px;
    font: initial;
    flex-shrink: 0;
    background-color: #fff;
    transition:$transition;

    &:hover {
      opacity: $globalOpacity;
    }

	  @media (min-width: 769px) and (max-width: 1440px) {
		  width: 20px !important;
		  height: 20px !important;
		  border-radius: 6px !important;
	  }
    &.defaultstickyCheckbox{
      margin-left: 6px !important;

      @media (min-width: 769px) and (max-width: 1440px) {
        margin-left: 5px;
      }
    }

    &::after {
      content: ' ';
      display: block;
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center;
      left: 9px;
      top: 4px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      position: relative;
      width: 5px;
      height: 10px;

      @media (min-width: 769px) and (max-width: 1440px) {
        left: 7px !important;
        top: 3px !important;
        border-width: 0 1px 1px 0 !important;
        width: 4px !important;
        height: 8px !important;
      }
    }

    &:checked {
      background-color: #1e3166;
      border-color: #1e3166;
      &::after {
        content: ' ';
        display: block;
        background-size: 35px;
        background-repeat: no-repeat;
        background-position: center;
        left: 9px;
        top: 4px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: relative;
        width: 5px;
        height: 10px;
        background-color: #1e3166 !important;
      }
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  .leftContainer {
    position: fixed;
    cursor: pointer;
    z-index: 999;
    margin-left: -20px;
    top: 50%;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: -16px;
      top: 40%;
    }

    svg {
      width: 40px;

      @media (min-width: 769px) and (max-width: 1440px) {
        width: 32px;
      }
    }
    @media only screen and (max-width: 768px) {
      margin-left: 0px;
    }
  }

  .loaderTableCatalogOuter {
    height: 120px;
    position: relative;

    @media only screen and (max-width: 768px) {
      height: 96px;
    }

    .loaderTableCatalog {
      position: fixed;
      padding: 33px;
      width: calc(94vw - 238px);
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 1257px) {
        width: calc(91vw - 236px);
      }
      @media only screen and (max-width: 768px) {
        padding: 26px;
        width: calc((94vw - 238px) * 0.8);
      }
    }
  }
  .hideLeftContainer {
    display: none;
  }

  .rightContainer {
    position: fixed;
    cursor: pointer;
    z-index: 999;
    margin-right: -37px;
    top: 50%;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-right: -30px;
      top: 40%;
    }

    svg {
      width: 40px;

      @media (min-width: 769px) and (max-width: 1440px) {
        width: 32px;
      }
    }
    @media only screen and (max-width: 768px) {
      margin-right: -14px;
    }
  }

  .hideRightContainer {
    display: none;
  }

}

.noResults {
  padding: 33px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 26px;
  }

  p {
    font-weight: 600;
    font-size: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
      font-size: 16px;
    }
  }
}