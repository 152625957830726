.SearchResultContainer{
	padding: 10px 0;
	display: flex;
	align-items: center;
	gap: 10px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 8px;
		padding: 8px 0 14px 0;
	}

	.clearAllItem{
		background-color: #D7E2FF;
		border-radius: 8px;
		cursor: pointer;
		padding: 4px 15px;

		@media (min-width: 769px) and (max-width: 1440px) {
			border-radius: 6px;
			padding: 3px 12px;
		}
	}
	.searchResultList{
		display: flex;
		align-items: center;
		gap: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}
		.searchItem{
			display: flex;
			align-items: center;
			gap: 12px;
			padding: 4px 15px;
			background: #f3f3f3;
			border-radius: 8px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 10px;
				padding: 3px 12px;
				border-radius: 6px;
			}
		}
		.removeItem{
			display: flex;
			cursor: pointer;
			svg{
				path{
					fill: rgb(182, 182, 182);
				}
			}
		}
	}
}