.templatesHeader{
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: 8px;
      min-width: 16px;
    }
  }
}
.selectInput{
  max-width: 350px;
  width: 100%;
  align-items: center;
  @media (min-width: 769px) and (max-width: 1440px) {
    max-width: 280px;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.header {
  background-color: #4db89e;
  border-radius: 6px 6px 0 0;
  padding: 22px;

  @media (min-width: 769px) and (max-width: 1440px) {
    border-radius: 5px 5px 0 0;
    padding: 18px;
  }
  .draftCheckbox {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #393838;
    padding: 5px 10px;
    white-space: nowrap;
    background: white;

    &.addBorder {
      border: 1px solid #4db89e;
    }

    .checkBoxContainer {
      display: inline-block;
      position: relative;
      padding-left: 40px;
      cursor: pointer;
    }

    /* The checkbox itself */
    .checkBoxContainer input {
      display: none;
    }

    /* The custom checkbox */
    .checkBoxContainer .checkMark {
      position: absolute;
      top: -22px;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #4db89e;
      border: 1px solid #4db89e;
      border-radius: 8px;
    }

    .checkBoxContainer .checkboxChecked {
      position: absolute;
      top: -22px;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #e9e9e9;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
    }

    /* The white arrow (you can use an SVG or pseudo-element to create the arrow) */
    .checkBoxContainer .checkMark::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 12px;
      width: 5px;
      border: solid white;
      border-width: 0 2px 2px 0;
    }

    .checkBoxContainer .checkboxChecked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 12px;
      width: 5px;
      border: solid white;
      border-width: 0 2px 2px 0;
    }
  }
  .selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .selectionTitle {
      font-size: 24px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 15px;
      font-style: normal;
      display: flex;
      align-items: center;

      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 19px;
        margin-bottom: 12px;
      }
      svg {
        @media only screen and (max-width: 768px) {
          min-width: 20px;
        }
      }
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        letter-spacing: 0.1em;
        text-align: center;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
}