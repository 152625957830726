.csv-progressbar {
	&__header {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
			margin-bottom: 8px;
		}
	}

	&__title {
		color: #2B2B2B;
		font-size: 14px;
		font-weight: 600;

		@media (min-width: 769px) and (max-width: 1440px) {
			font-size: 11px;
		}

		&--complete {
			color:#32C09A;
			font-weight: 700;
		}
	}

	&__line {
		> div {
			border-radius: 8px !important;
			background: #32C09A !important;
			height: 16px !important;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px !important;
				height: 13px !important;
			}
		}
	}

	&--paddingForListings {
		padding: 20px 0;

		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 16px 0;
		}
	}

	&--paddingForSales {
		padding: 10px 0;

		@media (min-width: 769px) and (max-width: 1440px) {
			padding:8px 0;
		}
	}

}