@import "@assets/styles/variables.scss" ;

.bulkBlockForPopover .tippy-box{
    background-color: #fff;
    border: 1px solid red;
    color: #000;
}
#scrollHeader .tippy-box{
    background-color: #fff;
    border: 1px solid red;
    color: #000;
    padding: 10px;
}
.bulkBlockForPopover .tippy-content{
    padding: 10px !important;
}

.LPE_status_container .LPE_status {
    top: -24px !important;
}

#saveAndCancel .tippy-box{
    right: -5px;
}

.disabledMarketplaces .tippy-box{
    border: 1px solid grey !important;
}
.customize{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
    text-align: right !important;
    padding: 10px;
    gap: 10px;
    border: 1px solid #1E3166;
    border-radius: 8px;
    height: 40px;
    color: #1E3166;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: $transition;

    &:hover {
        opacity: $globalOpacity;
    }

}
.customizeDisabled{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
    text-align: right !important;
    padding: 10px;
    gap: 10px;
    border: 1px solid #e6e6e6;
    color: grey;
    border-radius: 8px;
    height: 40px;
}
.customizeDisabled svg path{
    fill: #B6B6B6;
}

@media (min-width: 769px) and (max-width: 1440px) {
    .customize, .customizeDisabled {
        padding: 8px !important;
        gap: 8px !important;
        font-size: 13px !important;
        border-radius: 6px !important;
        height: 32px !important;

        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }
}
