@import '../../../../assets/styles/variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.platformsModal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(51, 51, 51, 0.4);

  .modal {
    position: fixed;
    font-family: $fontFamilyOpenSans !important;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    padding: 10px 20px;
    top: 10px;
    bottom: 10px;
    min-width: 500px;
    @media only screen and (max-width: 768px) {
     min-width: auto;
    }

    @media (min-width: 769px) and (max-width: 1440px) {
      border-radius: 6px;
      padding: 8px 16px;
      min-width: 400px;
    }

    .close__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 16px;
      }

    }

    .platformsTitleWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    h1 {
      font-family: $fontFamilyOpenSans;
      color: $dark;
      text-transform: none !important;
      font-size: 30px;
      font-weight: 600;
      word-wrap: break-word;
      word-break: break-word;
      margin-top: -13px;

      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 24px;
        margin-top: -10px;
      }
    }

    .platformsModal__wrapper {
      overflow: auto;
      max-height: calc(100% - 60px);
      padding-right: 2px;

      &::-webkit-scrollbar {
        width: 5px;

        @media (min-width: 769px) and (max-width: 1440px) {
          width: 4px;
        }
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--that_grey);
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #c1c1c1;
      }

      .platformsModal__row {
        display: grid !important;
        grid-template-columns: 40px repeat(2, 1fr) !important;
        grid-template-rows: 1fr !important;
        grid-column-gap: 10px !important;
        margin-bottom: 10px !important;
        @media only screen and (max-width: 768px) {
          grid-template-columns: 1fr 1fr !important;
          gap: 10px;
        }

        @media (min-width: 769px) and (max-width: 1440px) {
          grid-template-columns: 32px repeat(2, 1fr) !important;
          grid-column-gap: 8px !important;
          margin-bottom: 8px !important;
        }

        .platformsModal__logo {
          width: 40px;
          cursor: pointer;
          @media only screen and (max-width: 768px) {
            display: none;
          }

          @media (min-width: 769px) and (max-width: 1440px) {
            width: 32px;

            .platformsModal__logo {
              div {
                padding: 8px !important;
                height: 32px !important;
                border-radius: 3px !important;
              }
            }
          }

          img {
            width: 100%;
            height: 40px;

            @media (min-width: 769px) and (max-width: 1440px) {
              height: 32px;
            }
          }
          svg {
            width: 100%;
            height: 40px;

            @media (min-width: 769px) and (max-width: 1440px) {
              height: 32px;
            }
          }
        }

        .platformModal__button__green {
          color: #393838 !important;
          background: rgba(77, 184, 158, 0.1) !important;
          border: 1px solid rgba(77, 184, 158, 0.1) !important;
          white-space: nowrap !important;
          border-radius: 8px !important;
          padding: 0 20px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          cursor: pointer !important;
          height: 39px !important;
          width: initial;
          font-family: 'Open Sans', sans-serif !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          position: relative !important;
          user-select: none;
          transition: $transition;

          &:hover {
            opacity: $globalOpacity;
          }

          @media (min-width: 769px) and (max-width: 1440px) {
            border-radius: 6px !important;
            padding: 0 16px !important;
            height: 32px !important;
            font-size: 13px !important;
          }
        }


        .platformModal__button__red {
          background: rgba(202, 16, 16, 0.1) !important;
          border: 0px solid rgba(202, 16, 16, 0.1) !important;
          color: #393838 !important;
          white-space: nowrap !important;
          border-radius: 8px !important;
          padding: 0 20px !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          cursor: pointer !important;
          height: 39px !important;
          width: initial;
          font-family: 'Open Sans', sans-serif !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          position: relative !important;
          user-select: none;
          transition: $transition;

          &:hover {
            opacity: $globalOpacity;
          }

          @media only screen and (max-width: 768px) {
            padding: 0 5px !important;
          }

          @media (min-width: 769px) and (max-width: 1440px) {
            border-radius: 6px !important;
            padding: 0 16px !important;
            height: 32px !important;
            font-size: 13px !important;
          }
        }
      }
    }
  }
}

.activeClassGreen {
  background: $mint !important;
  color: #fff !important;
}

.activeClassRed {
  background: #ca1010 !important;
  color: #ffffff !important;
}

.platform__modal__button {
  white-space: nowrap !important;
  border-radius: 8px !important;
  padding: 0 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  height: 39px !important;
  width: initial;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  position: relative !important;
  user-select: none;
  transition: $transition;

  &:hover {
    opacity: $globalOpacity;
  }
}

@media (min-width: 769px) and (max-width: 1440px) {
  .modal {
    padding: 8px 16px;
    min-width: 400px;
    top: 8px !important;
    bottom: 8px !important;
    .close__block {
      button {
        font-size: 1.6em;
      }
    }

    h1 {
      font-size: 24px;
    }

    .platformsModal__logo {
      width: 32px;
    }

    .platformsModal__row {
      font-size: 13px !important;
    }

    .platformModal__button__red {
      font-size: 13px !important;
    }

    .platform__modal__button {
      padding: 0 16px;
      font-size: 13px !important;
    }
  }
}
