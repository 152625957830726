@import "@assets/styles/variables.scss" ;

.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background: rgba(43, 43, 43, 0.5);
}

.modal {
	width: 420px;
	height: 253px;
	border-radius: 8px;
	border: 1px solid #1E3166;
	background:  #F8F8F8;
	padding: 20px;

	@media (min-width: 769px) and (max-width: 1440px) {
		border-radius: 6px;
		width: 336px;
		height:202px;
		padding: 16px;
	}

	&__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 75%;

		@media (min-width: 769px) and (max-width: 1440px) {
			height: 60%;
		}
	}
}

.modal__header {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 4px;
		margin-bottom: 12px;
	}

}

.modal__title  {
	color: #2B2B2B;
	font-size: 16px;
	font-weight: 600;

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 13px;
	}
}

.modal__wrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(4, auto);
	gap: 15px;
	margin-bottom: 20px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 12px;
		margin-bottom: 16px;
	}
}

.checker {
	display: flex;
	align-items: center;
	gap: 10px;
	width: fit-content;
	user-select: none;
	transition: $transition;

	&:hover {
		.checker__checkbox {
			opacity:$globalOpacity;
		}

		.checker__label {
			opacity:$globalOpacity;
		}
	}

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 8px;
	}
}

.checker__checkbox {
	cursor: pointer;
	margin: 0 !important;
	-webkit-appearance: none;
	border: 1px solid #1e3166;
	border-radius: 4px;
	outline: none;
	width: 20px;
	height: 20px;
	font: initial;
	flex-shrink: 0;
	background-color: #fff;
	transition: $transition;

	@media (min-width: 769px) and (max-width: 1440px) {
		border-radius: 3px;
		width: 16px;
		height: 16px;
	}

	&::after {
		content: ' ';
		display: block;
		background-size: 35px;
		background-repeat: no-repeat;
		background-position: center;
		left: 7px;
		top: 3px;
		border: solid #ffffff;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		position: relative;
		width: 4px;
		height: 8px;

		@media (min-width: 769px) and (max-width: 1440px) {
			left: 6px;
			top: 2px;
			border-width: 0 1px 1px 0;
			transform: rotate(36deg);

			width: 3px;
			height: 6px;
		}
	}

	&:checked {
		background-color: #1e3166;
		border-color: #1e3166;
	}

	&:disabled {
		opacity: 0.2;
		cursor: not-allowed;
	}

}

.checker__label {
	color: #2B2B2B;
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	transition: $transition;

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 11px;
	}

	&--checked {
		font-weight: 600;
	}
}

.modal__footer {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 12px;
	}
}
