.platformsContainer{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mainLeftContainer{
  border-right: 1px solid #DDD;
  @media screen and (max-width: 768px) {
    border-right: none;
  }
}
.listedOn {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
  max-height: 410px;
  overflow-y: auto;
  min-width: 220px;

  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
  @media screen and (max-width: 768px) {
    border-right: none;
    border-bottom: solid #e0e0e0 1px;
    max-height: 100%;
    padding:20px;
    overflow: initial;

  }
}

.iconBlock {
  width: 40px;
  height: 40px;
  img {
    width: 40px;
    height: 40px;
  }
  svg{
    width: 40px !important;
    height: 40px !important;
  }
}
.platformInfo {
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 10px;
}

.platformName {
  color: var(--Dark, #393838);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.platformIdContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  img{
    height: 14px;
    width: 14px;
  }
}
.platformIdTitle {
  color: var(--Grey1, #767676);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.noMarketListed {
  color: var(--Dark, #393838);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.fieldTitle {
  color: var(--Gray-2, #4f4f4f);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}
.copiedContent{
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  svg{
    height: 18px;
  }
  span{
    font-size: 12px;
    font-weight: bold;
    color: #4db89e;
  }
}
.aListedOnId {
  color: var(--Blue, #2185d0);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline !important;
}

.platformTitleObjectKeyName {
  color: var(--Grey1, #767676);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .platformTitleObjectKeyName {
    font-size: 16px;
  }
}
.platformTitles {
   display: flex;
   flex-direction: column;
   padding: 20px;
   border-top: 1px solid #DDD;
 }

.platformTitleText {
  color: #2B2B2B;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
