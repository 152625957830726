#expressLister .tippy-box {
    background-color: transparent;
    color: #333;
    border: none;
    font-size: 16px;
    font-weight: 400;
}

#expressLister .popover {
    max-width: 300px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        max-width: 240px !important;
    }
}

#expressLister .image-uploader-container{
    border: none !important;
    min-height: auto !important;
    min-width: auto !important;
    padding: 0 !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 6px !important;
    }
}

#basicInfoList .tippy-box{
    background-color: #fff;
    color: #333;
    border: 1px solid #333;
    font-size: 16px;
}