@import "@assets/styles/variables.scss" ;

.tabsContent{
	margin-top: 30px;

	@media (min-width: 769px) and (max-width: 1440px) {
		margin-top: 24px;
	}

	.tabContentInner{
		.poshmarkNewTitle{
			p{
				color: grey;
				font-style: italic;
				margin-bottom: 10px;

				@media (min-width: 769px) and (max-width: 1440px) {
					margin-bottom: 8px;
				}
			}
		}
		.checkBoxesExtensionPoshmark{
			display: flex;
			gap: 30px;
			align-items: center;
			margin-bottom: 20px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 24px;
				margin-bottom:16px;
			}
		}
		.dropdownPoshmark{
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 10px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
				margin-bottom: 8px;
			}
			label{
				display: flex;
				align-items: center;
				gap: 5px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 4px;

					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
		}
		.poshmarkShopIDBlock {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 24px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
				margin-bottom: 19px;
			}

			div {
				display: flex;
				align-items: center;
				gap: 10px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 8px;
				}

				input {
					width: 100%;
					max-width: 450px;
					height: 40px;
					outline: none;
					border: 1px solid #D9D9D9;
					padding-left: 10px;
					border-radius: 8px;

					@media (min-width: 769px) and (max-width: 1440px) {
						max-width: 360px;
						height: 32px;
						padding-left: 8px;
						border-radius: 6px;
					}
				}
			}
		}
		.tags_container{
			outline: none;
			border: 1px solid #D9D9D9;
			border-radius: 8px;
			background: #fff;
			max-width: 809px;
			width: 100%;
			min-height: 100px;
			padding: 5px 0 0 10px;
			resize: none;
			margin-bottom: 0;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px;
				max-width: 647px;
			}

		}
		.csvDisabled{
			cursor: pointer;
			display: flex;
			align-items: center;
		}
		.checkBoxesExtension{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px 10px;
			margin-bottom: 20px;

			@media (min-width: 769px) and (max-width: 1440px) {
				grid-gap: 16px 8px;
				margin-bottom: 16px;
			}
		}
		.extSettingsCheckboxesItem{
			display: flex;
			align-items: center;
			gap: 15px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 12px;

				svg {
					width: 16px;
					height: 16px;
				}
			}
			label{
				cursor: pointer;
				transition: $transition;

				&:hover {
					opacity: $globalOpacity;
				}
			}
		}
		.textareaBlock {
			display: flex;
			flex-direction: column;
			gap: 10px;
			position: relative;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
			}

			label {
				color: #393838;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;
				gap: 5px;

				@media (min-width: 769px) and (max-width: 1440px) {
					font-size: 13px;
					gap: 4px;

					svg {
						width: 16px;
						height: 16px;
					}
				}
			}

			textarea {
				outline: none;
				border: 1px solid #D9D9D9;
				border-radius: 8px;
				background: #fff;
				max-width: 809px;
				width: 100%;
				height: 80px;
				padding: 5px 0 0 10px;
				resize: none;

				@media (min-width: 769px) and (max-width: 1440px) {
					max-width: 647px;
					border-radius: 6px;
					height:64px;
					padding: 4px 0 0 8px;
				}
			}
		}
		.myCheckbox {
			cursor: pointer;
			margin: 0 !important;
			-webkit-appearance: none;
			border: 1px solid #1e3166;
			border-radius: 8px;
			outline: none;
			width: 25px;
			height: 25px;
			font: initial;
			flex-shrink: 0;
			background-color: #fff;
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px !important;
				width: 20px !important;
				height: 20px !important;
			}

			&::after {
				content: ' ';
				display: block;
				background-size: 35px;
				background-repeat: no-repeat;
				background-position: center;
				left: 9px;
				top: 4px;
				border: solid #ffffff;
				border-width: 0 2px 2px 0;
				transform: rotate(45deg);
				position: relative;
				width: 5px;
				height: 10px;

				@media (min-width: 769px) and (max-width: 1440px) {
					left: 7px !important;
					top: 3px !important;
					border-width: 0 1px 1px 0 !important;
					width: 4px !important;
					height: 8px !important;
				}
			}

			&:checked {
				background-color: #1e3166;
				border-color: #1e3166;
			}

			&:disabled {
				opacity: 0.2;
				cursor: not-allowed;
			}
		}
		.poshmarkTabSelectBlock {
			margin-top: 24px;
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 15px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
				margin-bottom: 12px;
			}

			>div {
				display: flex;
				align-items: center;
				gap: 5px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 4px;
				}
			}
		}
		.shopifyTabBlock {
			display: flex;
			align-items: center;
			gap: 20px;
			margin-bottom: 11px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 16px;
				margin-bottom: 9px;
			}

            label{
				display: flex;
				align-items: center;
				gap: 5px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 4px;

					svg {
						width: 16px;
						height: 16px;
					}
				}
			}
			input {
				width: 100%;
				max-width: 450px;
				height: 40px;
				outline: none;
				border: 1px solid #D9D9D9;
				padding-left: 10px;
				border-radius: 8px;

				@media (min-width: 769px) and (max-width: 1440px) {
					max-width: 360px;
					height: 32px;
					padding-left: 8px;
					border-radius: 6px;
				}
			}

		}
		.shopifyCheckboxBlock {
			display: flex;
			flex-direction: column;
			gap: 20px;
			margin-bottom: 20px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 16px;
				margin-bottom: 16px;
			}

			div {
				display: flex;
				align-items: center;
				gap: 15px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 12px;
				}
			}
		}

		.instagramBlock {
			display: flex;
			flex-direction: column;
			gap: 20px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 16px;
			}
            label{
				display: flex;
				align-items: center;
				gap: 5px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 4px;

					svg {
						width: 16px;
						height: 16px;
					}
				}

			}
			.labelTitle {
				display: flex;
				align-items: center;
				gap: 20px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 16px;
				}

				input {
					width: 100%;
					max-width: 450px;
					height: 40px;
					outline: none;
					border: 1px solid #D9D9D9;
					padding-left: 10px;
					border-radius: 8px;

					@media (min-width: 769px) and (max-width: 1440px) {
						max-width: 360px;
						height: 32px;
						padding-left: 8px;
						border-radius: 6px;
					}
				}
			}

			.instagramCheckboxBlock {
				display: flex;
				align-items: center;
				gap: 15px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 12px;
				}
			}
		}

		.etsyBlock {
			.etsyShopIDBlock {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-bottom: 24px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 8px;
					margin-bottom: 19px;
				}

				div {
					display: flex;
					align-items: center;
					gap: 10px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 8px;
					}

					input {
						width: 100%;
						max-width: 450px;
						height: 40px;
						outline: none;
						border: 1px solid #D9D9D9;
						padding-left: 10px;
						border-radius: 8px;

						@media (min-width: 769px) and (max-width: 1440px) {
							max-width: 360px;
							height: 32px;
							padding-left: 8px;
							border-radius: 6px;
						}
					}
				}
			}

			.etsyCheckboxes {
				display: flex;
				flex-direction: column;
				gap: 23px;
				margin-bottom: 24px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap:18px;
					margin-bottom: 19px;
				}

				>div {
					display: flex;
					align-items: center;
					gap: 15px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 12px;
					}
				}
			}

			.etsyQuestionSelects {
				display: flex;
				flex-direction: column;
				gap: 30px;
				margin-bottom: 15px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 24px;
					margin-bottom: 12px;
				}

				label{
					cursor: pointer;
				}

				>div {
					display: flex;
					align-items: center;
					gap: 10px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 8px;
					}

					>div {
						display: flex;
						align-items: center;
						gap: 5px;

						@media (min-width: 769px) and (max-width: 1440px) {
							gap: 4px;
						}
					}
				}
			}
		}

		.ebayBlock, .mercariBlock, .facebookBlock , .grailedBlock, .depopBlock, .kidizenBlock, .vestiaireBlock
		{
			.ebayLabelBlock , .mercariLabelBlock , .facebookLabelBlock, .grailedLabelBlock, .depopLabelBlock, .kidizenLabelBlock, .vestiaireLabelBlock{
				>div {
					display: flex;
					align-items: center;
					gap: 10px;
					margin-bottom: 15px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 8px;
						margin-bottom: 12px;
					}

					> div {
						display: flex;
						align-items: center;
						gap:7px;

						@media (min-width: 769px) and (max-width: 1440px) {
							gap: 6px;
						}
					}
					input {
						width: 100%;
						max-width: 450px;
						height: 40px;
						outline: none;
						border: 1px solid #D9D9D9;
						padding-left: 10px;
						border-radius: 8px;

						@media (min-width: 769px) and (max-width: 1440px) {
							max-width: 360px;
							height:32px;
							padding-left: 8px;
							border-radius: 6px;
						}
					}
				}
			}

			.ebayCheckboxes {
				display: flex;
				flex-direction: column;
				gap:15px;
				margin-bottom: 15px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 12px;
					margin-bottom: 12px;
				}

				> div {
					display: flex;
					align-items: center;
					gap:15px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 12px;
					}
				}
			}
		}
		.mercariBlock {
			.mercariCheckboxs {
				display: flex;
				flex-direction: column;
				gap:15px;
				margin-bottom: 15px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap:12px;
					margin-bottom:12px;
				}

				> div {
					display: flex;
					align-items: center;
					gap:15px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 12px;
					}

					> div {
						display: flex;
						align-items: center;
						gap:8px;

						@media (min-width: 769px) and (max-width: 1440px) {
							gap: 6px;
						}
					}
				}
			}
		}
	}
	.disabledText{
		color: grey !important;
	}
	.disabledTags{
		background: rgba(182, 182, 182, 0.2);
		border: 1px solid #B6B6B6;
		cursor: not-allowed;
		position: absolute;
		top: 32px;
		bottom: 0;
		left: 0;
		right: 0;
		max-width: 809px;
		border-radius: 8px;
		color: #fff;

		@media (min-width: 769px) and (max-width: 1440px) {
			top: 26px;
			max-width: 647px;
			border-radius: 6px;
		}
	}
	.popoverIcon{
		cursor: pointer;
		transition: $transition;

		&:hover {
			opacity: $globalOpacity;
		}
	}
	.popoverContent{
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}

	}
}
.popoverIcon{
	cursor: pointer;
}

.reactSelectZoomWrapper {
	zoom: 0.8;
}