@import "@assets/styles/variables.scss" ;

.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background: rgba(43, 43, 43, 0.5);
}

.modal {
	width: 600px;
	border-radius: 8px;
	border: 1px solid var(--Dark-Blue, #1E3166);
	background: var(--Light-Grey, #F8F8F8);
	padding: 20px;

	@media (min-width: 769px) and (max-width: 1440px) {
		width: 480px;
		border-radius: 6px;
		padding: 16px;
	}

	&__error {
		position: absolute;
		bottom: -15px;
		font-size: 11px;
		color: #FF4D4D;

		@media (min-width: 769px) and (max-width: 1440px) {
			font-size: 9px;
		}
	}

	label {
		cursor: pointer;
		user-select: none;
		width: fit-content;
	}
}

.modal__header {
	margin-bottom: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		margin-bottom: 12px;
	}

	.modal__label {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 600;
		font-size: 16px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
			font-size: 13px;
		}

		svg {
			width: 18px;
			height: 18px;

			@media (min-width: 769px) and (max-width: 1440px) {
				width: 14px;
				height: 14px;
			}

			path {
				fill: #4DB89E !important;
			}
		}
	}
}

.modal__email {
	margin-bottom: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		margin-bottom: 12px;
	}
}

.modal__content {
	display: flex;
	flex-direction: column;
	gap: 5px;
	position: relative;
	min-height: 70px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 4px;
		min-height: 56px;

	}
}

.modal__name {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 8px;
		margin-bottom: 12px;
	}

	.modal__content {
		flex:1
	}
}

.modal__password {
	margin-bottom: 15px;

	@media (min-width: 769px) and (max-width: 1440px){
		margin-bottom:12px;
	}
}

.modal__generateBtn {
	padding: 10px;
	border-radius: 8px;
	background: var(--LP-Light-Blue, #D9E2FC);
	color: var(--Dark-Blue, #1E3166) !important;
	font-weight: 600 !important;
	height: 32px;
	min-height: 32px !important;
	font-size: 14px !important;

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 8px;
		border-radius: 6px;
		height: 28px;
		min-height: 28px !important;
		font-size: 11px !important;
	}
}

.modal__input {
	border-radius: 8px;
	border: 1px solid var(--Border-Grey, #DDD);
	background: #FFF;
	padding: 10px;
	font-size: 14px;
	height: 39px;
	outline: none;
	box-sizing: border-box;

	&:focus {
		height: 39px;
	}


	@media (min-width: 769px) and (max-width: 1440px) {
		border-radius: 6px;
		padding: 8px;
		font-size: 11px;
		height: 32px;

		&:focus {
			height: 32px;
		}

	}

}

.modal__content {
	color: var(--Dark, #2B2B2B);
	font-size: 14px;
	font-weight: 600;

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 11px;
	}
}


.modal__footer {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&-left{
		display: flex;
		align-items: center;
		gap:10px;
		transition: $transition;
		&:hover {
			opacity: $globalOpacity;
		}
		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}
	}

	&-right {
		display: flex;
		align-items: center;
		gap: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}
	}
}

.modal__footer-checkbox {
	cursor: pointer;
	margin: 0 !important;
	-webkit-appearance: none;
	border: 1px solid #1e3166;
	border-radius: 4px;
	outline: none;
	width: 24px;
	height: 24px;
	font: initial;
	flex-shrink: 0;
	background-color: #fff;

	@media (min-width: 769px) and (max-width: 1440px) {
		width: 19px;
		height: 19px;
		border-radius: 3px;
	}

	&::after {
		content: ' ';
		display: block;
		background-size: 35px;
		background-repeat: no-repeat;
		background-position: center;
		left: 9px;
		top: 4px;
		border: solid #ffffff;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		position: relative;
		width: 5px;
		height: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			left: 7px;
			top:3px;
			border-width: 0 1px 1px 0;
			width: 4px;
			height: 8px;
		}
	}

	&:checked {
		background-color: #1e3166;
		border-color: #1e3166;
	}

	&:disabled {
		opacity: 0.2;
		cursor: not-allowed;
	}
}