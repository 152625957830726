.intendedMenu {
  margin-top: 10px;
  margin-bottom: 20px;

  @media (min-width: 769px) and (max-width: 1440px) {
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;
  }
  .conditionMenu {
    display: flex;
    gap: 30px;
    align-items: flex-start;
    input {
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      height: 40px;
      width: 100%;
      padding: 15px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }
  }
  .menuItems {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @media (min-width: 769px) and (max-width: 1440px) {
      gap: 8px;
    }
    &.menuItemsConditions {
      flex-wrap: nowrap;
    }
  }
  .title {
    margin-bottom: 9px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #393838;
    display: flex;
    align-items: center;
    @media (min-width: 769px) and (max-width: 1440px) {
      margin-bottom: 7px;
      font-size: 16px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  .item {
    border-radius: 8px;
    background: #d7e2ff;
    color: #1e3166;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 9px 15px;
    cursor: pointer;
    display: flex;
    width: fit-content;
    white-space: nowrap;

    @media (min-width: 769px) and (max-width: 1440px) {
      border-radius: 6px;
      font-size: 13px;
      padding: 7px 12px;
    }
  }
  .itemActive {
    background-color: #1e3166;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 9px 15px;
    cursor: pointer;
    display: flex;
    width: fit-content;
    white-space: nowrap;

    @media (min-width: 769px) and (max-width: 1440px) {
      border-radius: 6px;
      font-size: 13px;
      padding: 7px 12px;
    }
  }
}
.brandInfoForm {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 30px;

  .leftSide {
    width: 100%;
    @media only screen and (max-width: 768px) {
      .charactersQuantity {
        display: none !important;
      }
    }

    .headerBrandInfo {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      flex-wrap: wrap;
      gap: 20px;

      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #393838;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }

      .suggestDescription {
        border-radius: 8px;
        background-color: #f2711c;
        color: #fff;
        cursor: pointer;
        width: 100%;
        max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 9px 20px;
        min-width: 227px;
        display: flex;
        align-items: center;

        .suggestContainer {
          display: flex;
          align-items: center;
          align-items: center;
        }

        .suggestIcon {
          margin-right: 10px;
        }

        .loader {
          .loaderAnimation {
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #3498db;
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite;
            /* Safari */
            animation: spin 2s linear infinite;

            /* Safari */
            @-webkit-keyframes spin {
              0% {
                -webkit-transform: rotate(0deg);
              }

              100% {
                -webkit-transform: rotate(360deg);
              }
            }

            @keyframes spin {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }

          }
        }
      }
    }
  }
}
.mobileDescBuild {
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
}
.deskDescBuild {
  display: block;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}