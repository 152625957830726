@import "@assets/styles/variables.scss" ;

.customSkuFormat {
	font-family: Open Sans, "sans-serif" ;
	margin-bottom: 20px;
	@media (min-width: 769px) and (max-width: 1440px) {
		margin-bottom: 16px;
	}
	.popoverIcon{
		cursor: pointer;
	}
	.titleBlock {
		display: flex;
		gap:10px;
		margin-bottom: 5px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
			margin-bottom: 4px;

			svg {
				width: 16px;
				height: 16px;
			}
		}

		.title {
			color: #000;
			font-size: 16px;
			font-weight: 700;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
			}
		}

		.questionIcon {
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}
		}
	}

	.skuFormatArea {
		padding: 15px;
		border-radius: 4px;
		border: 1px solid var(--Grey, #B6B6B6);
		position: relative;

		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 12px;
			border-radius: 3px;
		}

		.disableSku{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			background-color: rgba(239, 239, 239, 0.3);
			z-index: 99;
			cursor: not-allowed;
		}
		.formatBlock {
			display: flex;
			align-items: center;
			gap:10px;
			flex-wrap: wrap;
			margin-bottom: 15px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 8px;
				margin-bottom: 12px;
			}

			.skuFormatItem {
				cursor: pointer;
				background: #EDEDED;
				border-radius: 4px;
				display: flex;
				align-items: center;
				gap:10px;
				user-select: none;
				padding: 10px 15px;

				@media (min-width: 769px) and (max-width: 1440px) {
					border-radius: 3px;
					gap: 8px;
					padding: 8px 12px;
				}

				.dragHandle {
					display: flex;
					align-items: center;
					padding: 5px;

					@media (min-width: 769px) and (max-width: 1440px) {
						padding: 4px;
					}
				}

				.tagLabelBlock {
					display: flex;
					align-items: baseline;
					gap:10px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 8px;
					}
				}

				.tagLabel {
					color: var(--Dark, #2B2B2B);
					font-weight: 400;
				}

				.tagRemove {
					padding:0 2px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: $transition;

					&:hover {
						opacity: $globalOpacity;
					}

					@media (min-width: 769px) and (max-width: 1440px) {
						padding: 0 1px;

						svg {
							width: 12px;
							height: 16px;
						}
					}
				}
			}
		}

		.tagsAndDividers {
			display: flex;
			align-items: center;

			@media (max-width: 767px) {
				flex-direction: column;
				align-items: stretch;
				gap:10px;
			}

			.availableBlock {
				.availableTitle {
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					text-transform: capitalize;
					margin-bottom: 15px;

					@media (min-width: 769px) and (max-width: 1440px) {
						font-size: 14px;
						margin-bottom: 12px;
					}
				}
				.availableTagsBlock {
					display: flex;
					align-items: center;
					gap:20px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 16px;
					}

					@media (max-width: 767px) {
						flex-wrap:wrap;
						gap:10px;
					}

					.tagItem {
						cursor: pointer;
						padding: 10px 15px;
						border-radius: 4px;
						background: #EDEDED;
						color: var(--Dark, #2B2B2B);
						font-weight: 400;
						transition: $transition;

						&:hover {
							opacity: $globalOpacity;
						}

						@media (min-width: 769px) and (max-width: 1440px) {
							padding: 8px 12px;
							border-radius: 3px;
						}

					}
				}
			}

			.dividersBlock {
				.dividersTitle {
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					text-transform: capitalize;
					margin-bottom: 15px;

					@media (min-width: 769px) and (max-width: 1440px) {
						font-size: 14px;
						margin-bottom: 12px;
					}
				}

				.dividers {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					gap:20px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 16px;
					}

					.tagItem {
						cursor: pointer;
						padding: 10px 15px;
						border-radius: 4px;
						background:#EDEDED ;
						display: flex;
						align-items: baseline;
						gap:10px;
						transition: $transition;

						&:hover {
							opacity: $globalOpacity;
						}

						@media (min-width: 769px) and (max-width: 1440px) {
							padding: 8px 12px;
							gap: 8px;

							svg {
								width: 10px;
								height: 10px;
							}
						}
					}
				}

			}
		}

	}

	.skuLine {
		width: 100%;
		height: 1px;
		background: #B6B6B6;
		margin: 10px -15px 10px -15px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin:8px -12px 8px -12px;
		}
	}

	.previewBlock {
		.previewTitle {
			font-size: 16px;
			font-weight: 600;
			text-transform: capitalize;
			margin-bottom: 5px;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-bottom: 4px;
				font-size: 13px;
			}
		}

		.previewText {
			color: #000;
		}
	}

}

.toolTip {
	padding: 10px;
	border-radius: 8px;
	border: 1px solid var(--Dark-Blue, #1E3166);
	background: var(--White, #FFF);
	box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.20);

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 8px;
		border-radius: 6px;
	}
}

.questionSvg {
	cursor: pointer;
}

.isClickedQuestionClass {
	path {
		fill: #1E3166;
	}
}