.modalContainer {
  display: flex;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9999;
}

.modal {
  width: 100%;
  max-width: 1400px;

  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-height: 600px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999999;
  margin: 20px;
  margin-top: 5vh;
  @media (max-width: 768px) {
    margin-left: 20px !important;
  }
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
}

.notificationsTitle {
  color: var(--dark-blue, #1e3166);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 10px;
  align-items: center;
}

.firstSectionFlex {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.notificationInput {
  width: 100%;
  input {
    border-radius: 4px;
    border: 1px solid #b6b6b6;
    background: var(--white, #fff);
    padding: 10px;
    color: #b6b6b6;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
}

.notificationBody {
  margin-top: 20px;
  display: flex;
}

.notificationTitle {
  color: var(--dark, #393838);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.typeChecker {
  width: 37px;
  border-top-left-radius: 6px; /* Apply border radius to the top-left corner */
  border-bottom-left-radius: 6px;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date {
  color: rgba(57, 56, 56, 0.65);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
