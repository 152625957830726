.fullImageSlider .slick-slider{
    height: 100%;
}

.fullImageSlider .slick-list{
    height: 100%;
}

.fullImageSlider .slick-track{
    height: 100%;
}

.fullImageSlider .slick-slide div{
    height: fit-content;
}

.fullImageSlider .slick-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}