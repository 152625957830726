.footerButtonsMobile{
	justify-content: center;
	margin: 20px 0;
	display: none;
	align-items: center;
	.editCloseButton {
		display: flex;
		align-items: center;
		background-color: #FF6B6B;
		gap: 10px;
		border-radius: 8px;
		height: 40px;
		padding: 10px;
		color: #fff;
		cursor: pointer;
		min-width: 110px;
		font-size: 16px;
		justify-content: center;
		svg {
			height: 16px;
			width: 16px;
		}
	}
	.editSubmitButtons {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		bottom: 63px;
		background-color: #fff;
		padding: 15px;
		&.editFooterNotFixed{
          bottom: 0;
		}

		button {
			background-color: #1e3166;
			display: flex;
			border-radius: 8px;
			gap: 10px;
			height: 40px;
			padding: 10px 20px;
			justify-content: center;
			align-items: center;
			outline: none;
			border: none;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #fff;
			cursor: pointer;
		}
		@media only screen and (max-width: 1450px) and (min-width: 769px) {
			display: none;
		}
		@media only screen and (max-width: 768px) {
			width: 100%;
			gap: 10px;
		}
	}
	@media only screen and (max-width: 768px) {
		display: flex;
	}
}