.editHeader {
	border-bottom: 1px solid #ececec;
	padding: 10px 30px;
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	right: 35px;
	background-color: #fff;
	height: 60px;
	justify-content: center;
	transition: all .2s;
	z-index: 999;
	left: 115px;
	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 8px 24px;
		right: 28px;
		height: 48px;
		left:92px;
	}
	@media only screen and (max-width: 768px) {
		display: none;
	}
	&.editHeaderFixed{
		position: fixed;
		right: 0;
		z-index: 9993;
		width: 80vw;
		left: auto;
	}

	.headerLeftContainer {
		display: flex;
		gap: 40px;
		align-items: center;

	}
	@media only screen and (max-width: 768px) {
		width: 100%;
		border-left: none;
	}
	.editCloseButton {
		display: flex;
		position: absolute;
		right: 50px;
		align-items: center;
		background-color: #FF6B6B;
		gap: 10px;
		border-radius: 8px;
		height: 40px;
		padding: 10px;
		color: #fff;
		cursor: pointer;
		min-width: 110px;
		font-size: 16px;
		justify-content: center;
		svg {
			height: 16px;
			width: 16px;
		}
	}
	.editSubmitButtons {
		display: flex;
		gap: 20px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 16px;
		}

		@media only screen and (max-width: 768px) {
			width: 100%;
			bottom: 62px;
			gap: 10px;
		}
	}
	.headerTitle {
		color: #000;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	.listingAssistButton {
		background-color: #f2711c;
		border: none;
		border-radius: 8px;
		color: #fff;
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 5px 20px;
		height: 40px;
		font-size: 16px;
		font-style: normal;
		line-height: normal;
		cursor: pointer;
	}
}
.closeEditIcon {
	height: 30px;
	width: 30px;
	cursor: pointer;
}