@import "@assets/styles/variables.scss" ;

.extensionTabs{
	display: flex;
	gap: 8px;
	align-items: center;
	border-bottom: 5px solid #1E3166;
	@media only screen and (max-width: 1160px){
		max-width: calc(100% - 10px);
		overflow-x: auto;
		overflow-y: hidden;
		&::-webkit-scrollbar {
			background-color: #fff;
			width: 16px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid #fff;
		}

		&::-webkit-scrollbar-track {
			background-color: #fff;
		}

		&::-webkit-scrollbar-button {
			display: none;
		}
	}
	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 6px;
	}
	.tabItem{
		height: 40px;
		padding: 10px 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background-color: #EDEDED;
		cursor: pointer;
		color: #393838;
		margin-bottom: 5px;
		transition:$transition;

		&:hover {
			opacity: $globalOpacity;
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			height: 32px !important;
			padding: 8px 12px;
			border-radius: 3px !important;
			margin-bottom: 4px !important;

			svg {
				width: 24px;
				height: 24px;
			}

		}
		&.activeTab {
			background-color:  #1E3166;
			color: #fff;
			border-radius: 4px 4px 0 0;
			height: 46px;
			margin-bottom: -1px;

			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 3px 3px 0 0 !important;
				height: 37px !important;
			}
		}
	}
}