@media (max-width: 768px) {
  .intercom-launcher {
    display: none !important;
  }

  .intercom-launcher-frame {
    display: none !important;
  }
}

.favoriteLinkWrapper  .tippy-box {
    background-color: #fff;
    border: 1px solid red;
    color: #000;
    padding: 5px;
    border-radius: 8px;
}
#mobileMenuMain .tippy-box {
    background-color: #fff;
    padding: 5px;
    border-radius: 8px;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #cecece;
}