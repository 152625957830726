.scanModalMainContainer{
  background-color: rgba( 57, 56, 56, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  width: 100%;
  .scanModalInner{
    max-width: 580px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #F2711C;
    background:#F5F5F5;
    padding: 15px 20px;
    min-height: 223px;
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}