.printLoaderContainer {
  height: 100vh;
  width: 100vw;
  background: white;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.closeIcon {
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  right: 37px;
  top:23px;
}