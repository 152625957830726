.accordionContent {
  padding: 40px 20px 20px 20px;
  border: 1px solid #4db89e;
  border-radius: 8px;
  margin-top: -30px;
  margin-bottom: 20px;
  background: #f8f8f8;
  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 32px 16px 16px 16px;
    border-radius: 6px;
    margin-top: -24px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 15px 15px 15px;
    border-radius: 0 0 8px 8px;
    margin-top: -50px;
  }
  .title {
    margin-bottom: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #393838;
    display: flex;
    align-items: center;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-bottom: 8px !important;
      font-size: 16px !important;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
  .lineContainer{
    display: flex;
    gap: 10px;
    align-items: center;

    @media (min-width: 769px) and (max-width: 1440px) {
      gap: 8px;
    }
    p{
      font-size: 20px;
      font-weight: 600;
      margin-top: -10px;
      text-transform: uppercase;
      min-width: 80px;

      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 16px;
        margin-top: -8px;
        min-width: 64px;
      }
    }
  }
  .bottomOfDescriptionContainer{
    margin-top: 20px;
  }
  .defaultInputs{
    input{
      margin-bottom: 10px;

      @media (min-width: 769px) and (max-width: 1440px) {
        margin-bottom: 8px;
      }
    }
  }
  .addMoreInputs{
    input{
      margin-bottom: 10px;
    }
  }
  .rightContainer{
    input{
      margin-bottom: 10px;
    }
  }
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: 8px;
      min-width: 16px;
    }
  }
  .cogsAndProfit {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .accordionContent1 {
    .line {
      height: 1px;
      background-color: #d9d9d9;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 18px;
    }

    .downContainer {

      .heading {
        margin-bottom: 4px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #393838;
        display: flex;
        align-items: center;
      }

      .measurementInputs {
        display: flex;
        gap: 10px;
        max-width: 510px;

        .popoverItem {
          flex: 1;
        }

        .inputContainer {
          display: flex;
          gap: 10px;
        }

        &.includesBtn {
          max-width: 100%;

          .inputContainer {
            flex: 1;
            max-width: 510px;
          }

          .enterBtn {
            flex: 1;
            margin-left: 50px;
          }
        }

        .left {
          max-width: 382px;
          margin-bottom: 10px;
        }

        .right {
          width: 118px;
          margin-bottom: 10px;
        }

        .enterBtn {
          border-radius: 8px;
          border: 1px solid #1e3166;
          cursor: pointer;
          width: 100%;
          max-width: 254px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #1e3166;
          background: #fff;
          padding: 9px;
          white-space: nowrap;
          height: 40px;
        }

        &.includesBtn {
          @media only screen and (max-width: 768px) {
            flex-direction: column;
            .enterBtn {
              margin-left: 0;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    .upperContainer {
      display: flex;
      gap: 50px;

      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 40px;
      }

      .title {
        margin-bottom: 7px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #393838;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      .leftContainer {
        flex: 1;
      }

      .rightContainer {
        flex: 1;

        .heading {
          margin-bottom: 4px;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #393838;
          display: flex;
          align-items: center;

          @media (min-width: 769px) and (max-width: 1440px) {
            margin-bottom: 3px;
            font-size: 13px;
          }
        }

        .title {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  .equal {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
    margin-top: 15px;
  }
}
.measurementLabelContainer{
  margin-top: 15px;
  .measureItem{
    display: grid;
    grid-template-columns: 3fr 1fr;
    max-width: 400px;
    gap: 15px;
    margin-bottom: 10px;

    @media (min-width: 769px) and (max-width: 1440px) {
      max-width: 320px;
      gap: 12px;
      margin-bottom: 8px;
    }
    &.includesButton{
      grid-template-columns: 3fr 1fr 250px;
      max-width: 665px;
      @media only screen and (max-width: 768px) {
        grid-template-columns: 3fr 1fr;
        max-width: 400px;
      }
    }
  }
  .popoverContainer{
    margin-bottom: 5px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-bottom: 4px;
    }
  }
}