@import '@assets/styles/variables.scss';

.tableSettings__overlay {
	font-family: $fontFamilyOpenSans !important;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background: rgba(51, 51, 51, 0.4);

	.myCheckbox {
		cursor: pointer;
		margin: 0 !important;
		-webkit-appearance: none;
		border: 1px solid #1e3166;
		border-radius: 8px;
		outline: none;
		width: 25px;
		height: 25px;
		font: initial;
		flex-shrink: 0;
		background-color: #fff;

		@media (min-width: 769px) and (max-width: 1440px) {
			width: 20px;
			height: 20px;
			border-radius: 6px;
		}

		&::after {
			content: ' ';
			display: block;
			background-size: 35px;
			background-repeat: no-repeat;
			background-position: center;
			left: 9px;
			top: 4px;
			border: solid #ffffff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			position: relative;
			width: 5px;
			height: 10px;

			@media (min-width: 769px) and (max-width: 1440px) {
				left: 7px;
				top: 3px;
				width: 4px;
				height: 8px;
				border-width: 0 1px 1px 0;

			}
		}

		&:checked {
			background-color: #1e3166;
			border-color: #1e3166;
		}

		&:disabled {
			opacity: 0.2;
			cursor: not-allowed;
		}
	}

	.modal {
		position: relative;
		font-family: 'Open Sans', sans-serif;
		border-radius: 8px;
		background-color: #fff;
		padding: 30px 30px 20px 30px;
		height: 500px;

		&::-webkit-scrollbar {
			width: 5px;

			@media (min-width: 769px) and (max-width: 1440px) {
				width: 4px;
			}
		}

		&::-webkit-scrollbar-track {
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--that_grey);
		}

		&::-webkit-scrollbar-thumb:hover {
			background: #c1c1c1;
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			border-radius: 6px;
			padding: 24px 24px 16px 24px;
			height: 400px;
		}

		.modal__title {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 27px;
			text-transform: capitalize;
			color: #393838;
			margin-bottom: 35px;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 16px;
				line-height: 22px;
				margin-bottom: 28px;
			}
		}

		.modal__wrapper {
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			gap: 40px;
			margin-bottom: 50px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 32px;
				margin-bottom: 40px;
			}

			.columns__title {
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 22px;
				text-transform: capitalize;
				color: #393838;
				margin-bottom: 20px;

				@media (min-width: 769px) and (max-width: 1440px) {
					font-size: 13px;
					line-height: 18px;
					margin-bottom: 16px;
				}
			}

			.modal__viewColumns {

				.modal__viewColumns_wrapper {
					display: grid;
					grid-template-columns: 1fr;
					grid-gap: 10px;
					column-count: 3;
					column-gap: 30px;

					@media (min-width: 769px) and (max-width: 1440px) {
						grid-gap: 8px;
						column-gap: 24px;
					}

					.viewColumnItem {
						break-inside: avoid;
						display: flex;
						align-items: center;
						gap: 10px;
						user-select: none;
						transition: $transition;
						&:hover {
							opacity: $globalOpacity;
						}

						@media (min-width: 769px) and (max-width: 1440px) {
							gap: 8px;
						}

						label {
							white-space: nowrap;
							cursor: pointer;
						}
					}
				}
			}

		}

		.arrange__block {
			.arrange__items {
				display: flex;
				align-items: center;
				gap: 10px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 8px;
				}

				.arrange__wrapper {
					display: flex;
					flex-direction: column;
					gap: 5px;
					width: 250px;
					background: #ffffff;
					border: 1px solid #b6b6b6;
					border-radius: 8px;
					padding: 10px;
					max-height: 250px;
					overflow-y: auto;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 4px;
						width: 200px;
						border-radius: 6px;
						padding: 8px;
						max-height: 200px;
					}

					span {
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 22px;
						text-transform: capitalize;
						color: #393838;
						padding: 3px 0;
						cursor: pointer;
						padding-left: 10px;
						padding-right: 10px;

						@media (min-width: 769px) and (max-width: 1440px) {
							font-size: 13px;
							line-height: 18px;
							padding: 2px 8px 2px 8px;
						}
					}

					.activeSpan {
						background-color: #1e3166;
						color: #fff;
						font-weight: 600;
						border-radius: 8px;
						padding-left: 10px;
						padding-right: 10px;
					}
				}

				.arrange__directions {
					display: flex;
					flex-direction: column;
					gap: 5px;
					user-select: none;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 4px;
					}

					span {
						cursor: pointer;
						background: #ffffff;
						border: 1px solid #1e3166;
						border-radius: 8px;
						padding: 15px 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: $transition;

						&:hover {
							opacity: $globalOpacity;
						}
						&:last-child {
							transform: rotate(180deg);
						}

						@media (min-width: 769px) and (max-width: 1440px) {
							border-radius: 6px;
							padding: 12px 8px;

							svg {
								width: 18px;
								height: 8px;
							}
						}
					}
				}
			}
		}

		.tableSettingFooter {
			.modal__btn__block {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 22px;
				user-select: none;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 18px;
				}

				div {
					display: flex;
					gap: 15px;

					@media (min-width: 769px) and (max-width: 1440px) {
						gap: 12px;
					}

					span {
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 22px;
						text-transform: capitalize;
						color: #1e3166;
						cursor: pointer;

						&:first-child {
							font-weight: 600;
						}

						@media (min-width: 769px) and (max-width: 1440px) {
							font-size: 13px;
							line-height: 18px;
						}
					}
				}
			}
		}
	}
}