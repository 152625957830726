.settingsModal{
	background-color: #fff;
	width: 100%;
	max-width: 760px;
	//height: calc(100vh - 50px);
	border-radius: 8px;
	border: 1px solid  #999;
	padding-top: 11px;
	padding-bottom: 30px;
	position: fixed;
	top:auto;
	bottom: auto;
	margin: auto;
	max-height: calc(100vh - 110px);
	display: flex;
	flex-direction: column;
	.poshmarkNewTitle{
		p{
			color: grey;
			font-style: italic;
			margin-bottom: 10px;
		}
	}
	.dropdownPoshmark{
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
		label{
			display: flex;
			align-items: center;
			gap: 5px;
		}
	}
	&.hideModal{
		display: none;
	}
	&.showModal{
		display: flex;
		flex-direction: column;
	}
	.marketplacesListOuter{

	}
	.closeModal{
		position: absolute;
		top: 15px;
		right:15px;
		cursor: pointer;
	}
	.marketplacesList{
		margin-top: 23px;
		flex-grow: 1;
		overflow-y: auto;
		max-height: calc(100% - 60px);
		padding-bottom: 20px;
		padding-left: 30px;

		&.marketplacesListReconnect{
			flex-grow: 1;
			overflow-y: auto;
			max-height: calc(100% - 60px);
			padding: 10px;
		}
		&::-webkit-scrollbar {
			background-color: #fff;

			width: 16px;
			&-thumb {
				background-color: #babac0;
				border-radius: 16px;
				border: 4px solid #fff;
			}
			&-track {
				background-color: #fff;
			}
			&-button {
				display: none;
			}
		}
		.marketplacesListHeader{
			display: grid;
			grid-template-columns: 1fr 2fr 1fr;
			width: 100%;
			.marketplacesListHeaderItem{
				color: #999;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				&.actions{
					text-align: center;
				}
			}
		}
	}
	.runConnectionWizard{
		cursor: pointer;
		height: 40px;
		margin: 0;
		flex-shrink: 0;
		border-radius: 8px;
		border: 1px solid #4DB89E;
		background: #4DB89E;
		color:  #FFF;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: capitalize;
		display: flex;
		align-items: center;
		padding: 0 18px;
		gap: 12px;
	}
	.reconnectionWizardBtns{
		display: flex;
		padding-left: 30px;
		padding-right: 30px;
		gap: 17px;
		.viewAllSettings{
			cursor: pointer;
			height: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid #1E3166;
			background: #fff;
			color: #1E3166;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
			margin-top: 15px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			gap: 12px;
			width: fit-content;
		}
		.runReConnectionWizard{
			cursor: pointer;
			height: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid  #FF4D4D;
			background: #FF4D4D;
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
			margin-top: 15px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			gap: 12px;
			width: fit-content;
		}
	}

	.bottomButtons {
		position: absolute;
		right: 20px;
		bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		.connectButton {
			border-radius: 8px;
			background: #1E3166;
			padding: 10px;
		}
		.cancelButton {
			border-radius: 8px;
			border: 1px solid #999 !important;
			background: #FFF !important;
			padding: 8px 18px;
			color: #999 !important;
		}
	}
	.notConnectedTitle {
		color:  #999;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-top: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.backButton {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.titles{
		display: flex;
		align-items: center;
		gap: 15px;
		padding-left: 30px;
		padding-right: 30px;
		.heading{
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
		}
		.subTitle{
			color: #999;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}
	.settingsModalContainer{
		padding-right: 30px;
		padding-left: 30px;
		max-height: 100%;
		overflow-y: auto;
		&::-webkit-scrollbar {
			background-color: #fff;
			width: 16px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid #fff;
		}

		&::-webkit-scrollbar-track {
			background-color: #fff;
		}

		&::-webkit-scrollbar-button {
			display: none;
		}
		.textareaContainer{
			p{
				color: #393838;
			}
			textarea{
				resize: none;
				border: 1px solid #D9D9D9;
				outline: none;
				padding: 10px;
				width: 100%;
				border-radius: 8px;
				height: 80px;
				margin-top: 10px;
				font-family: "Open Sans", sans-serif;
			}
		}
		.alternatives{
			margin-top: 10px;
			margin-bottom: 15px;
		}
		.alternativesContainer{
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 10px;
			svg{
				cursor: pointer;
			}
			.checker{
				height: 30px;
				width: 30px;
				cursor: pointer;
				margin: 0 !important;
				-webkit-appearance: none;
				border: 1px solid #1e3166;
				border-radius: 8px;
				outline: none;
				font: initial;
				flex-shrink: 0;
				background-color: #fff;

				&::after {
					content: ' ';
					display: block;
					background-size: 35px;
					background-repeat: no-repeat;
					background-position: center;
					left: 11px;
					top: 5px;
					border: solid #ffffff;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
					position: relative;
					width: 5px;
					height: 10px;
				}

				&:checked {
					background-color: #1e3166;
					border-color: #1e3166;
				}

				&:disabled {
					opacity: 0.2;
					cursor: not-allowed;
				}
			}
			span{
				font-size: 16px;
				color: #393838;
			}
		}
		.shopIdContainer{
			display: flex;
			align-items: center;
			gap: 10px;
			margin-bottom: 10px;
			.shopId{
				border: 1px solid #D9D9D9;
				outline: none;
				height: 40px;
				border-radius: 8px;
				padding: 10px;
				width: 100%;
				max-width: 490px;
			}
			svg{
				cursor: pointer;
			}
		}
		.marketInfoContsiner{
			display: flex;
			gap: 15px;
			margin-bottom: 16px;
			align-items: center;
			p{
				font-size: 20px;
				font-weight: 600;
			}
		}
	}
	.checkBoxesExtensionPoshmark{
		display: flex;
		gap: 30px;
		align-items: center;
		margin-bottom: 20px;

	}
	.tags_container{
		outline: none;
		border: 1px solid #D9D9D9;
		border-radius: 8px;
		background: #fff;
		max-width: 809px;
		width: 100%;
		min-height: 100px;
		padding: 5px 0 0 10px;
		resize: none;
		margin-bottom: 20px !important;
	}
	.csvDisabled{
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.checkBoxesExtension{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px 10px;
		margin-bottom: 20px;
	}
	.extSettingsCheckboxesItem{
		display: flex;
		align-items: center;
		gap: 15px;
		cursor: pointer;
		width: fit-content;
		label{
			cursor: pointer;
		}
	}
	.textareaBlock {
		display: flex;
		flex-direction: column;
		gap: 10px;
		position: relative;

		label {
			color: #393838;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			display: flex;
			align-items: center;
			gap: 5px;
		}

		textarea {
			outline: none;
			border: 1px solid #D9D9D9;
			border-radius: 8px;
			background: #fff;
			max-width: 809px;
			width: 100%;
			height: 80px;
			padding: 5px 0 0 10px;
			resize: none;
			font-family: "Open Sans", sans-serif;
		}
	}
	.myCheckbox {
		cursor: pointer;
		margin: 0 !important;
		-webkit-appearance: none;
		border: 1px solid #1e3166;
		border-radius: 8px;
		outline: none;
		width: 25px;
		height: 25px;
		font: initial;
		flex-shrink: 0;
		background-color: #fff;

		&::after {
			content: ' ';
			display: block;
			background-size: 35px;
			background-repeat: no-repeat;
			background-position: center;
			left: 9px;
			top: 4px;

			border: solid #ffffff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			position: relative;
			width: 5px;
			height: 10px;
		}

		&:checked {
			background-color: #1e3166;
			border-color: #1e3166;
		}

		&:disabled {
			opacity: 0.2;
			cursor: not-allowed;
		}
	}
	.poshmarkTabSelectBlock {
		margin-top: 24px;
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 15px;

		>div {
			display: flex;
			align-items: center;
			gap: 5px;

		}
	}
	.shopifyTabBlock {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 11px;
        label{
			display: flex;
			align-items: center;
			gap: 5px;
		}
		input {
			width: 100%;
			max-width: 450px;
			height: 40px;
			outline: none;
			border: 1px solid #D9D9D9;
			padding-left: 10px;
			border-radius: 8px;
		}

	}
	.shopifyCheckboxBlock {
		display: flex;

		flex-direction: column;
		gap: 20px;
		margin-bottom: 20px;

		div {
			display: flex;
			align-items: center;
			gap: 15px;
		}
	}

	.instagramBlock {
		display: flex;
		flex-direction: column;
		gap: 20px;
		label{
			display: flex;
			align-items: center;
			gap: 5px;
		}

		.labelTitle {
			display: flex;
			align-items: center;
			gap: 20px;
			input {
				width: 100%;
				max-width: 450px;
				height: 40px;
				outline: none;
				border: 1px solid #D9D9D9;
				padding-left: 10px;
				border-radius: 8px;
			}
		}

		.instagramCheckboxBlock {
			display: flex;
			align-items: center;
			gap: 15px;

		}
	}

	.etsyBlock {
		.etsyShopIDBlock {
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin-bottom: 24px;

			div {
				display: flex;
				align-items: center;
				gap: 10px;

				input {
					width: 100%;
					max-width: 450px;
					height: 40px;
					outline: none;
					border: 1px solid #D9D9D9;
					padding-left: 10px;
					border-radius: 8px;
				}
			}
		}

		.etsyCheckboxes {
			display: flex;
			flex-direction: column;
			gap: 23px;
			margin-bottom: 24px;

			> div {
				display: flex;
				align-items: center;
				gap: 15px;
			}
		}

		.etsyQuestionSelects {
			display: flex;
			flex-direction: column;
			gap: 30px;
			margin-bottom: 15px;

			label {
				cursor: pointer;
			}

			> div {
				display: flex;
				align-items: center;
				gap: 10px;

				> div {
					display: flex;
					align-items: center;
					gap: 5px;
				}
			}
		}
	}
	.ebayBlock, .mercariBlock, .facebookBlock , .grailedBlock, .depopBlock, .kidizenBlock, .vestiaireBlock
	{
		.ebayLabelBlock , .mercariLabelBlock , .facebookLabelBlock, .grailedLabelBlock, .depopLabelBlock, .kidizenLabelBlock, .vestiaireLabelBlock{
			>div {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-bottom: 15px;

				> div {
					display: flex;
					align-items: center;
					gap:7px;
				}
				input {
					width: 100%;
					max-width: 450px;
					height: 40px;
					outline: none;
					border: 1px solid #D9D9D9;
					padding-left: 10px;
					border-radius: 8px;
				}
			}
		}

		.ebayCheckboxes {
			display: flex;
			flex-direction: column;
			gap:15px;
			margin-bottom: 15px;

			> div {
				display: flex;
				align-items: center;
				gap:15px;

			}
		}
	}
	.mercariBlock {
		.mercariCheckboxs {
			display: flex;
			flex-direction: column;
			gap:15px;
			margin-bottom: 15px;

			> div {
				display: flex;
				align-items: center;
				gap:15px;

				> div {
					display: flex;
					align-items: center;
					gap:8px
				}
			}
		}
	}
	.disabledText{
		color: grey !important;
	}
	.disabledTags{
		background: rgba(182, 182, 182, 0.2);
		border: 1px solid #B6B6B6;
		cursor: not-allowed;
		position: absolute;
		top: 32px;
		bottom: 20px;
		left: 0;
		right: 0;
		max-width: 809px;
		border-radius: 8px;
		color: #fff;
	}
	.popoverIcon{
		cursor: pointer;
	}
	.popoverContent{
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}
