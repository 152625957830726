
#textAreaTagifyCreateTemplateId .tagify {
    max-height: 300px;
    overflow-y:auto;
    overflow-x: hidden;
    width: 100%;
    background: #fff;
    margin-bottom:0 !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        max-height: 240px;
    }
}

#textAreaTagifyCreateTemplateId .tagify__tag {
    cursor: grab !important;
}

#textAreaTagifyCreateTemplateId .tagify .tagify__tag {
    @media (min-width: 769px) and (max-width: 1440px){
        margin: 4px 0 4px 4px;
    }

}
#textAreaTagifyCreateTemplateId .tagify .tagify__tag > div {
    padding: 4px;

    @media (min-width: 769px) and (max-width: 1440px) {
        padding: 3px;
    }
}

#textAreaTagifyCreateTemplateId .tagify .tagify__tag  .tagify__tag__removeBtn {
    @media (min-width: 769px) and (max-width: 1440px) {
        border-radius: 40px;
        font: 11px / 1 Arial;
        width: 11px;
        height: 11px;
    }
}
