.connectiopnWizardContainer{
	padding-right: 20px;
	display: flex;
	flex-direction: column;
}
.loadingBtn{
	display: flex;
	justify-content: space-between;
	gap: 10px;
	align-items: center;
	p{
		color: #1E3166;
	}
}
.connectionWizardList{

	&::-webkit-scrollbar {
		background-color: #fff;
		width: 16px;
		&-thumb {
			background-color: #babac0;
			border-radius: 16px;
			border: 4px solid #fff;
		}

		&-track {
			background-color: #fff;
		}

		&-button {
			display: none;
		}
	}
}
.connectionWizardModal{
	background-color: #fff;
	width: 100%;
	max-width: 760px;
	//height: calc(100vh - 50px);
	border-radius: 8px;
	border: 1px solid  #999;
	padding-top: 30px;
	padding-bottom: 30px;
	position: fixed;
	top:auto;
	bottom: auto;
	margin: auto;
	max-height: calc(100vh - 110px);
	display: flex;
	flex-direction: column;
	&.hideModal{
		display: none;
	}
	&.showModal{
		display: flex;
		flex-direction: column;
	}
	.marketplacesListOuter{

	}
	.closeModal{
		position: absolute;
		top: 15px;
		right:15px;
		cursor: pointer;
	}
	.marketplacesList{
		margin-top: 23px;
		flex-grow: 1;
		overflow-y: auto;
		max-height: calc(100% - 60px);
		padding-bottom: 20px;
		padding-left: 30px;

		&.marketplacesListReconnect{
			flex-grow: 1;
			overflow-y: auto;
			max-height: calc(100% - 60px);
			padding: 10px;
		}
		&::-webkit-scrollbar {
			background-color: #fff;

			width: 16px;
			&-thumb {
				background-color: #babac0;
				border-radius: 16px;
				border: 4px solid #fff;
			}
			&-track {
				background-color: #fff;
			}
			&-button {
				display: none;
			}
		}
		.marketplacesListHeader{
			display: grid;
			grid-template-columns: 1fr 2fr 1fr;
			width: 100%;
			.marketplacesListHeaderItem{
				color:  #999;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				&.actions{
					text-align: center;
				}
			}
		}
	}
	.runConnectionWizard{
		cursor: pointer;
		height: 40px;
		margin: 0;
		flex-shrink: 0;
		border-radius: 8px;
		border: 1px solid  #4DB89E;
		background:  #4DB89E;
		color:  #FFF;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: capitalize;
		display: flex;
		align-items: center;
		padding: 0 18px;
		gap: 12px;
	}
	.reconnectionWizardBtns{
		display: flex;
		padding-left: 30px;
		padding-right: 30px;
		gap: 17px;
		.viewAllSettings{
			cursor: pointer;
			height: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid #1E3166;
			background: #fff;
			color: #1E3166;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
			margin-top: 15px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			gap: 12px;
			width: fit-content;
		}
		.runReConnectionWizard{
			cursor: pointer;
			height: 40px;
			flex-shrink: 0;
			border-radius: 8px;
			border: 1px solid  #FF4D4D;
			background:#FF4D4D;
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
			margin-top: 15px;
			display: flex;
			align-items: center;
			padding: 0 18px;
			gap: 12px;
			width: fit-content;
		}
	}

	.bottomButtons {
		position: absolute;
		right: 20px;
		bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		.connectButton {
			border-radius: 8px;
			background:  #1E3166;
			padding: 10px;
		}
		.cancelButton {
			border-radius: 8px;
			border: 1px solid #999 !important;
			background: #FFF !important;
			padding: 8px 18px;
			color: #999 !important;
		}
	}
	.notConnectedTitle {
		color:  #999;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		margin-top: 30px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.backButton {
		position: absolute;
		top: 20px;
		right: 20px;
	}
	.titles{
		display: flex;
		align-items: center;
		gap: 15px;
		padding-left: 30px;
		padding-right: 30px;
		.heading{
			color: #000;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;
		}
		.subTitle{
			color: #999;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
		}
	}

}