#singlePageViewSlider .slick-slider {
    position: relative;
    height: 300px;
    /* max-width: 880px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width: 768px) {
    #singlePageViewSlider .slick-slider {
        max-width: 300px;
        height: 106px;
    }
}

#singlePageViewSlider .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    /* max-width: 880px; */
    /* width: 80vw !important; */
}
#singlePageViewSlider .slick-slide div {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    outline: none;
    margin-left: 10px;
    margin-right: 10px;
    width: 110px;
    height: 110px;
}

@media screen and (max-width: 768px) {
    #singlePageViewSlider .slick-slide div {
        width: 92px;
        height: 92px;
        margin: 0;
    }
}

#singlePageViewSlider .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0;
    width: 780px;
    height: 320px;
    margin-bottom: -20px;
    /* width: 100%; */
}
@media screen and (min-width: 1900px) {
    #singlePageViewSlider .slick-list {
        width: 1110px;
    }
}
@media screen and (min-width: 1700px) and (max-width: 1900px) {
    #singlePageViewSlider .slick-list {
        width: 950px;
    }
}
@media screen and (max-width: 1630px) {
    #singlePageViewSlider .slick-list {
        width: 640px;
    }
}
@media screen and (max-width: 1490px) {
    #singlePageViewSlider .slick-list {
        width: 468px;
    }
}
@media screen and (max-width: 1315px) {
    #singlePageViewSlider .slick-list {
        width: 312px;
    }
}
@media screen and (max-width: 1175px) {
    #singlePageViewSlider .slick-list {
        width: 170px;
    }
}
@media screen and (max-width: 768px) {
    #singlePageViewSlider .slick-list {
        width: 300px;
        height: auto;
    }


}
@media screen and (min-width: 768px) {
    #singlePageViewSlider .slick-slide {
        margin: 0;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        position: relative;
        z-index: 999;
        height: 300px;
        width: 156px !important;
    }
}

@media screen and (max-width: 768px) {
    #singlePageViewSlider .slick-slide {
        margin: 7px;
    }
}
#singlePageViewSlider .slick-slide.slick-active {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    position: relative;
    z-index: 999;
    height: 300px;
    width: 156px !important;
}
@media screen and (max-width: 768px) {
    #singlePageViewSlider .slick-slide.slick-active {
        height: 92px;
        width: 92px !important;
        margin: 7px;
    }
}
#singlePageViewSlider .slick-slide.slick-active.slick-current {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    position: relative;
    z-index: 999;
    height: 300px;
    width: 156px !important;
    /* transform: scale(1.6); */
    /* transition: 0.25s; */
}

@media screen and (max-width: 768px) {
    #singlePageViewSlider .slick-slide.slick-active.slick-current {
        height: 92px;
        width: 92px !important;
        margin: 7px;
    }
}
#singlePageViewSlider .image {
    object-fit: contain;
    /* border: lightgray 1px solid; */
    border-radius: 8px;
    background: #e4e4e4;
    width: 110px;
    height: 110px;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    #singlePageViewSlider .image {
        width: 92px;
        height: 92px;
    }
}

#singlePageViewSlider .imageSelected {
    object-fit: contain;
    border: #4db89e 4px solid;
    border-radius: 8px;
    background: #e4e4e4;
    width: 110px;
    height: 110px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    #singlePageViewSlider .imageSelected {
        width: 92px;
        height: 92px;
    }
}
#singlePageViewSlider .imageSelected:focus {
    outline: none !important;
}