@import '../../../../assets/styles/variables.scss';

.mobileTable {
    display: none;
    padding: 20px;

    @media only screen and (max-width: 768px) {
        display: block;
    }
}
.platformItemBlock {
    position: relative;

    span {
        position: relative;

        a {
            img {
                width: 100%;
                height: 35px;
            }
        }

        img {
            width: 35px;
            height: 35px;
        }

        .platformYellowBadge {
            position: absolute;
            top: -30%;
            right: -10%;
            width: 10px;

            img {
                height: 10px;
            }
        }
    }


}
.platformTitleWithNoLink{
    color:  #2B2B2B;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.platformTitleWithLink{
    color:  #2185D0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: $transition;

    &:hover {
        opacity: $globalOpacity;
    }
}

.titleIconActive {
    transform: rotate(180deg);
    height: 100%;
    display: flex;
    align-items: center;
}

.titleIconInactive {
    height: 100%;
    display: flex;
    align-items: center;
}

.tableContainerGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    border-bottom: none !important;

    @media only screen and (min-width: 2000px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @media only screen and (min-width: 3000px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 1600px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 890px) {
        grid-template-columns: 1fr;
    }
}

.loaderTableCatalogOuter {
    height: 120px;
    position: relative;

    .loaderTableCatalog {
        position: fixed;
        padding: 33px;
        width: calc(94vw - 238px);
        display: flex;
        justify-content: center;
        @media only screen and (max-width: 1257px) {
            width: calc(91vw - 236px);
        }
    }
}

.tableContainer {
    position: relative;
    display: flex;
    align-items: center;

    &.tableContainerWithNoResults {
        border: 1px solid #d9d9d9;
    }

    .leftContainer {
        position: fixed;
        cursor: pointer;
        z-index: 999;
        margin-left: -20px;
        top: 50%;
        transition: $transition;

        &:hover {
            opacity: $globalOpacity;
        }

        svg {
            width: 40px;
        }
    }

    .hideLeftContainer {
        // visibility: hidden;
        display: none;
    }

    .rightContainer {
        position: fixed;
        cursor: pointer;
        z-index: 999;
        margin-right: -37px;
        top: 50%;
        transition: $transition;

        &:hover {
            opacity: $globalOpacity;
        }

        svg {
            width: 40px;
        }
    }

    .hideRightContainer {
        display: none;
    }

    table {
        display: grid;
        overflow: auto;
        //max-height: calc(100vh - 170px);
        border-right: 1px solid #d9d9d9;
        border-left: 1px solid #d9d9d9;

        ::-webkit-scrollbar {
            width: 12px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #8d8d8d;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-track {
            background-color: #f5f5f5;
        }


        .myCheckbox.stickyCheckbox {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 55%;
                width: 12px;
                height: 2px;
                background-color: grey; /* Set the color of the minus sign */
                transform: translate(-50%, -50%);
                border: none; /* Remove border to make a flat line */
            }

            &:checked::after {
                /* Optional: Change the minus sign color when checked */
                background-color: grey; /* Keep the minus sign grey when checked */
            }
        }

        .myCheckbox {
            cursor: pointer;
            margin: 0 !important;
            -webkit-appearance: none;
            border: 1px solid #1e3166;
            border-radius: 8px;
            outline: none;
            width: 25px;
            height: 25px;
            font: initial;
            flex-shrink: 0;
            background-color: #fff;
            transition: $transition;

            &:hover {
                opacity: $globalOpacity
            }
            &.defaultstickyCheckbox{
                margin-left: 6px !important;

                @media (min-width: 769px) and (max-width: 1440px) {
                    margin-left: 5px !important;
                }
            }

            @media (min-width: 769px) and (max-width: 1440px) {
                border-radius: 6px !important;
                width: 20px !important;
                height: 20px !important;
            }

            &::after {
                content: ' ';
                display: block;
                background-size: 35px;
                background-repeat: no-repeat;
                background-position: center;
                left: 9px;
                top: 4px;
                border: solid #ffffff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                position: relative;
                width: 5px;
                height: 10px;

                @media (min-width: 769px) and (max-width: 1440px) {
                    left: 7px;
                    top: 3px;
                    border-width: 0 1px 1px 0;
                    width: 4px;
                    height: 8px;
                }
            }

            &:checked {
                background-color: #1e3166;
                border-color: #1e3166;
                &::after {
                    content: ' ';
                    display: block;
                    background-size: 35px;
                    background-repeat: no-repeat;
                    background-position: center;
                    left: 9px;
                    top: 4px;
                    border: solid #ffffff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    position: relative;
                    width: 5px;
                    height: 10px;
                    background-color: #1e3166 !important;

                    @media (min-width: 769px) and (max-width: 1440px) {
                        left: 7px;
                        top: 3px;
                        border-width: 0 1px 1px 0;
                        width: 4px;
                        height: 8px;

                    }
                }
            }

            &:disabled {
                opacity: 0.2;
                cursor: not-allowed;
            }
        }

        tbody {
            tr:nth-child(odd) td {
                background-color: #fff;
                .stickyCheckboxColumn{
                    background-color: #fff;
                }
                .stickyColumnActive{
                    background-color: #fff;
                }
            }

            tr {
                td:first-child {
                    justify-content: center;
                }
                .stickyColumnActive{
                    background-color: #f8f8f8;
                }
                .stickyCheckboxColumn{
                    background-color: #f8f8f8;
                }
            }

            .stickyColumnActive{
                position: sticky;
                left: 70px;
                z-index: 9;
            }
            .stickyCheckboxColumn{
                position: sticky;
                left: 0;
                z-index: 9;
            }
            .catalogImage {
                justify-content: center;
            }


            td {
                display: flex !important;
                justify-content: center;
                align-items: center;
                max-width: 500px !important;
                word-break: break-word;
                color: #393838;
            }

            .catalogNotes {
                width: 100%;
                text-align: left;
            }

            td {
                .catalogNewTitle {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;
                    word-break: break-word;
                    width: 100%;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    transition: $transition;

                    &:hover {
                        opacity: $globalOpacity;
                    }
                }
            }

            tr {
                text-align: center;
                border: 1px solid gray !important;

                input:not([type='checkbox']) {
                    width: calc(100% - 20px);
                    margin-left: 10px !important;
                    margin-right: 10px !important;
                }

                &:last-child {
                    border: none;
                }

                .issues {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 10px;
                    gap: 10px;
                    border-radius: 12px;
                    cursor: pointer;
                    transition: $transition;

                    &:hover {
                        opacity: $globalOpacity;
                    }

                    .yellowTriangle {
                        width: 16px;
                        height: 16px;
                    }
                }

                .platforms {

                    .platformsWrapper {
                        width: 100%;
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(50px, 140px));
                        gap: 0 8px;
                        padding: 10px;
                        overflow: hidden;
                        &.platformsWrapperHasTitle{
                            gap: 0 8px;
                        }

                        &::-webkit-scrollbar {
                            width: 8px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: #888888;
                            border-radius: 8px;

                            &:hover {
                                background-color: #555555;
                            }
                        }

                        &::-webkit-scrollbar-track {
                            background-color: #dddddd;
                            border-radius: 8px;
                        }
                    }

                    a {
                        display: block;
                        margin-bottom: 5px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    a {
                        color: #000;
                    }
                }

                .thumbnailTd {
                    .thumbnail {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;

                        svg {
                            width: 100px;
                            height: 67px;
                            fill: #B6B6B6;

                        }
                    }
                }

                .salesTd {
                    .salesWrapper {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition:$transition;

                        &:hover {
                            opacity:$globalOpacity;
                        }

                        .dollarSignSolid {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 9px;
                            border-radius: $borderRadius8;
                            border: 1px solid #e6e6e6;
                            background: #fff;
                            cursor: pointer;
                            width: 40px;
                            height: 40px;
                            position: relative;

                            .connectCount {
                                width: 26px;
                                height: 20px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #FFF;
                                font-size: 16px;
                                font-weight: 600;
                                background: #4DB892;
                                border-radius: 10px;
                                position: absolute;
                                top:-5px;
                                right: -15px;
                            }
                        }

                        svg {
                            cursor: pointer;
                            width: 15px;
                            height: 24px;
                        }

                        .circleCheck {
                            width: 11px;
                            height: 11px;
                            position: absolute;
                            right: 5px;
                            bottom: 5px;
                        }

                        .connectedTitle {
                            color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                        }

                    }
                }

                .actionsTd {
                    .actions {
                        display: flex;
                        align-content: center;
                        flex-wrap: wrap;
                        align-items: center;
                        gap: 5px;
                        padding: 5px 0;

                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 9px;
                            border-radius: $borderRadius8;
                            border: 1px solid #e6e6e6;
                            background: #fff;
                            width: 35px;
                            height: 35px;
                            cursor: pointer;
                            transition: $transition;

                            &:hover {
                                opacity: $globalOpacity;
                            }
                        }

                        svg {
                            cursor: pointer;
                            transition: $transition;

                            &:hover {
                                opacity:$globalOpacity;
                            }
                        }

                        .recoverySpan {
                            border: 1px solid $red2;
                            background-color: transparent;

                            svg {
                                color: $red2;
                                fill: $red2;
                            }
                        }
                    }
                }

                td {
                    input {
                        margin: 0 auto;
                    }

                    .platformsIcon {
                        border: 1px solid $darkBlue;
                        width: 10px;
                        height: 10px;
                    }

                    button {
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    table thead {
        th {
            position: sticky;
            max-width: 500px ;
            min-width: 70px;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 85px;


            .flexHeaderOptimizer {
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: center;
                cursor: pointer;

                svg {
                    margin-bottom: 5px;
                    cursor: pointer;
                    transform: rotate(180deg);
                }
            }
        }
    }

    table thead,
    table tbody,
    table tr {
        display: contents;
    }

    table th {
        position: relative;
    }

    .editBtn {
        font-family: $fontFamilyOpenSans !important;
        padding: 8px 12px !important;
        border: none !important;
        border-radius: $borderRadius8 !important;
        background-color: $darkBlue !important;
        margin: auto !important;
        color: $textColor !important;
        font-size: inherit !important;

        &:hover {
            background: rgba(30, 49, 102, 0.8) !important;
        }
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.save__cancel__btns {
    font-family: 'Open Sans', sans-serif !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center;
    margin: 0 !important;
    gap: 10px !important;

    button {
        font-family: 'Open Sans', sans-serif !important;
        margin: 0 !important;
    }
}

.activeRow td {
    background-color: #8ae2cc75 !important;
    display: block !important;
    &.stickyColumnActive{
        background: #C5EEE4 !important;
    }
    &.stickyCheckboxColumn{
        background: #C5EEE4 !important;
    }
}

.rowNotActive {
    display: none !important;
}

td {
    .platformsInput {
        font-family: $fontFamilyOpenSans;
        min-width: 100px;
        height: auto;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 15px 10px;
        width: 100%;

        &::-webkit-scrollbar {
            background-color: #fff;
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 16px;
            border: 4px solid #fff;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        .platformsInputWrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: center;
            align-items: center;

            .platformItem {
                display: flex;
                align-items: center;
                input {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.selectedSortView {
    background-color: $tabBgColor;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    min-width: 25px;
    border-radius: 50%;
    transition: $transition;

    &:hover {
        opacity: $globalOpacity;
    }

    @media (min-width: 769px) and (max-width: 1440px) {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }
    svg {
        margin: 0 !important;
        @media (min-width: 769px) and (max-width: 1440px) {
            width: 11px;
            height: 13px;
        }

    }
}

.popOverWrapper {
    user-select: none;
    padding: 5px 10px;
    transition: $transition;

    &:hover {
        background: #D7E2FF;

        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }



    .popoverSortItem {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        div {
            &.popoverSortItemIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    width: 14px;
                    height: 16px;
                }
            }

            &:last-child {
                font-family: $fontFamilyOpenSans !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                color: #1e3166;
            }
        }
    }
}

table {
    thead {
        tr {
            th {
                background: $tableRowBgColor !important;
            }
        }
    }

    .noResultsOuter {
        height: 120px;
        position: relative;

        @media (min-width: 769px) and (max-width: 1440px) {
            height: 96px;
        }

        .noResultsContainer {
            position: fixed;
            padding: 33px;
            width: calc(94vw - 238px);
            text-align: center;

            @media (min-width: 769px) and (max-width: 1440px) {
                padding: 26px;
                //width: calc((94vw - 238px) * 0.8);
            }
            @media only screen and (max-width: 1257px) {
                width: calc(91vw - 236px);
            }

            p {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                @media (min-width: 769px) and (max-width: 1440px) {
                    font-size: 16px;
                }
            }
        }
    }

    tbody {
        .firstRow {
            td {
                background-color: $tableRowBgColor;

                input {
                    background-color: #fff !important;
                }
            }
        }
    }
}

.keywordsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    align-items: center;

    li {
        padding: 2px 6px;
        background: rgb(215, 226, 255);
        border-radius: 8px;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
            word-break: break-word;
        }
    }
}


@media (min-width: 769px) and (max-width: 1440px) {
    .platformTitleWithNoLink, .platformTitleWithLink {
        font-size: 13px;
    }

    .tableContainerGrid {
        gap: 24px;

    }

    .tableContainer {
        table {
            tbody {
                td {
                    font-size: 13px;
                }

                .actions {
                    gap:4px !important;
                    padding: 4px 0 !important;

                     > svg {
                         border-radius: 6px !important;
                         width: 28px !important;
                         height: 28px !important;
                    }

                    a {
                        padding: 7px !important;
                        width: 28px !important;
                        height: 28px !important;
                        border-radius: 6px !important;
                    }
                }
            }
        }

        .leftContainer {
            svg {
                width: 32px;
            }
        }

        .rightContainer {
            svg {
                width: 32px;
            }
        }

    }

    td {
        a {
            font-size: 13px !important;
        }
    }

    .thumbnailTd {
        .thumbnail {
            svg {
               height: 54px !important; ;
                width: 80px !important;
            }
        }
    }

    table thead {
        th {
            gap: 4px !important;
            min-width: 56px !important;
            max-height: 70px !important;

            .flexHeaderOptimizer {
                gap: 4px;

                svg {
                    margin-bottom: 4px;
                }
            }

            input:not([type='checkbox']) {
                height: 32px !important;
                border-radius: 3px !important;
            }

            svg{
                width: 12px;
                height: 12px;
            }
        }
    }

    .selectedSortView {
        width: 24px !important;
        height: 24px !important;
        min-width: 20px !important;
        border-radius: 40% !important;

        svg {
            margin: 0 !important;
        }
    }

    .salesTd {
        a {
            gap: 4px !important;
        }

        .dollarSignSolid {
            padding: 7px !important;
            border-radius: 6px !important;
            width: 32px !important;
            height: 32px !important;

            svg {
                width: 12px !important;
                height: 19px !important;
            }

            .connectCount {
                width: 20px !important;
                height: 20px !important;
                top: -4px !important;
                right: -12px !important;
                font-size: 13px !important;
            }
        }
    }

    .draftIcon {
        width: 25px;
        height: 25px;
    }

    .customMarketWithPlatformId {
        margin: 2px 0 !important;
        font-size: 13px !important;
        gap:8px !important;
        .customMarketName {
            font-size: 13px !important;
        }

        .customMarketIcon {
            width:16px !important;
            height: 16px !important;
            border-radius: 3px !important;
            padding: 4px !important;

            svg {
                width: 16px !important;
                height: 16px !important;
            }
        }
    }

    .customMarket {
        margin: 2px 0 !important;
        font-size: 13px !important;
        gap: 8px !important;
        .customMarketName {
            font-size: 13px !important;
        }

        .customMarketIcon {
            width:16px !important;
            height: 16px !important;
            border-radius: 3px !important;
            padding: 4px !important;

            svg {
                width: 16px !important;
                height: 16px !important;
            }
        }

    }

    .issues {
        gap:8px !important;
        padding: 8px !important;
        border-radius: 10px !important;

        .yellowTriangle {
            width: 13px !important;
            height: 13px !important;
        }

    }



}
