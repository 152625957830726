.defaultTemplateCheckboxMobile {
	display: none;
	@media (max-width: 768px)  {
		display: flex;
		align-items: center;
		padding-bottom: 5px;
		label{
			display: flex;
			align-items: center;
			gap: 10px;
		}
	}
}