.accordionContent2 {
	.upperContainer {
		display: flex;
		gap: 30px;
		justify-content: space-between;
		align-items: flex-start;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 24px;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			gap: 15px;
		}
		.copyKeywordsMobile{
			display: none;
			margin-bottom: 15px;
			@media only screen and (max-width: 768px) {
				display: flex;
				align-items: center;
			}
		}
		.copyKeywordsDesc{
			display: flex;
			align-items: center;
			margin-left: 15px;
			@media only screen and (max-width: 768px) {
				display: none;
			}
		}
		.copyBtnKeywords{
			background-color: #4db89e;
			padding: 5px 10px;
			font-weight: 600;
			color: #fff;
			cursor: pointer;
			border-radius: 6px;
			display: flex;
			align-items: center;
			gap: 5px;
		}
		.copiedText{
			margin-left: 5px;
			font-size: 12px;
		}
		.headerContainer {
			display: flex;
			gap: 25px;
			align-items: center;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 20px;
			}
			@media only screen and (max-width: 768px) {
				flex-direction: column;
				gap: 10px;
				align-items: flex-start;
			}
		}
		.heading {
			margin-bottom: 4px;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #393838;
			display: flex;
			align-items: center;
		}
		.rightContainer {
			.inputsItem {
				display: flex;
				gap: 30px;

				@media (min-width: 769px) and (max-width: 1440px) {
					gap: 24px;
				}

				@media only screen and (max-width: 768px) {
					gap: 14px;

				}

				.heading {
					margin-bottom: 4px;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					color: #393838;

					@media (min-width: 769px) and (max-width: 1440px) {
						margin-bottom: 3px;
						font-size: 13px;
					}
				}
			}
		}
		.leftContainer {
			flex: 1;
			z-index: 0;
			@media only screen and (max-width: 768px) {
				width: 100%;
			}
		}
		.suggestBtn {
			border-radius: 8px;
			border: 1px solid #1e3166;
			cursor: pointer;
			width: 100%;
			max-width: 180px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #1e3166;
			background: #fff;
			padding: 9px;
			white-space: nowrap;
			height: 40px;
			margin-bottom: 10px;
		}
	}
}
.accordionContent {
	padding: 40px 20px 20px 20px;
	border: 1px solid #4db89e;
	border-radius: 8px;
	margin-top: -30px;
	margin-bottom: 20px;
	background: #f8f8f8;

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 32px 16px 16px 16px;
		border-radius: 6px;
		margin-top: -24px;
		margin-bottom: 16px;
	}
	@media only screen and (max-width: 768px) {
		padding: 30px 15px 15px 15px;
		border-radius: 0 0 8px 8px;
		margin-top: -50px;
	}

	.defaultInputs {
		input {
			margin-bottom: 10px;
		}
	}

	.addMoreInputs {
		input {
			margin-bottom: 10px;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-bottom: 8px;
			}
		}
	}

	.rightContainer {
		input {
			margin-bottom: 10px;

			@media (min-width: 769px) and (max-width: 1440px) {
				margin-bottom: 8px;
			}
		}
	}

	.popoverIcon {
		cursor: pointer;
		margin-left: 10px;
		min-width: 20px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-left: 8px;
			min-width: 16px;
		}
	}

	.cogsAndProfit {
		display: flex;
		gap: 20px;
		align-items: center;
	}

	.equal {
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		color: #000;
		margin-top: 15px;
	}
}