@import "@assets/styles/variables.scss" ;

.cstmMarketplacesWrapper {
	margin-bottom: 30px;
	@media (min-width: 769px) and (max-width: 1440px) {
		margin-bottom: 24px;
	}
	.popoverIcon{
		cursor: pointer;
	}
	.customMarketplaces {
		max-width: 641px;
		@media (min-width: 769px) and (max-width: 1440px) {
			max-width: 513px;
		}
		.customMarketLabel {
			font-size: 20px;
			font-weight: 700;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 16px;
			}
		}
		.cancelBtn2 {
			cursor: pointer;
			border-radius: 8px;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			padding: 8px 18px;
			background-color: #fff;
			font-family: 'Open Sans', sans-serif !important;
			min-height: 39px !important;
			white-space: nowrap;
			border: 1px solid #1E3166;
			color: #1E3166;
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}
			@media only screen and (max-width: 768px){
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				svg{
					fill: #1E3166;
				}
			}
			@media (min-width: 769px) and (max-width: 1440px) {
				border-radius: 6px;
				font-size: 13px;
				padding: 6px 14px;
				min-height: 32px !important;
			}
		}
		.customMarketCircleQuestion{
			cursor: pointer;
			display: flex;
			align-items: center;
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}

			@media (min-width: 769px) and (max-width: 1440px) {
				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
		.customMarketTItle {
			display: flex;
			gap: 15px;
			margin-bottom: 10px;
			align-items: center;

			@media only screen and (max-width: 768px){
				gap: 5px;
			}
			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 12px;
				margin-bottom: 8px;
			}
			h2 {
				color: #393838;
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				@media only screen and (max-width: 768px){
					font-size: 16px;
				}
				@media (min-width: 769px) and (max-width: 1440px) {
					font-size: 16px;
				}
			}

			span {
				color: #767676;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				@media only screen and (max-width: 768px){
					font-size: 16px;
				}
				@media (min-width: 769px) and (max-width: 1440px) {
					font-size: 16px;
				}
			}
		}

		table {
			width: 100%;
			border: none;
			text-align: left;
			margin-bottom: 20px;
			border-collapse: collapse;
			@media only screen and (max-width: 768px) {
				border-collapse: separate;
				border-spacing: 0 10px;
			}
			@media (min-width: 769px) and (max-width: 1440px) {
				margin-bottom: 16px;
			}
			thead {
				@media only screen and (max-width: 768px){
					display: none;
				}
				tr {
					th {
						color: #767676;
						font-size: 16px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						padding: 14px 0;
						background-color: transparent !important;

						@media (min-width: 769px) and (max-width: 1440px) {
							font-size: 13px;
							padding: 11px 0;
						}
					}
				}
			}

			tbody {
				tr {
					@media only screen and (max-width: 768px){
						background-color: #EDEDED;
						border-radius: 8px;
						margin-bottom: 10px;
					}
					.platformIconTd{
						width: 15%;
						@media only screen and (max-width: 768px) {
							border-radius:  8px 0 0  8px ;
							padding: 10px;
						}
						@media (min-width: 769px) and (max-width: 1440px) {
							width: 12%;
						}
					}
					.platformNameTd{
						width: 35%;

						@media (min-width: 769px) and (max-width: 1440px) {
							width: 28%;
						}
					}
					.platformColorTd{
						width: 10%;

						@media (min-width: 769px) and (max-width: 1440px) {
							width: 8%;
						}
					}
					.actionsTd{
						width: 40%;
						@media only screen and (max-width: 768px) {
							border-radius: 0 8px 8px 0;
							padding: 10px;
							div{
								display: flex;
								justify-content: flex-end;
							}
						}
						@media (min-width: 769px) and (max-width: 1440px) {
							width: 32%;
						}
					}
					td {
						padding: 15px 0;

						@media (min-width: 769px) and (max-width: 1440px) {
							padding: 12px 0;
						}
					}

					.platformIconTd {
						cursor: pointer;

						div {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 40px;
							height: 40px;
							border-radius: 8px;

							@media (min-width: 769px) and (max-width: 1440px) {
								width: 32px;
								height: 32px;
								border-radius: 6px;
							}
						}

						.actionBtn {
							cursor: pointer;
							border-radius: 8px;
							border: 1px solid var(--Dark-Blue, #1E3166);
							display: flex;
							align-items: center;
							gap: 15px;
							padding: 9px 20px 9px 15px;
							width: fit-content;

							@media (min-width: 769px) and (max-width: 1440px) {
								border-radius: 6px;
								gap: 12px;
								padding: 7px 16px 7px 12px;
							}

						}
					}



					.platformNameTd {
						width: fit-content;
						.platformInputWrapper {
							position: relative;
							width: fit-content;
							input{
								width: 100%;
							}

							span {
								position: absolute;
								left: 0;
								bottom: -25px;
								@media only screen and (max-width: 768px) {
									display: none;
								}
								@media (min-width: 769px) and (max-width: 1440px) {
									bottom: -20px;
								}
							}
						}


						div {
							color: #393838;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;

							@media (min-width: 769px) and (max-width: 1440px) {
								font-size: 13px;
							}
						}
					}

					.platformColorTd {
						@media only screen and (max-width: 768px) {
							display: none;
						}
						.colorDiv {
							width: 30px;
							height: 30px;
							border-radius: 50%;

							@media (min-width: 769px) and (max-width: 1440px) {
								width: 24px;
								height: 24px;
							}
						}

					}

					.actionsTd {

						div {
							display: flex;
							align-items: center;
							gap: 15px;
							@media only screen and (max-width: 768px){
								gap: 5px;
							}
							@media (min-width: 769px) and (max-width: 1440px) {
								gap: 12px;
							}
							.editBtn {
								display: flex;
								align-items: center;
								gap: 14px;
								@media only screen and (max-width: 768px) {
									border: 1px solid #fff !important;
								}
								@media (min-width: 769px) and (max-width: 1440px) {
									gap: 11px;

									svg {
										width: 16px;
										height: 16px;
									}
								}
								span {
									color: #fff;
									font-size: 16px;
									font-style: normal;
									font-weight: 600;
									line-height: normal;
									text-transform: capitalize;
									@media only screen and (max-width: 768px) {
										display: none;
									}
									@media (min-width: 769px) and (max-width: 1440px) {
										font-size: 13px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.addNewBtn {
		border-radius: 8px;
		border: 1px solid #1e3166;
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px 20px;
		width: fit-content;
		cursor: pointer;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
			padding: 8px 16px;
		}

		svg {
			fill: #fff;
		}

		span {
			color: #fff;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			text-transform: capitalize;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
			}
		}
	}

}