@import '@assets/styles/variables.scss';

.myCheckbox {
	cursor: pointer;
	margin: 0 !important;
	-webkit-appearance: none;
	border: 1px solid #1e3166;
	border-radius: 8px;
	outline: none;
	width: 25px;
	height: 25px;
	font: initial;
	flex-shrink: 0;
	background-color: #fff;
	transition: $transition;

	&:hover {
		opacity: $globalOpacity;
	}

	@media (min-width: 769px) and (max-width: 1440px) {
		width: 20px;
		height: 20px;
		border-radius: 6px;
	}
	&.defaultstickyCheckbox{
		margin-left: 6px !important;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-left: 5px !important;
		}
	}

	&::after {
		content: ' ';
		display: block;
		background-size: 35px;
		background-repeat: no-repeat;
		background-position: center;
		left: 9px;
		top: 4px;
		border: solid #ffffff;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		position: relative;
		width: 5px;
		height: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			left: 7px !important;
			top: 3px !important;
			border-width: 0 1px 1px 0 !important;
			width: 4px !important;
			height: 8px !important;
		}
	}

	&:checked {
		background-color: #1e3166;
		border-color: #1e3166;
		&::after {
			content: ' ';
			display: block;
			background-size: 35px;
			background-repeat: no-repeat;
			background-position: center;
			left: 9px;
			top: 4px;
			border: solid #ffffff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
			position: relative;
			width: 5px;
			height: 10px;
			background-color: #1e3166 !important;
		}
	}

	&:disabled {
		opacity: 0.2;
		cursor: not-allowed;
	}
}

.activeRow td {
	background-color: #C5EEE4 !important;
	display: block !important;
}
.rowNotActive {
	display: none !important;
}

.centeredTd{
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	.actions {
		display: flex;
		align-content: center;
		flex-wrap: wrap;
		align-items: center;
		gap: 5px;
		padding: 5px 0;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 4px;
			padding: 4px 0;
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 9px;
			border-radius: $borderRadius8;
			border: 1px solid #e6e6e6;
			background: #fff;
			width: 35px;
			height: 35px;
			cursor: pointer;
			transition:$transition;

			&:hover {
				opacity: $globalOpacity;
			}

			@media (min-width: 769px) and (max-width: 1440px) {
				width: 28px;
				height: 28px;
				border-radius: 6px;
				padding: 7px;
			}
		}

		svg {
			cursor: pointer;

			@media (min-width: 769px) and (max-width: 1440px) {
				width: 19px;
				height: 19px;
			}
		}

		.recoverySpan {
			border: 1px solid $red2;
			background-color: transparent;

			svg {
				color: $red2;
				fill: $red2;
			}
		}
	}
}
.trashTemplate{
	border-radius: 8px;
	background: var(--White, #fff);
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	max-width: 46px;
	border: 1px solid rgb(202, 16, 16);
}

.templateActiveCheckbox, .defaultTemplateCheckbox{
	cursor: pointer;
	margin: 0 !important;
	-webkit-appearance: none;
	border: 1px solid #1e3166;
	border-radius: 8px;
	outline: none;
	width: 25px !important;
	height: 25px;
	font: initial;
	flex-shrink: 0;
	background-color: #fff;
	transition: $transition;

	&:hover {
		opacity: $globalOpacity;
	}

	@media (min-width: 769px) and (max-width: 1440px) {
		width: 20px !important;
		height: 20px;
		border-radius: 6px;
	}

	&::after {
		content: ' ';
		display: block;
		background-size: 35px;
		background-repeat: no-repeat;
		background-position: center;
		left: 9px;
		top: 4px;
		border: solid #ffffff;
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		position: relative;
		width: 5px;
		height: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			left: 7px;
			top: 3px;
			border-width: 0 1px 1px 0;
			width: 4px;
			height: 8px;
		}
	}

	&:checked {
		background-color: #1e3166;
		border-color: #1e3166;
	}

	&:disabled {
		opacity: 0.2;
		cursor: not-allowed;
	}
}