@import "@assets/styles/variables.scss" ;

.sendToSalesModalOverlay, .combineItemsModalOverlay {
	font-family: "Open Sans", 'sans-serif';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	z-index: 999999;
	background: rgba(0, 0, 0, 0.40);

	.newHeaderModal {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #B6B6B6;
		position: sticky;
		top: 0;
		left: 0;
		width: 100%;
		background: #fff;
		z-index: 999999;
		padding: 10px 0;


		.modalBtns {
			margin-left: 20px;

		}


		.closeBtn {
			cursor: pointer;
			padding-right: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 24px;
				transition: $transition;

				&:hover {
					opacity: $globalOpacity;
				}
			}

		}
	}


	.modal {
		position: relative;
		width: 80%;
		height: 100%;
		background-color: #fff;
		font-family: 'Open Sans', sans-serif !important;
		border-radius: 8px;



		.outerModalWrapper {
			height: 95%;
			overflow: scroll;
			padding: 0 20px;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 5px;
			}
		}


		.outerModalTitle {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-family: "Open Sans", 'sans-serif';
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 10px;
			margin-top: 10px;

			div {
				display: flex;
				align-items: center;
				gap: 10px;

				.spanTitle {
					color: #393838;
				}

				span {
					&:last-of-type {
						color: #999;
					}
				}
			}

			.closeBtn {
				cursor: pointer;

				svg {
					font-size: 24px;
					opacity: .3;
				}
			}

		}

		.modalParagpraph {
			color: #393838;
			font-family: inherit;
			font-weight: 400;
			margin-bottom: 10px;
			font-size: 16px;
			font-style: normal;
			line-height: normal;
			text-align: justify;
		}

		.modalWrapper {
			display: flex;
			flex-direction: column;
			gap: 15px;

			.modalItem {
				border-radius: 8px;
				padding: 10px 20px 20px 20px;
				background: #F7F7F7;
				position: relative;
				display: flex;
				align-items: flex-start;
				gap: 10px;

				.modalItemNumber {
					display: flex;
					align-items: center;
					justify-content: center;
					background: #D9E2FC;
					border-radius: 50%;

					span {
						color: #1E3166;
						font-family: inherit;
						font-weight: 600;
						line-height: normal;
						text-transform: capitalize;
						padding: 3px 10px;
					}
				}



				.modalHeader {
					display: flex;
					align-items: center;
					gap: 15px;
					margin-bottom: 10px;

					.modalThumbnail {
						height: 100%;
						img {

							width: 85px;
							height: auto;
						}
					}

					.modalTitle {
						color: #393838;
						font-family: inherit;
						font-weight: 600;
					}

				}

				.trashSoldSvg {
					position: absolute;
					right: 5px;
					bottom: 20px;
					opacity: 0.5;
					cursor: pointer;


					&:hover {
						opacity: 1;
					}
				}


			}
		}

		.combineModalWrapper {

			height: 95vh;
			overflow: scroll;

			&::-webkit-scrollbar {
				width: 5px;
			}
			.wrapper {
				display: flex;
				flex-direction: column;
				gap: 20px;
				padding: 0 20px;

				.modalItem {
					border-radius: 8px;
					padding: 20px;
					background: #F7F7F7;
					position: relative;
					display: flex;
					align-items: flex-start;
					gap: 10px;

					.modalNumWithTrashBlock {
						.modalItemNumber {
							display: flex;
							align-items: center;
							justify-content: center;
							background: #D9E2FC;
							border-radius: 50%;


							span {
								color: #1E3166;
								font-family: inherit;
								font-weight: 600;
								line-height: normal;
								text-transform: capitalize;
								padding: 3px 10px;

							}
						}

					}


					.trashSolid {
						position: absolute;
						right: 20px;
						bottom: 20px;
						opacity: 0.5;
						cursor: pointer;

						&:hover {
							opacity: 1;
						}


					}

					.modalHeader {
						display: flex;
						align-items: center;
						gap: 15px;
						margin-bottom: 10px;

						.modalNumWithTrashBlock {
							display: flex;
							flex-direction: column;
							justify-content: center;
							align-items: center;
							gap: 12px;

							svg {
								cursor: pointer;
							}
						}


						.modalItemNumber {
							display: flex;
							align-items: center;
							justify-content: center;
							background: #D9E2FC;
							border-radius: 50%;

							span {
								color: #1E3166;
								font-family: inherit;
								font-weight: 600;
								line-height: normal;
								text-transform: capitalize;
								padding: 3px 10px;
							}
						}

						.modalThumbnail {
							height: 100%;

							img {
								width: 85px;
								height: auto;
							}
						}

						.modalTitle {
							color: #393838;
							font-family: inherit;
							font-weight: 600;
						}

					}

					.trashSoldSvg {
						position: absolute;
						bottom: 10px;
						right: 10px;
						opacity: 0.5;
						cursor: pointer;

						&:hover {
							opacity: 1;
						}
					}
				}

			}
		}

		.modalFields {
			display: flex;
			align-items: center;
			gap: 15px;
			flex-wrap: wrap;

			.modalFieldItem {
				display: flex;
				flex-direction: column;
				gap: 5px;

				label {
					color: #393838;
					font-family: inherit;
					font-size: 14px;
					font-weight: 600;
				}

				.soldOnPlatforms {

					.customImg {
						width: 25px;
						height: 25px;
					}

					svg {
						width: 25px;
						height: 25px;
					}
				}

				input {
					text-align: left !important;
					border-radius: 8px !important;
					background-color: #fff  !important;

					@media (min-width: 769px) and (max-width: 1440px) {
						border-radius: 6px !important;
					}
					&:focus {
						border:1px solid $mint !important;
						outline: none !important;
					}
				}

				.sendToSales__input  {
					border-radius: 8px ;
					text-align: left ;
					background-color: #fff ;
					height: 39px ;
					min-height: 39px ;
					outline: none ;
					border: 1px solid $dark ;
					padding: 0 10px ;

					@media (min-width: 769px) and (max-width: 1440px) {
						border-radius: 6px ;
						height: 32px ;
						min-height: 32px;
						padding: 0 8px;
					}


					&:focus {
						border: 1px solid $mint ;
					}

					&:disabled {
						outline: 1px solid $gray;
					}

					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}

				.combineModalItemInput {
					width: 145px;
				}
			}
		}

		.modalBtns {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 15px;
		}
		&::-webkit-scrollbar {
			//width: 3px;
		}

	}


}

.backBtn {
	padding: 9px 15px;
	border-radius: 8px;
	border: 1px solid #1E3166 !important;
	background: #fff !important;
	color: #1E3166 !important;
}

.spesificInputWrapper {
	position: relative;
}

.requiredText {
	color:#FF4D4D;
	font-size: 12px;
	position: absolute;
}

.noThumbnail {
	width: 60px;
	height: 60px;
	border-radius: 8px;
	background: #DBDBDB;
}

.datePicker {
	width: 132px;
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type="number"] {
		-moz-appearance: textfield;
	}
}

@media (min-width: 769px) and (max-width: 1440px) {
	.newHeaderModal {
		padding: 8px 0 !important;

		.modalBtns {
			margin-left: 16px !important;
		}

		.closeBtn {
			padding-right: 16px !important;

			svg {
				width: 19px !important;
			}
		}
	}

	.outerModalWrapper{
		padding: 0 16px !important;
	}
	.outerModalTitle {
		font-size: 16px !important;

		div {
			gap: 8px !important;

			svg {
				width: 19px !important;
				height: 20px !important;
			}
		}

		.closeBtn {
			svg {
				font-size: 19px !important;
			}
		}
	}
	.modalParagpraph {
		font-size: 13px !important;
		margin-bottom: 8px !important;
	}
	.modalParagpraph {
		font-size: 13px !important;
	}
	.modalWrapper {
		gap:12px !important;

		.modalItem {
			gap: 8px !important;
			padding: 8px 16px 16px 16px !Important;
			border-radius: 6px !important;

			.modalItemNumber {
				margin-top: 20px !important;
			}

			.modalHeader {
				gap: 12px !important;
				.modalThumbnail {
					img {
						width: 68px !important;
					}
				}
			}
			.trashSoldSvg {
				right: 4px !important;
				bottom: 16px !important;
				width: 17px;
				height: 19px;

			}
		}
	}
	.combineModalWrapper {
		.wrapper {
			gap: 16px !important;
			padding: 0 16px !important;

			.modalItem {
				gap: 8px !important;
				padding: 16px !important;

				.modalItemNumber {
					span {
						padding: 2px 8px !important;
					}
				}
				.modalHeader {
					gap: 12px !important;

					.modalNumWithTrashBlock {
						gap: 10px !important;
					}
				}
				.trashSoldSvg {
					bottom: 8px !important;
					right: 8px !important;

				}
			}
		}
	}
	.modalFields {
		gap: 12px !important;

		.modalFieldItem {
			gap: 4px !important;

			label {
				font-size: 11px !important;
			}

			input {
				border-radius: 6px !important;
			}

		}
	}
	.modalBtns {
		gap: 12px !important;
	}
	.backBtn {
		padding: 6px 12px !important;
	}
	.requiredText {
		font-size: 10px !important;
	}

	.datePicker {
		width: 106px !important;
		height: 32px !important;
	}


	.noThumbnail {
		width: 48px;
		height: 48px;
		border-radius: 6px;
	}

}