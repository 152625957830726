.brandInfoTextarea {
	border-radius: 8px;
	border: 1px solid #d9d9d9;
	width: 100% !important;
	box-sizing: border-box;
	resize: none;
	outline: none;
	padding: 10px 20px;
	overflow: hidden;
	height: 40px;

	@media (min-width: 769px) and (max-width: 1440px) {
		border-radius: 6px;
		padding: 8px 16px;
		height: 32px !important;
	}
	&:focus {
		border: 1px solid #393838;
	}
}

.charactersQuantity {
	color: #696969;
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	line-height: normal;
	margin: 5px;
	@media only screen and (max-width: 768px) {
		margin: 8px 0;
	}
	@media (min-width: 769px) and (max-width: 1440px){
		font-size: 11px;
		margin:4px;
	}
}