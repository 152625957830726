.expressHeader {
  border-bottom: 1px solid #ececec;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  height: 60px;
  justify-content: space-between;
  z-index: 998;
  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 8px 16px;
    height: 48px;
  }
  .progressSavedContainer{
    display: flex;
    gap: 8px;
    align-items: center;
    .timeAgo{
      font-style: italic;
      font-weight: 400;
      color: rgb(118, 118, 118);
    }
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .myListings{
    svg{
     path{
       fill: rgba(255,255,255,0.7);
     }
    }
  }
  .headerLeftContainer {
    display: flex;
    gap: 40px;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    border-left: none;
    padding: 10px;
  }
  .editCloseButton {
    display: flex;
    align-items: center;
    background-color: #FF6B6B;
    gap: 10px;
    border-radius: 8px;
    height: 40px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
    min-width: 110px;
    font-size: 16px;
    justify-content: center;
    svg {
      height: 16px;
      width: 16px;
    }
    @media only screen and (max-width: 768px) {
      height: 35px;
      font-size: 14px;
      min-width: 90px;
    }
  }
  .editSubmitButtons {
    display: flex;
    gap: 20px;
    align-items: center;
    @media only screen and (max-width: 768px) {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      bottom: 62px;
      gap: 10px;
    }
  }
  .headerTitle {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
  .listingAssistButton {
    background-color: #f2711c;
    border: none;
    border-radius: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 20px;
    height: 40px;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
  }
}
.closeEditIcon {
  height: 30px;
  width: 30px;
  cursor: pointer;
}