.price-card {
  border-radius: 8px;
  border: 1px solid #DDD;
  background: #F8F8F8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  max-width: 260px;
  position: relative;

  &--advanced{
    border: 2px solid  #2185D0;
  }
  &__advancedBadge{
    display: inline-flex;
    height: 26px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #2185D0;
    position: absolute;
    top: -14px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__title {
    color: #2B2B2B;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__price {
    color: #32C09A;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__button {
    display: flex;
    width: 100%;
    height: 38px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #32C09A;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    outline: none;
    margin-top: 10px;

    &:hover {
      background: rgba(50, 192, 154, 0.7);
    }
  }

  &__separator {
    background: #DDD;
    height: 1px;
    width: 100%;
    margin: 15px 0;
  }

  &__description {
    color: #2B2B2B;
    font-size: 14px;
    font-weight: 400;
  }

  &__features-heading{
    color:  #2B2B2B;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__features-title{
    color:#2B2B2B;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  &__comparePlans{
    color: #2185D0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline !important;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    margin-bottom: 10px;
  }

  &__features {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-item {
      display: grid;
      grid-template-columns: 20px auto;
      align-items: center;
      gap: 5px;

      &--bold {
        color: #2B2B2B;
        font-weight: 600;
      }

      &--highlight {
        color: #32C09A;
        font-weight: 700;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &-link {
      display: flex;
      height: 32px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        opacity: 0.8;
      }

      &--primary {
        background: #1E3166;
        color: #FFF;
      }

      &--secondary {
        background: #E2EAFF;
        color: #1E3166;
      }
    }
  }
}
