.redirectContainer {
  display: flex;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  z-index: 9999;
}

.redirectModal {
  width: 100%;
  /* max-width: 1400px; */
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999999;
  margin: 20px;
  margin-top: 5vh;
  height: 278px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    color: var(--dark-blue, #1e3166);
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
