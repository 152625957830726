@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&display=swap');
@import "@assets/styles/variables.scss" ;
.header {
  margin-bottom: 2em;

  .header__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 991;
    border-right: 1px solid #ECECEC;
    .logoBlock {
      padding-bottom: 10px;
      width: 100%;
      @media (min-width: 769px) and (max-width: 1440px) {
        padding-bottom: 8px;
      }
    }

    .quickLinksBlock {
      display: flex;
      gap:10px;
      align-items: center;
      padding: 10px 0 5px 0;
      @media (min-width: 769px) and (max-width: 1440px) {
        min-height: 34px;
      }
      .quickLinksTitle {
        padding: 0;
      }
    }


    .quickLinksTitle {
      color: #a6a6a6;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 10px 0 5px 0;
      text-transform: uppercase;
    }

    .line {
      background-color: #d9d9d9;
      height: 1px;
      width: 100%;
      margin: 0 auto;
    }

    .quickLinksContainer {
      display: flex;
      flex-direction: column;
      .quickLinkATagActive {
        color: #1e3166;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 8px;
        background: #E9EEFF;
        &:hover {
          background: #E9EEFF;
        }
        &:active {
          background: #D6DAEA;
          font-weight: 600;
        }
        .quickLinks {
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          gap: 8px;
          padding: 10px;
        }
      }

      .quickLinkATag {
        color: #1e3166;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 8px;
        transition: $transition;
        @media (min-width: 769px) and (max-width: 1440px){
          font-size: 14px;
        }
        &:hover {
          background: #E9EEFF;
        }
        &:active {
          background: #D6DAEA;
          font-weight: 600;
        }

        .quickLinks {
          border-radius: 8px;
          padding: 10px;
          cursor: pointer;
          display: flex;
          gap: 10px;
          min-height: 42px;
          @media (min-width: 769px) and (max-width: 1440px) {
            padding: 8px;
            min-height: 36px;
          }

          svg {
            width: 20px;
            height: 20px;
            @media (min-width: 769px) and (max-width: 1440px) {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .arrow {
      display: flex;
      justify-content: flex-end;
      &.arrowCenter{
        justify-content: center;
      }
      button {
        background: none;
        border: none;
      }
    }
    .optProPlus{
      position: relative;
      border-radius: 8px;
      background: var(--white, #fff);
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap:10px;
      &:hover {
        background: #E9EEFF;;
      }
      &:active {
        background: #D6DAEA;
      }
    }

    .notification {
      position: relative;
      border-radius: 8px;
      background: var(--white, #fff);
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap:10px;
      min-height: 42px;
      transition: $transition;
      @media (min-width: 769px) and (max-width: 1440px) {
        padding: 8px;
        min-height: 36px;
        svg {
          width: 16px;
          height: 16px;
        }
      }



      &:hover {
        background: #E9EEFF;;
      }
      &:active {
        background: #D6DAEA;
      }

      .notificationGreenDot {
        position: absolute;
        background: #4db89e;
        border-radius: 8px;
        height: 12px;
        width: 12px;
        //top: -5px;
        //right: -6px;
        top: 0;
        right: 0;
      }

      .circle {
        border-radius: 8px;
        background: var(--mint, #4db89e);
        padding: 3px 6px 4px;
        color: var(--white, #fff);
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 29px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }

      .marketplaceCircle {
        border-radius: 8px;
        width: 12px;
        height: 12px;
      }

      .marketplaceIcon {
        g {
          path {
            fill: #1E3166;
          }
        }
      }

      .statusCircle {
        width: 12px;
        height: 12px;
        background: #FF4D4D;
        border-radius: 50%;
      }
    }

    .logoLink {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      min-height: 50px;
      .logo {
        width: 35px;
        height: 32px;
      }
    }

    .header__site_menu {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-direction: column;

      li {
        position: relative;
        transition: 0.3s all;

        a {
          text-transform: uppercase;
          font-family: Raleway, sans-serif;
          font-weight: 600;
          color: #213f64;

          display: flex;
          align-items: center;
          gap: 3px;

          &:hover {
            opacity: 0.6;
          }
        }

        ul {
          padding: 30px 0;
          position: absolute;
          top: 100%;
          left: -15px;
          min-width: 250px;
          z-index: 999;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          line-height: 23px;
          background-color: #fff;
          font-size: 18px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          li {
            line-height: 23px;

            a {
              text-transform: capitalize;
              color: #000000 !important;
              font-weight: 400;
              white-space: nowrap;
              margin: 3px 40px;
              border-bottom: 2px solid transparent;
              display: inline;

              &::after {
                content: '';
              }

              &:hover {
                border-bottom-color: #000000;
                border-bottom-width: 2px;
                border-bottom-style: solid;
                transition: all 0.3s ease-out;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .settingsNew{
      display: flex;
      gap: 10px;
      align-items: center;
      span{
        width: 48px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #4DB89E;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#FFF;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        padding: 1px;
      }
    }
    @media only screen and (max-width: 768px) {
      position: inherit;
      display: none;
    }
  }
}

.expandedBlock {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-top:20px;
}

.expanded {
  cursor: pointer;
  height: 40px;
  border-radius: 8px;
  background: #E9EEFF;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) and (max-width: 1440px) {
    svg{
      width: 135px !important;
      height: 16px !important;
    }
  }
}

.leftMenuLinkDisabled {
  cursor: not-allowed !important;
  display: none;
  p,{
    color: #B6B6B6 ;
  }
  svg g path {
    fill: #B6B6B6 !important;

  }

  .redCircle {
    background: #B6B6B6 !important;
  }

}

.red{
  background-color: #FF4D4D;
}
.green{
  background-color: #4db89e;
}

.acrodionItemListingBlock {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;

  &:hover {
    color: #767676;
    background: #F1F1F1;
  }
  &:active {
    color:#767676;
    background: #ECECEC;
  }
  div,a {
    padding: 0;
  }
}

.acrodionItemListingBlockActive {
  background: var(--Light-Grey, #F5F5F5);

  div,a {
    font-weight: 600;
    color: var(--Dark, #2B2B2B);
  }
}

.headerStartIcon {
  display: flex;
  align-items: center;
}

.favoriteIcon {
  width: 20px;
  height: 20px;
  background: #1E3166;
  border-radius: 50%;
}

.disabledExpressLister {
  cursor: not-allowed !important;
  opacity: .4;

  .quickLinks {
    cursor: not-allowed !important;
  }
}

.headerTippy {
  display: none !important;
}

.invisibleAddListingBtn {
  width: 0.5px;
  height: 0.5px;
  visibility: hidden;
  position: absolute;
  top:-999999px;
}

.sideMenuIcon {
  width: 22px !important;
  height: 22px !important;

}