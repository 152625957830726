.sellerDesc {
  margin-top: 50px;
  @media (min-width: 769px) and (max-width: 1440px) {
    margin-top: 40px;
  }
  .title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #393838;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    @media (min-width: 769px) and (max-width: 1440px) {
      margin-bottom: 7px;
      font-size: 16px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .popoverIcon {
      cursor: pointer;
      margin-left: 10px;
      min-width: 20px;

      @media (min-width: 769px) and (max-width: 1440px) {
        margin-left: 8px;
        min-width: 16px;
      }
    }
  }
}
.markListing {
  display: flex;
  align-items: center;
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;
  }
  .draftCheckbox {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-left: 30px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #393838;
    padding: 10px;
    white-space: nowrap;
    @media (min-width: 769px) and (max-width: 1440px) {
      border-radius: 6px;
      margin-left: 24px;
      font-size: 16px;
      padding: 8px;
    }
    &.addBorder {
      border: 1px solid #4db89e;
    }
    .checkBoxContainer {
      display: inline-block;
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      @media (min-width: 769px) and (max-width: 1440px) {
        padding-left:32px;
      }
    }

    /* The checkbox itself */
    .checkBoxContainer input {
      display: none;
    }

    /* The custom checkbox */
    .checkBoxContainer .checkMark {
      position: absolute;
      top: -22px;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #4db89e;
      border: 1px solid #4db89e;
      border-radius: 8px;

      @media (min-width: 769px) and (max-width: 1440px) {
        top: -18px;
        height: 24px;
        width: 24px;
        border-radius: 6px;
      }
    }
    .checkBoxContainer .checkboxChecked {
      position: absolute;
      top: -22px;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: #e9e9e9;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      @media (min-width: 769px) and (max-width: 1440px) {
        top: -18px;
        height: 24px;
        width: 24px;
        border-radius: 6px;
      }
    }
    /* The white arrow (you can use an SVG or pseudo-element to create the arrow) */
    .checkBoxContainer .checkMark::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 12px;
      width: 5px;
      border: solid white;
      border-width: 0 2px 2px 0;
    }
    .checkBoxContainer .checkboxChecked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 12px;
      width: 5px;
      border: solid white;
      border-width: 0 2px 2px 0;
    }
  }
  .title {
    margin-bottom: 9px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #393838;
    display: flex;
    align-items: center;
    @media (min-width: 769px) and (max-width: 1440px) {
      margin-bottom: 7px;
      font-size: 16px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .draftCheckbox {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}