@import "@assets/styles/variables.scss" ;

.colorPicker {
	display: flex;
	align-items: center;
	gap: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		gap: 12px;
	}

	.colorPickerRounded {
		width: 30px;
		height: 30px;
		cursor: pointer;
		border-radius: 50%;
		transition: $transition;

		&:hover {
			opacity: $globalOpacity;
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			width: 24px;
			height: 24px;
		}
	}
}