@import '../../assets/styles/variables';

.closeIcon {
  font-size: 25px;
  cursor: pointer;

  @media (min-width: 769px) and (max-width: 1440px) {
    font-size: 20px;
  }
}

.printHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;

  @media (min-widt  h: 769px) and (max-width: 1440px) {
    margin: 16px;
  }
}

.tableContainer {
  border-radius: $borderRadius8;
  align-items: start;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 20px;

  @media (min-width: 769px) and (max-width: 1440px) {
    margin: 16px;
    gap: 16px;
  }

  table {
    display: grid;
    grid-template-columns: 50px 110px 200px 100px 70px max-content 70px 80px;
    width: 100%;

    thead {
      display: contents;

      tr {
        display: contents;
      }

      th {
        padding: 10px;
        user-select: none;
        border: 1px solid #ccc;
        overflow: hidden;
        text-transform: capitalize !important;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: 769px) and (max-width: 1440px) {
          padding: 8px;
        }

        .myCheckbox {
          cursor: pointer;
          margin: 0 !important;
          -webkit-appearance: none;
          border: 1px solid #1e3166;
          border-radius: 8px;
          outline: none;
          width: 25px;
          height: 25px;
          font: initial;
          flex-shrink: 0;
          background-color: #fff;

          @media (min-width: 769px) and (max-width: 1440px) {
            border-radius: 6px;
            width: 20px;
            height: 20px;
          }
        }
      }

      .emptyHeadCell {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    tbody {
      display: contents;

      tr {
        display: contents;
      }

      td {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ccc;
        word-break: break-word;
        text-align: center;

      }
    }
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 40%;
  gap: 5px;

  @media (min-width: 769px) and (max-width: 1440px) {
    gap: 4px;
  }
}