#singleProductView .tippy-box{
    background-color: #fff;
    border: 1px solid #cecece;
    color: #000;
    padding: 5px;
    border-radius: 8px;
}
#singleProductView .tippy-arrow{
    display: none;
}
.lp_custom_chrome_extension_listing_data {
    display: none !important;
}
#singleProductView .issueDot{
    right: -5px !important;
}
#singleProductView .lp_custom_crosspost_icon_disabled {
    background:#B6B6B6;
}
#singleProductView .lp_custom_crosspost_icon_disabled:hover {
    opacity: .7;
    box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.05);
    background-color: #B6B6B6;
    cursor: not-allowed;
}

#singleProductView .lp_custom_crosspost_icon_active {
    background: #4db89e;
}
#singleProductView .lp_custom_crosspost_icon_active:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #4DB89E;
    box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.05);
}
