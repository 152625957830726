
@import "@assets/styles/variables.scss" ;
.templatesHeader{
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 8px 0;
		gap: 8px;
	}

	.templatesTitle {
		font-size: 20px;
		font-weight: 600;

		@media (min-width: 769px) and (max-width: 1440px) {
			font-size: 16px;
		}
	}
	.popoverContentDisabledActions {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #fff;

		.title {
			font-weight: bold;
			font-size: 15px;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 12px;
			}
		}

		.desc {
			font-size: 15px;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 12px;
			}
		}
	}
	.defaultTemplateCheckbox{
		display: flex;
		align-items: center;
		label{
			display: flex;
			align-items: center;
			gap: 10px;
			cursor: pointer;
			user-select: none;
			transition: $transition;

			&:hover {
				opacity: $globalOpacity;
			}
		}
		@media only screen and (max-width: 768px){
           display: none;
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}
	}

	.templatesHeaderRightPart{
		display: flex;
		gap: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}
	}
	.perPageSelectBlock {
		display: flex;
		align-items: center;
		gap: 8px;
        z-index: 9991;
		span {
			&:first-child {
				text-wrap: nowrap;
			}
		}

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 6px;
		}
	}

	.bulkBtnWrapper {
		transition: opacity .25s ease,
		background-color .1s ease,
		color .1s ease,
		box-shadow .1s ease,
		background .1s ease,
		-webkit-box-shadow .1s ease;

		&:hover {
			opacity: .9;
		}
	}
	.saveAndCancel {
		display: flex;
		align-items: center;
		gap: 5px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 4px;
		}
	}
}
