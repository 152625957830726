.plansBoxesMain{
  margin-top: 76px;
  display: flex;
  justify-content: center;
  .plansBoxesContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 60px;
    row-gap: 30px;
    @media only screen and (max-width: 1050px){
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 768px){
      grid-template-columns: 1fr;
    }
  }
}