.accordionContent {
  padding: 40px 20px 20px 20px;
  border: 1px solid #4db89e;
  border-radius: 8px;
  margin-top: -30px;
  margin-bottom: 20px;
  background: #f8f8f8;
  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 32px 16px 16px 16px;
    border-radius: 6px;
    margin-top: -24px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 15px 15px 15px;
    border-radius: 0 0 8px 8px;
    margin-top: -50px;
  }
  .soldDateCalendar{
    margin-top: 20px;
  }
  .customsLoader{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
  }
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: 8px;
      min-width: 16px;
    }
  }

  .cogsAndProfit {
    display: flex;
    gap: 20px;
    align-items: center;

    @media (min-width: 769px) and (max-width: 1440px) {
      gap: 16px;
    }
  }

  .accordionContent4 {
    .heading {
      margin-bottom: 5px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #393838;
      margin-top: 20px;
      @media (min-width: 769px) and (max-width: 1440px) {
        margin-bottom: 4px;
        font-size: 13px;
        margin-top: 16px;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }

    }
    .title {
      margin-top: 20px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #393838;
      display: flex;
      align-items: center;
      @media (min-width: 769px) and (max-width: 1440px) {
        margin-top: 16px;
        font-size: 16px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    .inputsItem {
      display: grid;
      gap: 0 15px;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 0 12px;
      }
      @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }
    .customMarketplacesContainer{
      .customMarketItems{
        display: flex;
        gap: 15px;
        margin-top: 10px;
        flex-wrap: wrap;
        @media (min-width: 769px) and (max-width: 1440px) {
          gap: 12px;
          margin-top: 8px;
        }
        .popoverContent{
          .marketButtonsContainer{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            button{
              display: flex;
              border-radius: 6px;
              height: 30px;
              padding: 10px 20px;
              justify-content: center;
              align-items: center;
              outline: none;
              border: none;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              color: #fff;
              background-color: #4db89e;
              min-width: 92px;
              &.cancelMarketModal{
                background-color: #ca1010;
              }
            }
          }
          input{
            border-radius: 8px;
            border: 1px solid #d9d9d9;
            width: 100%;
            resize: none;
            outline: none;
            padding: 10px;
          }
          .reachedLimit{
            color: red;
            margin-top: 4px;
            font-size: 16px;
            font-style: normal;
          }
          .headingMarket{
            margin-bottom: 4px;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            color: #393838;
          }
        }
        @media only screen and (max-width: 768px) {
          justify-content: center;
        }
        .marketItemAdd{
          border: 1px solid #4DB89E;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 140px;
          cursor: pointer;
          min-height: 110px;

          @media (min-width: 769px) and (max-width: 1440px) {
            border-radius: 6px;
            width: 112px;
            min-height: 112px;
          }
          @media only screen and (max-width: 768px) {
            width: 90px;
            min-height: 60px;
            svg{
              height: 30px;
              width: 30px;
            }
          }
        }
        .marketItem{
          padding: 15px 0 14px 0;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 140px;
          max-width: 140px;
          position: relative;
          cursor: pointer;
          @media (min-width: 769px) and (max-width: 1440px) {
            padding: 12px 0 11px 0;
            border-radius: 6px;
            min-width: 112px;
            max-width:112px;
          }
          svg{
            max-width: 55px;
            max-height: 55px;
          }
          @media only screen and (max-width: 768px) {
            min-width: 90px;
            width: 90px;
            padding: 10px;
            .checkIcon{
              height: 15px;
              width: 13px;
            }
            .storeIcon{
              height: 40px;
              width: 40px;
            }
            p{
              font-size: 13px !important;
              margin-top: 5px !important;

            }
          }
          .checkIcon{
            position: absolute;
            right: 10px;
            top: 10px;
          }
          &.activeMarket{
            background-color: #4db89e;
            border: 1px solid #4db89e;
            p{
              color: #fff;
            }
          }
          p{
            color: #393838;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-top: 10px;

            @media (min-width: 769px) and (max-width: 1440px) {
              font-size: 13px;
              margin-top: 8px;
            }
          }
        }
      }
    }
    .salesListingsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 19px;
      margin-top: 20px;
      justify-content: flex-start;

      @media (min-width: 769px) and (max-width: 1440px) {
        gap:15px;
        margin-top: 16px;
      }

      .salesItem {
        border: 1px solid #b6b6b6;
        cursor: pointer;
        padding: 15px;
        width: 100%;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        text-align: center;
        max-width: 140px;

        @media (min-width: 769px) and (max-width: 1440px) {
          padding: 12px;
          border-radius: 6px;
          max-width:112px;
        }
        img{
          max-width: 60px;
          max-height: 60px;

          @media (min-width: 769px) and (max-width: 1440px) {
            max-width: 48px;
            max-height: 48px  ;
          }
        }

        @media only screen and (max-width: 768px) {
          padding: 8px;
          max-width: 90px;
          gap: 4px;
          p {
            font-size: 14px !important;
          }
          img {
            height: 40px;
            width: 40px;
          }
        }
        @media only screen and (max-width: 374px) {
          max-width: 75px;
        }
        .checkIcon {
          position: absolute;
          right: 10px;
          top: 10px;
          @media only screen and (max-width: 768px) {
            height: 15px;
            width: 13px;
            right: 5px;
            top: 5px;
          }
        }
        &.selectedSalesItem {
          background-color: #4db89e;
          border: 1px solid #4db89e;

          p {
            color: #fff;
          }
        }
        p {
          color: #393838;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          @media (min-width: 769px) and (max-width: 1440px) {
            font-size: 13px;
          }
        }
      }
      @media only screen and (max-width: 768px) {
        gap: 10px;
      }
    }
    .amount {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: #393838;
      margin-top: 12px;

      @media (min-width: 769px) and (max-width: 1440px) {
        font-size: 13px;
        margin-top: 10px;
      }
    }
    .inputsContainerDown {
      .inputsItem {
        display: flex;
        gap: 30px;
        @media (min-width: 769px) and (max-width: 1440px) {
          gap: 24px;
        }
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
        }
        .heading {
          margin-bottom: 4px;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #393838;
          @media (min-width: 769px) and (max-width: 1440px) {
            margin-bottom: 3px;
            font-size: 13px;
          }
        }
      }
    }
  }
}