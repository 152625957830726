@import '@assets/styles/variables.scss';

.iframeContainer {
    position: fixed;
    z-index: 9993;
    right: 0;
    top: 0;


    .loaderIframe {
        background: white;
        width: 80vw;
        border: 1px solid grey;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 768px) {
            width: 100vw !important;
            /* New width for smaller screens */
        }

        .loaderAnimation {
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #3498db;
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite;
            /* Safari */
            animation: spin 2s linear infinite;

            /* Safari */
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                }
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

    .closeBackground {
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #ccc;

        position: absolute;
        align-items: center;
        height: 40px;

        .closeIcon {
            width: 29px;
            cursor: pointer;
            margin-right: 5px;
            margin-top: 5px;
            position: absolute;
            right: 4px;
        }

        .assistantButton {
            background-color: #234165;
            color: white;
            border: none;
            border-radius: 8px;
            padding: 5px 10px;
        }
    }
}

.popoverContent {
    z-index: 9991;
}

.bulkBtnDisable {
    background-color: #b4b4b3;
    padding: 8px 15px;
}

.marketplacesBtn {
	.marketplacesDot{
        height: 10px;
        width: 10px;
        border-radius: 100%;
        &.red{
            background-color: #FF4D4D;
        }
        &.green{
            background-color: #21BA45;
        }
    }
    &.marketplacesBtnDisabled{
        .marketplacesDot{
            &.red{
                background-color: #fff;
            }
            &.green{
                background-color: #fff;
            }
        }
    }
}

.errorTooltip {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    .closeErrorTooltip {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        width: 15px;
    }

    .errorSubtitle {
        color: var(--Dark, #393838);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
    }

    .errorTitle {
        color: var(--Light-Red, #FF4D4D);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
    }
}

.noResultsContainer {
    border-bottom: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    margin: 0 20px;

    &.noResultsContainerGridView {
        border-top: 1px solid #d9d9d9;
    }

    p {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }

    @media only screen and (max-width: 768px) {
        border-top: 1px solid #d9d9d9;
    }
}

.scrollHeader {
    .paginateBox {
        gap: 20px !important;
    }

    .popoverContentDisabledActions {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-weight: bold;
            font-size: 15px;
        }

        .desc {
            font-size: 15px;
        }
    }

    .switchToListView {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #1e3166;
        border-radius: 8px;
        height: 40px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-top: 6px;
        cursor: pointer;
        padding: 10px;
        transition: $transition;

        &:hover {
            opacity: $globalOpacity;
        }
    }

    .switchToListViewSimple {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #b4b4b3;
        border-radius: 8px;
        height: 40px;
        width: 204px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-top: 6px;
        cursor: pointer;
    }
}

.heading {
    display: flex !important;
    gap: 10px !important;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background: #f8f8f8;
    z-index: 9991;
    height: 100%;
    padding: 10px 0;

	@media only screen and (max-width: 768px) {
        z-index: 1;
    }
    .headerLeftContainer{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .switchToListView {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #1e3166;
        border-radius: 8px;
        height: 40px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-top: 6px;
        cursor: pointer;
        padding: 10px;
    }

    .switchToListViewSimple {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #b4b4b3;
        border-radius: 8px;
        height: 40px;
        width: 204px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-top: 6px;
        cursor: pointer;
    }

    .popoverContentDisabledActions {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            font-weight: bold;
            font-size: 15px;
        }

        .desc {
            font-size: 15px;
        }
    }

    @media only screen and (max-width: 768px) {
        position: relative !important;
        width: 100%;
        height: auto;
        padding: 20px !important;
    }

    .hideTitle {
        display: none;
    }

    .catalogTitle {
        font-size: 30px;
        font-weight: 600;
        user-select: none;

        @media only screen and (max-width: 768px) {
            font-size: 24px;
        }
    }
}

.tableFilterLine {
    .searchBox {
        display: flex !important;
        gap: 15px !important;
        flex-wrap: wrap;
    }

    .paginateBox {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}
.ConditionsSelectContainer{
    display: flex;
    gap: 10px;
    align-items: center;
}
.viewAllCheckers {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
        padding: 0 20px;
        margin-bottom: 5px;
        flex-wrap: wrap;
    }

    label {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
        user-select: none;
        white-space: nowrap;
        cursor: pointer;
        transition: $transition;

        &:hover {
            opacity: $globalOpacity;
        }

        input {
            width: 20px;
            height: 30px;
            cursor: pointer;
        }

    }
}

.bulkBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

	.HeaderBulkIcons {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin-right: 10px;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
        }

        .csvIndicator {
            font-family: $fontFamilyOpenSans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            color: #000;
        }


        .statusContainer {
            display: none;

            svg {
                height: 38px !important;
                width: 38px !important;
                cursor: pointer;
                margin-top: 5px;
            }
        }
    }

    .saveAndCancel {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
}


.btns__wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;

    margin-bottom: 15px !important;

    div {
        margin: 5px;
        transition: $transition;

        a {
            width: 180px !important;
            height: 40px !important;
            border-radius: 8px !important;
            font-size: 16px !important;
            color: #ffffff !important;
            background-color: $mint !important;
            font-family: 'Open Sans', sans-serif !important;
            cursor: pointer !important;
            text-align: center !important;
            user-select: none !important;
            display: inline-flex !important;
            justify-content: center !important;
            align-items: center !important;
            box-shadow: none !important;
        }
    }

    .tableUpBlock {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        background-color: #f8f8f8;
    }
}

.tableUpBlock {
    align-items: flex-start;
    gap: 10px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 60px;
    z-index: 999;
    height: 100%;
    flex-direction: column;

    .rightTableBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;

        .tableItems {
            display: flex;
            gap: 10px;
            align-items: flex-start;
            justify-content: space-between;

            .total {
                font-family: $fontFamilyOpenSans;
                font-size: 17px;
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .perPageSelectBlock {
                display: flex;
                align-items: center;
                gap: 8px;
				span {
                    &:first-child {
                        text-wrap: nowrap;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.selectApplyButton {
    font-family: $fontFamilyOpenSans !important;
    padding: 10px 15px !important;
    font-size: 16px !important;
    border: none !important;
    border-radius: $borderRadius8 !important;
    color: $textColor !important;
    background-color: $darkBlue;
    margin: 0 !important;

    &:hover {
        background: rgba(30, 49, 102, 0.8) !important;
    }
}

.noResult {
    text-align: center;
}

.line {
    margin-top: 6px;
    width: 100%;
    height: 0px;
    border: 1px solid $darkBlue;
}

.tableBottom__paginate {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    &.paginateWhenLoading {
        margin-top: 110px;
    }

    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.tabs {
    align-items: center;
    display: flex;
    font-family: Open Sans, sans-serif !important;
    gap: 15px;
    border-bottom: 3px solid #1e3166;


    @media only screen and (max-width: 1148px) {
        max-width: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
            height: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888888;

            &:hover {
                background-color: #555555;
            }
        }

        &::-webkit-scrollbar-track {
            background-color: #dddddd;
        }
    }

    li {
        display: flex;
        gap: 5px;
        cursor: pointer;
        flex-direction: column;
        align-items: center;
        background-color: $tabBgColor2;
        padding: 10px 15px;
        border-radius: 4px;
        transition: $transition;


        &:hover {
            opacity: $globalOpacity
        }

        span {
            font-family: $fontFamilyOpenSans;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            text-transform: capitalize;
            text-wrap: nowrap;
            color: $dark;
            display: flex;
            align-items: center;

            .tabListingCounSpan {
                color: rgba(30, 49, 102, 0.5);
            }
        }

        .activeTabSpan {
            color: #fff !important;

            svg {
                color: inherit;
            }
        }
    }

    .activeTab {
        padding: 9px 15px 10px 15px;
        border-radius: 8px 8px 0px 0px;
        border-top: 3px solid $darkBlue;
        border-right: 3px solid $darkBlue;
        border-left: 3px solid $darkBlue;
        background: $darkBlue;
        transition: $transition;

        &:hover {
            opacity: $globalOpacity;
        }
    }

    .removeCapitalize {
        text-transform: initial !important;
    }

    .activeLi {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        text-transform: capitalize;
        color: $darkBlue;
    }
}

.gridViewBtns {
    display: flex;
    gap: 4px;

    .activeIcon {
        transition: $transition;

        &:hover {
            opacity: $globalOpacity
        }
        rect {
            fill: rgb(30, 49, 102);
        }

        path {
            fill: #fff;
        }
    }

    .inactiveIcon {
        transition: $transition;

        &:hover {
            opacity:$globalOpacity;
        }
        rect {
            fill: #fff;
        }

        path {
            fill: rgb(30, 49, 102);
        }
    }

    svg {
        cursor: pointer;
    }

    .inactiveIconDisable {
        border: none;

        rect {
            stroke: #b4b4b3;;
            fill: #b4b4b3;
        }

    }
}

.catalogPage__wrapper {
    position: relative;
    height: 100%;
}

.viewIssuesBtn {
    background: var(--Light-Red, #FFDADA);
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 32px;

    &:disabled {
        opacity: .7;
        cursor: not-allowed !important;
    }

    @media (min-width: 769px) and (max-width: 1440px) {
        padding: 8px;
        gap: 8px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        height: 44px;
    }

    span {
        font-size: 14px;
        font-weight: 600;
        color: var(--Red, #FF4D4D);

        @media (min-width: 769px) and (max-width: 1440px) {
            font-size: 11px;
        }
    }

    svg {
        width: 14px;
        height: 14px;

        @media (min-width: 769px) and (max-width: 1440px) {
            width: 11px;
            height: 11px;
        }
    }
}

.viewIssuesBtnMobile {
    border-radius: 8px;
    background: var(--Light-Red, #FFDADA);
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 10px 0;
    width: 100%;

    &:disabled {
        opacity: .7;
        cursor: not-allowed !important;
    }

    svg {
        width: 16px;
        height: 16px;
    }

    span {
        color: var(--Red, #FF4D4D);
        font-size: 16px;
    }
}

@media (min-width: 769px) and (max-width: 1440px) {

    .heading {
        gap:8px !important;
        padding: 8px 0;

        .catalogTitle {
            font-size: 24px;
        }
    }

    .bulkBlock {
        gap:8px;

        .bulkBtnDisable {
            padding: 6px 12px !important;
        }

    }

    .HeaderBulkIcons {
        gap:8px !important;
        margin-right: 8px !important;

        .settings,.csvDisabled {
            padding: 8px !important;
            border-radius: 6px !important;
            width: 32px !important;
            height: 32px !important;
        }

        .statusContainer {
            svg {
                width: 32px !important;
                height: 32px !important;
            }
        }
    }

    .tableFilterLine {
        .searchBox {
            gap:12px !important;

            input[type="text"] {
                width: 257px;
                padding: 0 8px !important;
                border-radius: 3px !important;
                height: 32px !important;
                font-size: 13px !important;
            }

            .viewAllCheckers {
                gap:8px !important;

                label {
                    font-size: 13px;
                    gap:4px;

                    input {
                        width: 20px !important;
                        height: 20px !important;
                        border-radius:6px;

                        &:after {
                            left: 7px;
                            top: 3px;
                            width: 4px;
                            height: 8px;
                            //border-width: 0 1px 1px 0;
                        }
                    }

                }
            }

        }
    }

    .tabs {
        gap:12px;

        li {
            gap:4px;
            padding: 8px 12px !important;
            border-radius: 3px;

            span {
                font-size: 13px;
                line-height: 18px;

                svg {
                    width: 16px;
                    height: 16px;
                }

            }
        }

        .activeTab {
            padding: 7px 12px 8px 12px;
            border-radius: 6px 6px 0 0;
            border-top: 2px solid #1e3166;
            border-right: 2px solid #1e3166;
            border-left: 2px solid #1e3166;
        }

        .activeLi {
            font-size: 13px;
        }
    }

    .tableUpBlock {
        gap:8px ;
        margin-bottom: 6px ;
        top: 48px;
        .rightTableBlock {
            margin-bottom: 8px;
            .tableItems {
                gap: 8px;

                .perPageSelectBlock {
                    gap:6px;

                    span {
                        &:first-child {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    .gridViewBtns {
        gap:3px;

        svg {
            width: 32px;
            height: 32px;
        }
    }

    .saveAndCancel {
        gap: 4px !important;
    }
}