@import '../../../assets/styles/variables.scss';

.wrapper {
  font-family: $fontFamilyOpenSans;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 769px) and (max-width: 1440px) {
    height: 40vh !important;
  }

  .loader {
    display: flex;
    flex-direction: column;
    gap: 2px;

    @media (min-width: 769px) and (max-width: 1440px) {
      zoom: 0.8;
    }


    p {
      font-size: 17px;
    }
  }
}
