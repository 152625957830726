@import "@assets/styles/variables.scss" ;

.titleBlock {
	border-radius: 8px;
	padding: 12px 20px 12px 22px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	user-select: none;
	margin-bottom: 30px;
	z-index: 5;
	position: relative;
	transition: $transition;

	@media (min-width: 769px) and (max-width: 1440px) {
		padding: 10px 16px 10px 18px;
		margin-bottom: 24px;
	}
	&.hoverEffect {
		transition: $transition;

		&:hover {
			opacity: $globalOpacity;
		}
	}
	h3 {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		@media (min-width: 769px) and (max-width: 1440px) {
			font-size: 16px;
		}

	}

	span {
		svg {
			width: 32px;
			height: 32px;
		}
	}

}

.hoverEffect:hover {
	opacity:$globalOpacity;
}

.mobileExtensionSettings{
	pointer-events: none;
	background-color: #EDEDED !important;
	color: #393838 !important;
	p{
		font-size: 14px;
		font-style: italic;
		font-weight: 400;
		line-height: normal;
	}
	svg{
		circle{
			fill: #B6B6B6;
		}
	}
}