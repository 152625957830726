.TitlesContainer {
	margin-top: 15px;

	@media (min-width: 769px) and (max-width: 1440px) {
		margin-top: 12px;
	}

	.popoverIcon {
		cursor: pointer;
		margin-left: 10px;
		min-width: 20px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-left: 8px;
			min-width: 16px;
		}
	}
	@media only screen and (max-width: 340px) {
		.titlesMain {
			.buttonsContainer {
				flex-direction: column;
			}
		}
	}
	@media only screen and (max-width: 768px) {
		margin-top: 22px;
		.titlesMain {
			grid-template-columns: 100%;
			gap: 10px;
			.titlesButton {
				max-width: 100% !important;
			}
		}
	}
}
.suggestTitlesContainer{
	margin-top: 10px;
	.textareaContainer{
		max-width: 500px;
	}
	.heading{
		margin-bottom: 10px;
		font-weight: 600;
		font-size: 16px;
		color: #333;
	}
}
.showAllTitlesContainer {
	.showAllTitlesItem {
		margin-top: 10px;
		.heading {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #393838;
			flex: 1;
			white-space: nowrap;
			margin-top: -30px;

			@media (min-width: 769px) and (max-width: 1440px) {
				font-size: 13px;
				margin-top: -24px;
			}
		}

		.itemInner {
			display: flex;
			gap: 10px;
			align-items: center;

			@media only screen and (max-width: 768px) {
				flex-direction: column-reverse;
				margin-top: 30px;
				justify-content: flex-start;
				align-items: flex-start;
			}
		}

		.charactersQuantity {
			color: #696969;
			font-size: 14px;
			font-style: italic;
			font-weight: 400;
			line-height: normal;
			margin-top: 8px;
		}

		input {
			border-radius: 8px;
			border: 1px solid #d9d9d9;
			height: 40px;
			width: 100%;
			padding: 15px;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			flex: 3;
		}
	}

	@media only screen and (max-width: 768px) {
		margin-left: 0;
		margin-right: 0;
		margin-top: 40px;
		.heading {
			font-size: 14px !important;
			margin-top: -36px;
		}
	}
}
.titlesMain {
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: flex-start;
	column-gap: 20px;

	@media (min-width: 769px) and (max-width: 1440px) {
		column-gap: 16px;
	}

	.selectionTitle {
		width: 100%;
		border-radius: 8px;
		border: 1px solid #d9d9d9;
		height: 40px;
		display: flex;
		align-items: center;
		padding: 15px;
	}
	.charactersQuantity {
		color: #696969;
		font-size: 14px;
		font-style: italic;
		font-weight: 400;
		line-height: normal;
		margin-top: 8px;
	}
	.buttonsContainer {
		display: flex;
		width: 100%;
		gap: 15px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 12px;
		}
		.titlesButtonReset{
			border-radius: 8px;
			cursor: pointer;
			width: fit-content;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			padding: 9px;
			white-space: nowrap;
			background-color: #4db89e;
			color: #fff;
			border: 1px solid #4db89e;
		}
		.titlesButton {
			border-radius: 8px;
			border: 1px solid #1e3166;
			cursor: pointer;
			width: 100%;
			max-width: 150px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: #1e3166;
			background: #fff;
			padding: 9px;
			white-space: nowrap;
			&.btnActive {
				background-color: #4db89e;
				color: #fff;
				border: 1px solid #4db89e;
			}
		}
	}
}
.title {
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	color: #393838;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	gap: 5px;

	@media (min-width: 769px) and (max-width: 1440px) {
		font-size: 16px;
		margin-bottom: 12px;
		gap: 4px;
	}
	@media only screen and (max-width: 768px) {
		font-size: 16px;
		margin-bottom: 10px;
	}
}
.showSuggestTitlesContainer {
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 20px;

	.heading {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		color: #393838;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 768px) {
		margin-left: 0;
		margin-right: 0;
		.heading {
			font-size: 14px;
		}
	}
}