#editMainContainer .tippy-box{
    background-color: transparent;
    color: #333;
    border: none;
}
#editMainContainer .popover{
    max-width: 300px !important;

    @media (min-width: 769px) and (max-width: 1440px) {
        max-width: 240px !important;
    }
}