@import "@assets/styles/variables.scss" ;

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 768px){
    justify-content: flex-start;
  }


}
.calendarIcon{
  path{
    fill: rgb(182, 182, 182);
  }
  &.calendarIconActive{

    path{
      fill: #4DB89E;
    }
  }
}
.inputStyles {
  padding: 8px 15px;
  border: 1px solid #d5d5d5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  min-height: 38px;
  flex: 2;
  width: 100%;
  min-width: 150px;
  &::placeholder {
    color: #b6b6b6;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (min-width: 769px) and (max-width: 1440px) {
      font-size: 13px;
    }
  }
  @media (min-width: 769px) and (max-width: 1440px) {
    padding: 6px 12px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    min-height: 32px;
    flex:1;
    min-width: 120px;

  }
}
.platformContainerIcon{
  padding: 0 15px;
  border: 1px solid #d5d5d5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  svg{
    fill: #1e3166;
  }
}
.PlusSolid {
  background-color: #d7e2ff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: $transition;

  &:hover {
    opacity: $globalOpacity;
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    border-radius: 40%;
    height: 32px !important;
    width: 32px !important;
    min-width: 32px !important;
    margin-left: 8px;

    svg {
      width: 14px;
      height: 16px;
    }
  }

  .minus {
    height: 3px;
    width: 15px;
    border-radius: 8px;
    background-color: rgb(30, 49, 102);

    @media (min-width: 769px) and (max-width: 1440px) {
      height: 2px;
      width: 12px;
      border-radius: 6px;
    }
  }
}
.rangesModal{
  .rangesCalendar{
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .rangesInput{
    border-radius: 8px;
    border: 1px solid  #4DB89E;
    background: #fff;
    height: 50px;
    outline: none;
    padding:14px 10px;
    color: #393838;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rangesheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
    p{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .closeRangesModal{
    svg{
      font-size: 24px;
      cursor: pointer;
      path{
        fill:#B6B6B6;
      }
    }
  }
}