.shortDescriptionContainer{
  .popoverIcon {
    cursor: pointer;
    margin-left: 10px;
    min-width: 20px;

    @media (min-width: 769px) and (max-width: 1440px) {
      margin-left: 8px;
      min-width: 16px;
    }
  }
  .quotasLoader{
    margin-right: 10px;
  }
  .brandInfoForm {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 30px;
    @media (min-width: 769px) and (max-width: 1440px) {
      margin-top: 24px;
      gap: 24px;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
    .leftSide {
      width: 100%;
      @media only screen and (max-width: 768px) {
        .charactersQuantity {
          display: none !important;
        }
      }

      .headerBrandInfo {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        flex-wrap: wrap;
        gap: 20px;

        @media (min-width: 769px) and (max-width: 1440px) {
          margin-bottom: 10px;
          gap: 16px;
        }

        .title {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: #393838;
          display: flex;
          align-items: center;
          @media (min-width: 769px) and (max-width: 1440px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        .suggestDescription {
          border-radius: 8px;
          background-color: #f2711c;
          color: #fff;
          cursor: pointer;
          width: 100%;
          max-width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 9px 20px;
          min-width: 227px;
          display: flex;
          align-items: center;

          .suggestContainer {
            display: flex;
            align-items: center;
            align-items: center;
          }

          .suggestIcon {
            margin-right: 10px;
          }

          .loader {
            .loaderAnimation {
              border: 16px solid #f3f3f3;
              border-radius: 50%;
              border-top: 16px solid #3498db;
              width: 120px;
              height: 120px;
              -webkit-animation: spin 2s linear infinite;
              /* Safari */
              animation: spin 2s linear infinite;

              /* Safari */
              @-webkit-keyframes spin {
                0% {
                  -webkit-transform: rotate(0deg);
                }

                100% {
                  -webkit-transform: rotate(360deg);
                }
              }

              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }

                100% {
                  transform: rotate(360deg);
                }
              }
            }
          }
        }
      }
    }
    .rightSide {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 769px) and (max-width: 1440px) {
        gap: 8px;
      }
      .title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #393838;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        @media (min-width: 769px) and (max-width: 1440px) {
          font-size: 16px;
          margin-bottom: 4px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      grid-template-columns: 100%;
      gap: 15px;
      .headerBrandInfo {
        gap: 10px !important;
      }
      .suggestDescription {
        height: 40px;
      }
    }
  }
}