.createdRange .react-datepicker-wrapper input{
	width: 100%;
	background-color: #fff !important;
	border: 1px solid #d9d9d9 !important;
	color: #393838;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.createdRange .react-datepicker__calendar {
	z-index: 999;
}

.createdRange .react-datepicker__input-container input {
	white-space: pre-line;
}
.createdRange .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
	color: #fff !important;
}


#createdTippy .tippy-box{
	width: 400px !important;
	max-width: 400px !important;
	border-radius: 8px;
	border: 1px solid  #4DB89E;
	background: #FFF;

	@media (min-width: 769px) and (max-width: 1440px) {
		/*width: 320px !important;*/
		/*max-width: 320px !important;*/
		/*border-radius: 6px;*/
		/*font-size: 11px;*/
		/*line-height: 1.1;*/

		zoom: 0.8;


	}
}
@media only screen and (max-width: 768px){
	#createdTippy .tippy-box{
		width: 300px !important;
		max-width: 300px !important;
	}
}

#createdTippy .tippy-content{
	width: 100% !important;
	padding:15px;

	/*@media (min-width: 769px) and (max-width: 1440px) {*/
	/*	padding: 12px;*/
	/*}*/
}
.createdRange .rangesModal{
	width: 100%;
}
.createdRange{
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
	position: relative;

	/*@media (min-width: 769px) and (max-width: 1440px) {*/
	/*	gap: 4px;*/
	/*}*/
}
.createdRange .react-datepicker__month-container{
	width: 100%;
}
.createdRange .date-picker-container{
	width: 100%;
}
.createdRange .react-datepicker-wrapper{
	width: 100%;
}
.createdRange .react-datepicker-popper{
	width: 100%;
	position: relative !important;
	transform: none !important;
}
.createdRange .react-datepicker-popper > div {
	width: 100%;
}
.createdRange .react-datepicker {
	width: 100%;
}
.createdRange .react-datepicker__triangle{
	display: none;
}

.createdRange .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
	background-color: #4DB89E;
	color: #000;
	outline: none !important;
	border: none !important;
}
.createdRange .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
	background-color: #D0F4EB;
	color: #000;
}
.createdRange .react-datepicker__day--selected:hover,
.createdRange .react-datepicker__day--in-selecting-range:hover,
.createdRange .react-datepicker__day--in-range:hover,
.createdRange .react-datepicker__month-text--selected:hover,
.createdRange .react-datepicker__month-text--in-selecting-range:hover,
.createdRange .react-datepicker__month-text--in-range:hover,
.createdRange .react-datepicker__quarter-text--selected:hover,
.createdRange .react-datepicker__quarter-text--in-selecting-range:hover,
.createdRange .react-datepicker__quarter-text--in-range:hover,
.createdRange .react-datepicker__year-text--selected:hover,
.createdRange .react-datepicker__year-text--in-selecting-range:hover,
.createdRange .react-datepicker__year-text--in-range:hover {
	background-color: #82e8d8;
	color: #000;
}
.createdRange .react-datepicker__day--selected {
	background-color: #4DB89E;
	color: #fff;
}
.createdRange .react-datepicker__header{
	background-color: #fff;
	border-bottom: 1px solid #fff;
}
.createdRange .react-datepicker__day-names{
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-left: 2px;
	padding-right: 5px;

	/*@media (min-width: 769px) and (max-width: 1440px) {*/
	/*	padding-left: 1px;*/
	/*	padding-right: 4px;*/
	/*}*/
}
.createdRange .react-datepicker__week{
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-left: 2px;
	padding-right: 5px;

	/*@media (min-width: 769px) and (max-width: 1440px) {*/
	/*	padding-left: 1px;*/
	/*	padding-right: 4px;*/
	/*}*/
}
.createdRange .react-datepicker__navigation--previous{
	right: 20px;
	left: auto;

	/*@media (min-width: 769px) and (max-width: 1440px) {*/
	/*	right: 16px;*/
	/*}*/
}
.createdRange .react-datepicker__header__dropdown,.react-datepicker__header__dropdown--scroll{
	display: flex;
	width: 100%;
	justify-content: flex-start;
	margin-left: -5px;

	/*@media (min-width: 769px) and (max-width: 1440px) {*/
	/*	margin-left: -4px;*/
	/*}*/
}
.createdRange .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
	display: flex;
	width: 100%;
	justify-content: flex-start;
	margin-left: 10px;
	margin-bottom: 10px;

	/*@media (min-width: 769px) and (max-width: 1440px) {*/
	/*	margin-left: 8px;*/
	/*	margin-bottom: 8px;*/
	/*	font-size: 0.7552rem;*/
	/*}*/
}
.createdRange .react-datepicker__day--in-selecting-range {
	background-color: #D0F4EB;
	color: #000;
}