.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	background: rgba(43, 43, 43, 0.5)
}


.modal {
	width: 600px;
	border-radius: 8px;
	background: var(--White, #FFF);

	@media (max-width: 768px) {
		width: 90%;
	}

	&__closeButton {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}

	&__header {
		padding: 20px 20px 10px 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 768px) {
			padding: 16px 15px 10px 15px;
		}

		&-left {
			display: flex;
			gap: 10px;
			align-items: center;
		}

		&-title {
			font-size: 16px;
		}
	}

	&__text {
		font-size: 14px;
		padding: 0 0 15px 20px;

		@media (max-width: 768px) {
			font-size: 12px;
			padding: 0 0 15px 15px;
		}
	}

	&__line {
		width: 100%;
		height: 1px;
		background: #D9D9D9;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap:15px;
		padding: 15px 20px 27px 20px;

		@media (max-width: 768px) {
			padding: 15px;

		}
	}

	&__item {
		display: flex;
		justify-content: space-between;

		&-left {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		&-count {
			background: #FFF7DF;
			min-width: 32px;
			min-height: 32px;
			border-radius: 50%;
			display: flex;
			font-size: 11px;
			justify-content: center;
			align-items: center;
			color: #FFB800;
			font-weight: 700;

			&--red {
				background: #FFEBEB !important;
				color: var(--Red, #FF4D4D);
			}

			&--notActive {
				background: #F8F8F8;
				color:#767676;
			}
		}

		&-text {
			font-size: 14px;
			color: var(--Dark, #2B2B2B);
			font-weight: 600;

			@media (max-width: 768px) {
				font-size: 13px;
			}
		}

		&-questionIcon {
			cursor: pointer;
			min-width:  20px;
			min-height: 20px;
			width: 20px;
			height: 20px;

			@media (max-width: 768px) {
				min-width:  14px;
				min-height: 14px;
				width: 14px;
				height: 14px;

			}
		}

	}
}

