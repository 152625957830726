.intendedMenu {
	margin-top: 10px;
	margin-bottom: 20px;

	@media (min-width: 769px) and (max-width: 1440px) {
		margin-top: 8px;
		margin-bottom: 16px;
	}

	.popoverIcon {
		cursor: pointer;
		margin-left: 10px;
		min-width: 20px;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-left: 8px;
			min-width: 16px;
		}
	}
	.conditionMenu {
		display: flex;
		gap: 30px;
		align-items: flex-start;
		input {
			border-radius: 8px;
			border: 1px solid #d9d9d9;
			height: 40px;
			width: 100%;
			padding: 15px;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		@media only screen and (max-width: 768px) {
			flex-direction: column;
			gap: 15px;
		}
	}
	.menuItems {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		@media (min-width: 769px) and (max-width: 1440px) {
			gap: 8px;
		}
		&.menuItemsConditions {
			flex-wrap: nowrap;
		}
	}
	.title {
		margin-bottom: 9px;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		color: #393838;
		display: flex;
		align-items: center;

		@media (min-width: 769px) and (max-width: 1440px) {
			margin-bottom: 7px;
			font-size: 16px;
		}
		@media only screen and (max-width: 768px) {
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
	.item {
		border-radius: 8px;
		background: #d7e2ff;
		color: #1e3166;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		padding: 9px 15px;
		cursor: pointer;
		display: flex;
		width: fit-content;
		white-space: nowrap;

		@media (min-width: 769px) and (max-width: 1440px) {
			border-radius: 6px;
			font-size: 13px;
			padding: 7px 12px;
		}
	}
	.itemActive {
		background-color: #1e3166;
		color: #fff;
		border-radius: 8px;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		padding: 9px 15px;
		cursor: pointer;
		display: flex;
		width: fit-content;
		white-space: nowrap;

		@media (min-width: 769px) and (max-width: 1440px) {
			border-radius: 6px;
			font-size: 13px;
			padding: 7px 12px;
		}
	}
}