.css-1446tz2-control {
  border-radius: 4px !important;
  color: #1e3166 !important;
  text-align: left !important;
  border-color: #1e3166 !important;
}

.css-b62m3t-container {
  color: #1e3166 !important;
  text-align: left !important;
  border-color: #1e3166 !important;
}

.css-1dimb5e-singleValue {
  color: #1e3166 !important;
}

.css-1xc3v61-indicatorContainer {
  color: #1e3166 !important;
}

.css-1xc3v61-indicatorContainer svg {
  color: #1e3166 !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}
