.videoElement{
  width: 100%;
  height: auto;
  align-items: center;
  video{
    height: 100%;
    width: 100%;
  }
  canvas{
    display: none;
  }
}