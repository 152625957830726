@import "@assets/styles/variables.scss" ;
.quickLinks {
	font-family: "Open Sans", sans-serif;
	padding-top: 90px;

	.quickLinksWrapper {

		display: grid;
		grid-template-columns: repeat(4, 200px);
		gap: 20px;

		.quickLinkItem {
			background: #EAF0FF;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 15px;
			padding: 15px 0;
			border-radius: 8px;
			cursor:pointer;
			transition:$transition;

			&:active, &:hover {
				background: #1E3166;

				.title {
					color: #FFF;
				}

				.menuLinks {

					svg {
						fill: #FFFFFF;

						g {
							path {
								fill: #fff;
							}
						}

						path {
							fill: #fff;
						}
					}
				}
			}


			.title {
				color: #1E3166;
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}

			span {
				svg {
					width: 40px;
					height: 40px;
					fill: #1E3166;
				}
			}
		}

	}
}


@media screen and (max-width: 1150px) {

	.quickLinks {
		padding-top: 75px;
	}

	.quickLinks .quickLinksWrapper {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 1000px) {

	.quickLinks {
		padding-top: 65px;
	}


	.quickLinks .quickLinksWrapper {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 767px) {
	.quickLinks {
		padding-top: 45px;
	}


	.quickLinks .quickLinksWrapper {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: 769px) and (max-width: 1440px) {
	.quickLinks {
		padding-top: 72px;

		.quickLinksWrapper {
			gap: 16px;
			grid-template-columns: repeat(4, 160px);

			.quickLinkItem {
				gap: 12px;
				padding: 12px 0;
				border-radius: 6px;

				.title {
					font-size: 13px;
				}

				span {
					svg {
						width: 32px;
						height: 32px;
					}
				}
			}
		}
	}
}


