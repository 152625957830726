@import '@assets/styles/variables.scss';

.platformsInput {
  display: flex;
  min-width: 100px;
  width: 100%;

  .platformsContainer {
    width: 100%;
    min-width: 70px;
    height: 39px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;
    float: right;
    clear: both;
    position: relative;
    background-color: #fff;
    transition:$transition;

    &:hover {
      opacity: $globalOpacity;
    }

    @media (min-width: 769px) and (max-width: 1440px) {
      min-width: 56px ;
      height: 32px;
      border-radius: 3px;
      padding: 0 8px;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 10px;
      cursor: pointer;
      user-select: none;

      .titleIcon {
        width: 21px;
        height: 21px;
        min-width: 21px;
        min-height: 21px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(15%) sepia(57%) saturate(2207%) hue-rotate(209deg)
          brightness(82%) contrast(88%);

        @media (min-width: 769px) and (max-width: 1440px) {
          width: 17px;
          height: 17px;
          min-width: 17px;
          min-height: 17px;
        }
      }

      .title {
        flex-grow: 1;
        font-weight: 400;
        font-size: 14px;
        color: #000;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 2px;
        text-align: left;

        @media (min-width: 769px) and (max-width: 1440px) {
          font-size: 11px;
          padding-right: 8px;
        }
      }

      .downUpIcon {
        width: 14px;
        height: 14px;
        min-height: 14px;
        min-width: 14px;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(15%) sepia(57%) saturate(2207%) hue-rotate(209deg)
          brightness(82%) contrast(88%);

        @media (min-width: 769px) and (max-width: 1440px) {
          width: 11px;
          height: 11px;
          min-height: 11px;
          min-width: 11px;
        }
      }
    }
  }
}
.calendarIcon{
  width: 59px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #B6B6B6;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;

  &:hover {
    opacity: $globalOpacity;
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    width: 48px;
    height: 32px;
    border-radius: 4px;

    svg  {
      width: 23px !important;
      height: 18px !important;
    }
  }
  path{
    fill: rgb(182, 182, 182);
  }
  &.calendarIconActive{

    path{
      fill: #4DB89E;
    }
  }
}
.rangesModal{
  .rangesCalendar{
    position: absolute;
    top: 10px;
    left: 10px;

    @media (min-width: 769px) and (max-width: 1440px) {
      top: 5px;
    }
  }
  .rangesInput{
    border-radius: 8px;
    border: 1px solid  #4DB89E;
    background: #fff;
    height: 50px;
    outline: none;
    padding:14px 10px;
    color: #393838;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    //@media (min-width: 769px) and (max-width: 1440px) {
    //  border-radius: 6px;
    //  height: 40px;
    //  padding: 11px 8px;
    //  font-size: 13px;
    //}
  }
  .rangesheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;

    //@media (min-width: 769px) and (max-width: 1440px) {
    //  margin-bottom: 10px;
    //}
    p{
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      //@media (min-width: 769px) and (max-width: 1440px) {
      //  font-size: 13px;
      //}
    }
  }
  .closeRangesModal{
    svg{
      font-size: 24px;
      cursor: pointer;
      //@media (min-width: 769px) and (max-width: 1440px) {
      //  font-size: 19px;
      //  width: 16px;
      //  height: 16px;
      //}
      path{
        fill:#B6B6B6;
      }
    }
  }
}