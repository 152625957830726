#descModal .tagify {
    max-height: 200px;
    overflow-y:auto;
    width: 100%;
    padding: 20px 15px;
    background-color: #fff;
    border-radius: 8px;
}

#descModal .tagify__tag {
    cursor: grab !important;
}

#descModal .custom-red-background-class {
    background-color: red;
    /* Add any other styling you need */
}

#descModal .limitedKeyword {
    border: 1px solid #D80032;
}

#descModal .limited .tagify__tag-text{
    color: #D80032;
    font-weight: 500;
}