.resultsFor {
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	.wrapper {
		display: flex;
		align-items: center;

		.tbDataItem {
			display: flex;
			align-items: center;
			gap: 12px;
			padding: 4px 15px;
			background: #f3f3f3;
			border-radius: 8px;

			@media (min-width: 769px) and (max-width: 1440px) {
				gap: 10px;
				padding: 3px 12px;
			}

			svg {
				cursor: pointer;
			}
		}
	}

	.clearAllButton {
		background-color: #D7E2FF;
		border-radius: 8px;
		cursor: pointer;
		padding: 4px 15px;
		border:none;
		text-transform: capitalize;
		white-space: nowrap;
		font-size: 17px;
		color: #1e3166;

		@media (min-width: 769px) and (max-width: 1440px) {
			padding: 3px 12px;
			font-size: 14px;
		}

	}
}