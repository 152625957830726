@import '../../../../../../assets/styles/variables.scss';

.platformsInput {
  display: flex;
  min-width: 100px;
  width: 100%;

  .platformsContainer {
    width: 100%;
    min-width: 70px;
    height: 39px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;
    float: right;
    clear: both;
    position: relative;
    transition: $transition;

    &:hover {
      opacity: $globalOpacity;
    }

    @media (min-width: 769px) and (max-width: 1440px) {
      min-width: 56px;
      padding: 0 8px;
      height: 32px;
      border-radius: 3px;
    }

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 0 10px;
      cursor: pointer;
      background-color: #fff;
      user-select: none;
      border-radius: 6px;
      color: #000000;

      @media (min-width: 769px) and (max-width: 1440px) {
        padding: 0 8px;
      }

      .titleIcon {
        width: 21px;
        height: 21px;
        min-width: 21px;
        min-height: 21px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(15%) sepia(57%) saturate(2207%) hue-rotate(209deg)
          brightness(82%) contrast(88%);

        @media (min-width: 769px) and (max-width: 1440px) {
          width: 17px;
          height: 17px;
          min-width: 17px;
          min-height: 17px;
        }
      }

      .title {
        flex-grow: 1;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-bottom: 2px;
        text-align: left;
        text-transform: none;

        @media (min-width: 769px) and (max-width: 1440px) {
          font-size: 11px;
          padding-right: 8px;
        }
      }

      .downUpIcon {
        width: 14px;
        height: 14px;
        min-height: 14px;
        min-width: 14px;
        background-repeat: no-repeat;
        background-position: center;
        filter: invert(15%) sepia(57%) saturate(2207%) hue-rotate(209deg)
          brightness(82%) contrast(88%);

        @media (min-width: 769px) and (max-width: 1440px) {
          width: 11px;
          height: 11px;
          min-height: 11px;
          min-width: 11px;
        }
      }
    }
  }
}
